import React from "react";
import Modal from "react-modal";

import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import TopbarDesktop from "../../UpdatedLanding/components/TopbarDesktop";

const customStyles = {
  content: {
    backgroundColor: "transparent",
    margin: 0,
    inset: 0,
    padding: 0,
    borderRadius: 0,
    border: "none",
    height: "100%",
  },
  overlay: {
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(5, 19, 34, 0.90)",
    inset: 0,
  },
};

Modal.setAppElement("#root");

const ModalCancelCalculator = ({ isOpen, onClose, onAbort, content }) => {
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    if (!isOpen) {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  if (!content) return;
  const { text, nextBtnText, secondaryBtnText } = content;
  return (
    <Modal
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Прервать работу ИИ-калькулятора"
    >
      <div className="p-3 h-full lg:hidden">
        <div className="max-w-sm mx-auto h-full flex flex-col">
          <div className="flex flex-row justify-around items-center border border-middleBlue rounded-[20px] h-[40px] px-2 py-2">
            <div className="text-middleBlue text-13">ИИ-калькулятор</div>
          </div>
          <div className="grow flex flex-col justify-around items-center">
            <div className="flex flex-col items-center gap-8 px-4">
              <div className="text-brightLightBlue text-left mb-24">{text}</div>
              <PrimaryButton className="w-[216px]" onClick={onClose}>
                {nextBtnText}
              </PrimaryButton>
              <SecondaryButton className="w-[216px]" onClick={onAbort}>
                {secondaryBtnText}
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>

      {/* for desktop */}
      <div className="h-full hidden lg:block">
        <TopbarDesktop
          backgroundColor="bg-primaryBlueGreen"
          className="lg:block hidden mx-auto max-w-screen-xl px-12 text-base"
        />
        <div className="max-w-lg mx-auto h-full flex flex-col">
          <div className="grow flex flex-col justify-start mt-44 items-center">
            <div
              className="flex flex-col items-center gap-8 px-[30px] py-10 rounded-[5px]"
              style={{ backgroundColor: "#293F57" }}
            >
              <div className="text-brightLightBlue text-base mb-5 font-primaryLight">
                {text}
              </div>
              <div className="flex items-center">
                <PrimaryButton className="w-[216px]" onClick={onClose}>
                  {nextBtnText}
                </PrimaryButton>
                <SecondaryButton
                  className="w-[216px] text-shipCove"
                  onClick={onAbort}
                >
                  {secondaryBtnText}
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCancelCalculator;
