import React from "react";
import svgIcons from "../../../../assets/v2/svgIcons";
import { Accordion } from "flowbite-react";
import { useWindowSize } from "@uidotdev/usehooks";

const { ArrowDown, Idea, USP } = svgIcons;

const AccordionArrow = ({ className, color }) => {
  const size = useWindowSize();
  return (
    <div className={className.className}>
      <ArrowDown
        width={size.width < 1024 ? 24 : 32}
        height={size.width < 1024 ? 24 : 32}
        color={color}
      />
    </div>
  );
};

const userInputToList = (ideaDescription, uspDescription) => [
  {
    title: "Ваша идея",
    SvgIcon: (size) => <Idea width={size.width} height={size.height} />,
    description: ideaDescription
  },
  {
    title: "УТП",
    SvgIcon: (size) => <USP width={size.width} height={size.height} />,
    description: uspDescription
  },
];

const accordionContentTheme = {
  base: "pb-1 last:rounded-b-lg text-sinbad lg:text-lg mb-2 font-primary",
};

const accordionTitleTheme = {
  base: "flex w-full items-start justify-between pt-3 pb-5 text-left font-primaryMedium text-brightLightBlue text-base lg:text-lg",
  arrow: {
    base: "",
  },
  open: {
    on: "",
  },
};

const accordionRootTheme = {
  base: "px-6 border-none",
};

const accordionRootWithoutBorder = {
  base: "mx-6 border-brightBlue/[.3]",
};

const Specs = ({ techSpecs, userInput }) => {
  const { ideaDescription, uspDescription } = userInput;
  const userInputList = userInputToList(ideaDescription, uspDescription);
  return (
    <div>
      <div
        className={`relative mt-5 font-primary text-sm lg:hidden border rounded-lg border-brightBlue/[.4]`}
      >
        <div className="absolute inset-y-3 left-0 w-1 items-center rounded-lg bg-brightBlue" />
        {userInputList.map((item, index) => (
          <Accordion
            key={index}
            collapseAll
            flush
            arrowIcon={(e) =>
              AccordionArrow({ className: e, color: "#1D8FFB80" })
            }
            theme={accordionRootTheme}
          >
            <Accordion.Panel>
              <Accordion.Title theme={accordionTitleTheme}>
                <div className="flex items-center text-base font-primaryBold text-brightBlue gap-3">
                  {item.SvgIcon({ width: 24, height: 24 })}
                  {item.title}
                </div>
              </Accordion.Title>
              <Accordion.Content theme={accordionContentTheme}>
                {item.description}
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        ))}
      </div>
      <div className={`relative mt-10 font-primary text-sm lg:hidden`}>
        <div className="absolute inset-y-4 left-0 w-1 items-center  rounded-lg bg-brightLightBlue" />
        <div className="flex items-center pt-3 px-6 pb-5 text-base leading-4 text-brightLightBlue font-primaryBold gap-3">
          <Idea width={24} height={24} />
          ИИ-калькулятор подготовил ответ
        </div>
        <div className="w-auto h-[1px] mx-6 bg-brightBlue/[.3]" />
        {techSpecs.map((item, index) => (
          <Accordion
            key={index}
            collapseAll
            flush
            arrowIcon={(e) => AccordionArrow({ className: e, color: "" })}
            theme={
              index !== techSpecs.length - 1
                ? accordionRootWithoutBorder
                : { base: "mx-6 border-none" }
            }
          >
            <Accordion.Panel>
              <Accordion.Title theme={accordionTitleTheme}>
                {item.title}
              </Accordion.Title>
              <Accordion.Content theme={accordionContentTheme}>
                {item.text.split("\n").map((item, itemIndex) => {
                  const cleanedItem = item.trim().startsWith("-")
                    ? item.trim().substring(1).trim()
                    : item.trim();
                  const hasMarker = item.trim().startsWith("-");
                  if (!hasMarker) {
                    return <p key={itemIndex}>{cleanedItem}</p>;
                  }
                  return (
                    <li
                      key={itemIndex}
                      className={`${
                        hasMarker
                          ? "pl-5 list-inside list-disc"
                          : "font-primary"
                      } py-1`}
                    >
                      {cleanedItem}
                    </li>
                  );
                })}
                {item.subsections && (
                  <div>
                    {item.subsections.map((subsection, subIndex) => (
                      <div key={subIndex}>
                        <div className="pt-5 pb-3 text-middleBlue font-primarySemiBold">
                          {subsection.title}
                        </div>
                        <ul>
                          {subsection.text
                            .split("\n")
                            .map((item, itemIndex) => {
                              const cleanedItem = item.trim().startsWith("-")
                                ? item.trim().substring(1).trim()
                                : item.trim();
                              const hasMarker = item.trim().startsWith("-");
                              return (
                                <li
                                  key={itemIndex}
                                  className={`${
                                    hasMarker
                                      ? "pl-5 list-inside list-disc"
                                      : "font-primaryExtraLight italic"
                                  } py-1`}
                                >
                                  {cleanedItem}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        ))}
      </div>

      {/* for desktop */}
      <div
        className={`relative mt-5 hidden lg:block border rounded-lg border-brightBlue/[.4]`}
      >
        <div className="absolute inset-y-3 left-0 w-1 items-center rounded-lg bg-brightBlue" />
        {userInputList.map((item, index) => (
          <Accordion
            key={index}
            collapseAll
            flush
            arrowIcon={(e) =>
              AccordionArrow({ className: e, color: "#1D8FFB80" })
            }
            theme={accordionRootTheme}
          >
            <Accordion.Panel>
              <Accordion.Title theme={accordionTitleTheme}>
                <div className="flex items-center text-brightBlue font-brightBlue gap-3">
                  {item.SvgIcon({ width: 32, height: 32 })}
                  {item.title}
                </div>
              </Accordion.Title>
              <Accordion.Content theme={accordionContentTheme}>
                {item.description}
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        ))}
      </div>
      <div className={`relative mt-10 font-primary text-sm hidden lg:block`}>
        <div className="absolute inset-y-4 left-0 w-1 items-center  rounded-lg bg-brightLightBlue" />
        <div className="flex items-center mt-2 p-7 text-2xl text-brightLightBlue font-primaryBold gap-3">
          <Idea width={32} height={32} />
          ИИ-калькулятор подготовил ответ
        </div>
        <div className="w-auto h-[1px] mx-7 bg-brightBlue/[.2]" />
        {techSpecs.map((item, index) => (
          <Accordion
            key={index}
            collapseAll
            flush
            arrowIcon={AccordionArrow}
            theme={
              index !== techSpecs.length - 1
                ? accordionRootWithoutBorder
                : { base: "mx-6 border-none" }
            }
          >
            <Accordion.Panel>
              <Accordion.Title theme={accordionTitleTheme}>
                {item.title}
              </Accordion.Title>
              <Accordion.Content theme={accordionContentTheme}>
                {item.text.split("\n").map((item, itemIndex) => {
                  const cleanedItem = item.trim().startsWith("-")
                    ? item.trim().substring(1).trim()
                    : item.trim();
                  const hasMarker = item.trim().startsWith("-");
                  if (!hasMarker) {
                    return <p key={itemIndex}>{cleanedItem}</p>;
                  }
                  return (
                    <li
                      key={itemIndex}
                      className={`${
                        hasMarker
                          ? "pl-5 list-inside list-disc"
                          : "font-primaryExtraLight italic"
                      } py-1`}
                    >
                      {cleanedItem}
                    </li>
                  );
                })}
                {item.subsections && (
                  <div>
                    {item.subsections.map((subsection, subIndex) => (
                      <div key={subIndex}>
                        <div className="pt-5 pb-3 text-middleBlue font-primarySemiBold">
                          {subsection.title}
                        </div>
                        <ul>
                          {subsection.text
                            .split("\n")
                            .map((item, itemIndex) => {
                              const cleanedItem = item.trim().startsWith("-")
                                ? item.trim().substring(1).trim()
                                : item.trim();
                              const hasMarker = item.trim().startsWith("-");
                              return (
                                <li
                                  key={itemIndex}
                                  className={`${
                                    hasMarker
                                      ? "pl-5 list-inside list-disc"
                                      : "font-primaryExtraLight italic"
                                  } py-1`}
                                >
                                  {cleanedItem}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default Specs;
