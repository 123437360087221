import React from "react";

import iconify from "../../../../assets/iconify";

const { FilledCircle, EmptyCircle, BigFilledCircle, BigEmptyCircle } = iconify;

const PlanTimelineCard = ({ item, idx }) => {
  const { title, duration, cost } = item;

  const cardRef = React.useRef(null);

  const textColor = idx === 0 ? "text-sinbad" : "text-middleBlue";

  return (
    <div className="relative">
      <div className="flex flex-row items-center gap-1 lg:hidden" ref={cardRef}>
        <div className={`${textColor}`} style={{ marginLeft: -4 }}>
          {idx === 0 ? <FilledCircle /> : <EmptyCircle />}
        </div>
        <div className={`flex-grow rounded-2xl text-left ${textColor} cursor-default`}>
          <div className="flex flex-row justify-end py-1 pr-4">
            <div className="flex-grow ml-3 py-1 flex flex-col">
              <div className="text-14">{title}</div>
              <div className="mt-1 text-12">{duration}</div>
            </div>
            <div className="py-1 w-[85px] text-right">{cost}</div>
          </div>
        </div>
      </div>

      {/* for desktop */}
      <div className="flex flex-row items-center gap-1 hidden lg:block" ref={cardRef}>
        <div className={`${textColor}`} style={{ marginLeft: -5 }}>
          {idx === 0 ? <BigFilledCircle /> : <BigEmptyCircle />}
        </div>
        <div className={`flex-grow rounded-2xl text-left ${textColor} cursor-default`}>
          <div className="flex flex-row justify-end px-3 py-1 pr-4" style={{ bottom: 20, position: "relative" }}>
            <div className="flex-grow ml-4 py-1 flex items-center">
              <div className="text-2xl mr-1">{title}</div>
              <div className="text-lg font-primaryExtraLight">{duration}</div>
            </div>
            <div className={`py-1 ${cost.includes("руб") ? 'text-xl' : 'text-lg'} text-right flex items-center`}>
              <span>
                {cost}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanTimelineCard;
