import React from "react";
import RadioInput from "../components/Radio";
import {differenceInCalendarDays} from "date-fns";
import {formatInTimeZone} from "date-fns-tz";
import {useFormContext} from "react-hook-form";


function ItemDate({date, index, isActive, register, onSelect}) {
    // const {watch,register}=useFormContext()
    const diff = differenceInCalendarDays(new Date(date), new Date());
    const dayLabel =
        diff === 0
            ? "сегодня"
            : diff === 1
                ? "завтра"
                : diff === 2
                    ? "послезавтра"
                    : "";
    return (
        <button
            className={`flex-1 rounded-xl bg-primaryDarkBlue pl-10 pr-8 pt-3 pb-3 gap-3 flex flex-row items-center ${isActive ? 'border-[#036A90] cursor-default' : 'lg:border-[#3E679266] border-[#051322]'} border-[1px] `}
            onClick={onSelect}>
            <RadioInput
                id={index}
                value={date}
                currentValue={index}
                // register={register('day')}
                color="bg-primaryDarkBlue"
                backgroundSize="27px 27px"
                className="border-[#1C354F]"
                style={{borderColor: "#1C354F"}}
                isActive={isActive}
            />
            {dayLabel && <div
                className={`lg:text-lg text-base ${isActive ? 'text-[#00C2FF]' : 'text-shipCove'} font-primaryLight`}>
                {dayLabel}
            </div>}
            <div
                className={`lg:text-lg text-base ${isActive ? 'text-[#00C2FF]' : 'text-shipCove'} font-primaryLight`}>
                {formatInTimeZone(date, "Europe/Moscow", "dd.MM.yyyy")}
            </div>
        </button>
    )
}

export default ItemDate
