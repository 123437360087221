import React from "react";

import CheckboxInput from "../../../../../components/CheckboxInput";
import HintComponent from "../../NewTodoList/components/HintComponent";

const TodoHeader = ({ todo, onTodoClosed, shouldShowHint }) => {
  const { states, title, id } = todo;
  const { state } = states;

  const [isClosed, setIsClosed] = React.useState(false);
  const onCheckboxClick = () => {
    setIsClosed(true);
    onTodoClosed({ todoId: id });
  };

  const isInteractive = state === "READY_TO_CLOSE" && !isClosed;

  return (
    <div>
      {shouldShowHint && (
        <HintComponent>Отмечайте выполненные задачи галочками</HintComponent>
      )}
      <div className="flex flex-row items-center gap-2 cursor-pointer w-full py-5 text-left font-primary text-white text-base">
        <CheckboxInput
          id="todo"
          value={state === "CLOSED"}
          disabled={state === "OPEN" || state === "CLOSED"}
          register={{ disabled: !isInteractive }}
          className={`${isInteractive ? "pulsating-checkbox" : ""} lineTo`}
          defaultChecked={state === "CLOSED"}
          onClick={onCheckboxClick}
        />
        <div className="flex-grow flex flex-row items-center gap-2">
          <label className="flex flex-grow cursor-pointer">
            {state === "CLOSED" ? (
              <span className="line-through">{title}</span>
            ) : (
              title
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

export default TodoHeader;
