import React from "react";

import CtoPhoto from "../../../assets/v2/images/CTO.png";
import MailIcon from "../../../assets/v2/icons/MailIcon.png";
import TelegramIcon from "../../../assets/v2/icons/TelegramIcon.png";
import ContactForm from "./ContactForm";

const name = "Александр Потапов";

const TeamDesktop = () => {
    return (
        <div className="hidden lg:flex w-full flex-col justify-between items-center" id="team">
            <h2
                className="self-start text-2xl lg:text-54 lg:leading-56 text-mintTulip font-primaryBold mb-6"
                style={{
                    background:
                        "radial-gradient(160.92% 3903.06% at -12.32% 90.79%, #A0C4D8 52.35%, rgba(154, 190, 211, 0) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                }}
            >
                Мы на связи
            </h2>
            <div className="flex mx-auto flex-row gap-8 justify-between items-center pt-5">
                <div className="flex flex-col justify-center items-center w-auto mx-auto">
                   <div className="flex flex-col justify-center items-center text-left w-[336px] h-[516px] mx-auto rounded-2xl border border-middleBlue bg-[#3E679240]">
                       <div className="relative">
                       <img
                           src={CtoPhoto}
                           alt={name}
                           className="rounded-lg w-[200px] h-[200px]"
                       />
                           <div className="absolute top-0 right-0 left-0 bottom-0 bg-[#051021] opacity-20"/>
                       </div>
                       <div className="px-16 mt-3">
                           <div className="font-primaryLight text-sinbad text-xl tracking-tight">
                               {name}
                           </div>
                           <div className="pt-1 font-primaryLight text-shipCove text-md leading-4 tracking-tighter">
                            Основатель и технический директор сервиса Спринт&nbsp;АП
                           </div>
                       </div>
                           <div className="flex flex-row justify-between items-center mt-10 gap-1" id="contact">
                               <a href="https://t.me/alpotapov" target="_blank" rel="noreferrer">
                                   <img
                                       src={TelegramIcon}
                                       alt="@alpotapov"
                                       className="w-[43px] h-[43px]"
                                   />
                               </a>
                               <div className="w-[164px] mx-auto font-primaryLight text-lg text-shipCove leading-4 tracking-tight">Остались вопросы - свяжитесь с нами</div>
                           </div>
                   </div>
                    <div className="pt-8 text-lg font-primary text-shipCove">Вы разработчик или дизайнер ?</div>
                </div>
                <div className="flex-1 text-2xl font-primaryBold text-mintTulip text-center">или</div>
                <ContactForm/>
            </div>
        </div>
    );
};

export default TeamDesktop;
