import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useBackendQueryWithoutAuth } from "../../../hooks/useBackendQueryWithoutAuth";
import { useBackendMutationWithoutAuth } from "../../../hooks/useBackendMutationWithoutAuth";

import ScheduleDesktop from "./ScheduleDesktop";
import { useWindowSize } from "@uidotdev/usehooks";
import ScheduleMobile from "./ScheduleMobile";
import ScheduleMeetingModal from "./ScheduleMeetingModal/ScheduleMeetingModal";

const ScheduleMeeting = () => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const { estimatesId } = useParams();

  const { data: slots } = useBackendQueryWithoutAuth({
    url: "/api/v2/onboarding/meeting",
    queryKey: ["meetingSlots"],
  });

  const mutation = useBackendMutationWithoutAuth({
    url: `/api/v2/onboarding/${estimatesId}/meeting`,
    method: "POST",
    mutationKey: ["onboardingMeeting"],
    data: {},
    onSuccess: () => {
      setOpenModal(true);
    },
  });


  const [chosenDateIndex, setChosenDateIndex] = useState(undefined);
  const [chosenSlotIndex, setChosenSlotIndex] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);

  const slotSelectionIsDisabled = !chosenDateIndex && chosenDateIndex !== 0;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setPageTitle, setOnBack } = useOutletContext();

  React.useEffect(() => {
    setPageTitle("Время консультации");
    setOnBack(() => () => {
      navigate(-1);
    });
  }, [setPageTitle, setOnBack, navigate]);

  const onChooseTime = () => {
    if (chosenDateIndex === undefined || chosenSlotIndex === undefined) {
      return;
    }
    if (slots) {
      mutation.mutate({
        date: slots[chosenDateIndex].date,
        slot: slots[chosenDateIndex].slots[chosenSlotIndex],
      });
    }
  };
  return slots ? (
    <div className={`flex-1 ${openModal ? "blur-2xl" : "blur-none"}`}>
      {slots && size.width < 1024 ? (
        <ScheduleMobile
          slots={slots || []}
          chosenDateIndex={chosenDateIndex}
          setChosenDateIndex={setChosenDateIndex}
          chosenSlotIndex={chosenSlotIndex}
          setChosenSlotIndex={setChosenSlotIndex}
          slotSelectionIsDisabled={slotSelectionIsDisabled}
          onPress={() => onChooseTime()}
        />
      ) : (
        <ScheduleDesktop
          slots={slots || []}
          chosenDateIndex={chosenDateIndex}
          setChosenDateIndex={setChosenDateIndex}
          chosenSlotIndex={chosenSlotIndex}
          setChosenSlotIndex={setChosenSlotIndex}
          slotSelectionIsDisabled={slotSelectionIsDisabled}
          onPress={() => onChooseTime()}
        />
      )}
      {slots[chosenDateIndex]?.slots[chosenSlotIndex] && (
        <ScheduleMeetingModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          time={slots[chosenDateIndex]?.slots[chosenSlotIndex]}
        />
      )}
    </div>
  ) : null;
};

export default ScheduleMeeting;
