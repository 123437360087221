import React from "react";
import ItemDate from "./ItemDate";
import ItemTime from "./ItemTime";


function ScheduleMobile({
                            slots,
                            chosenDateIndex,
                            setChosenDateIndex,
                            chosenSlotIndex,
                            setChosenSlotIndex,
                            slotSelectionIsDisabled,
                            onPress
                        }) {

    return (
        <div className="flex-1 w-full h-full bg-[#091A2C]">
            <div
                className="px-5 mx-auto w-full max-w-sm">
                <div
                    className="mt-5 text-sinbad text-base font-primaryExtraLight mb-4 flex items-start">
                    Ближайшие дни для консультации
                </div>
                <div className="flex-1 flex flex-wrap justify-center mb-5 gap-2 w-full">
                    {slots.map((slot, index) => {
                        return (
                            <ItemDate key={index} date={slot.date} index={index}
                                      isActive={chosenDateIndex === index}
                                      onSelect={() => setChosenDateIndex(index)}/>
                        )
                    })}
                </div>
                <div
                    className="mt-5 text-sinbad text-base font-primaryExtraLight mb-4 flex items-center mx-auto w-fit">
                    Выберите интервал времени для консультации
                </div>
                <div className="grid grid-cols-2 gap-1">
                    {!slotSelectionIsDisabled && slots[chosenDateIndex].slots.map((itemSlot, slotIndex) => {
                        return (
                            <ItemTime key={slotIndex} slot={itemSlot} index={slotIndex}
                                      isActive={chosenSlotIndex === slotIndex}
                                      onSelect={() => setChosenSlotIndex(slotIndex)}/>
                        )
                    })}
                </div>
                <div className="flex justify-center pt-7 px-4 pb-8">
                    <button
                        className={`w-[280px] h-[45px] my-5 rounded-[50px] text-base bg-mintTulip hover:bg-brightLightBlue focus:bg-brightLightBlue transition-all`}
                        onClick={onPress}
                    >
            <span className={`font-primaryBold text-base text-darkBlue`}>
              Подтвердить
            </span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ScheduleMobile
