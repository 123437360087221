import React from "react";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { useQueryClient } from "@tanstack/react-query";

import PrimaryButton from "../../../../../components/PrimaryButton";
import useBackendAction from "../../../../../hooks/useBackendAction";

const ActionUploadDocument = ({ uploadDocument }) => {
  const queryClient = useQueryClient();
  const { invalidateKey } = uploadDocument || {};
  const { register, handleSubmit } = useForm({
    defaultValues: {
      documentType: "requirementsPdf",
    },
  });
  const [acceptedFile, setAcceptedFile] = React.useState(null);

  const mutation = useBackendAction({
    action: uploadDocument,
    mutationKey: "uploadDocument",
    fileUpload: true,
  });

  const onDrop = React.useCallback((acceptedFiles) => {
    setAcceptedFile(acceptedFiles[0]);
  }, []);

  const onStartUpload = (data) => {
    const { documentType } = data;
    const formData = new FormData();
    formData.append("file", acceptedFile);
    formData.append("documentType", documentType);
    mutation.mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries(invalidateKey);
        console.log("onSuccess");
      },
      onError: (data) => {
        console.log("onError");
      },
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div
      className={`flex-grow rounded-2xl bg-primaryText text-white border border-primaryText`}
    >
      <div className="flex flex-row justify-between items-center py-2 pr-4">
        <div className={`flex-shrink h-10 w-1.5 bg-color7 rounded-xl`} />
        <div className="flex-grow ml-4 py-2 flex flex-col">
          <div className="mb-4 text-white ">
            <label className="font-primary">Добавить документ</label>
            <div className="flex space-x-2 mt-2">
              <select
                {...register("documentType")}
                className="border rounded w-full p-2 bg-primaryAccent border-primaryAccent"
              >
                <option value="requirementsPdf">Техническое задание</option>
                <option value="userStoriesPdf">Истории на спринт</option>
              </select>
            </div>
          </div>
          <div className="mb-4 text-white ">
            <label className="font-primary">Выбрать файл</label>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className="text-secondaryText text-sm">
                  Загрузить здесь ...
                </p>
              ) : (
                <p className="text-secondaryText text-sm">Выбрать файл</p>
              )}
            </div>
          </div>
          <PrimaryButton
            onClick={handleSubmit(onStartUpload)}
            className="mt-10"
          >
            Загрузить
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default ActionUploadDocument;
