/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";

import Topbar from "./components/Topbar";
import TopbarDesktop from "./components/TopbarDesktop";

import useScrollToAnchor from "../../hooks/useScrollToAnchor";

import PrimaryButton from "./components/PrimaryButton";
import SecondaryButton from "./components/SecondaryButton";
import FirstScreen from "./components/FirstScreen";
import HowDoesItWork from "./components/HowDoesItWork";
import Screenshots from "./components/Screenshots";
import WhyUs from "./components/WhyUs";
import Reviews from "./components/Reviews";
import Faq from "./components/Faq";
import Team from "./components/Team";
import LandingFooter from "./components/LandingFooter";

import ModalDownloadApp from "./components/ModalDownloadApp/ModalDownloadApp";

import FirstScreenDesktop from "./components/desktop/FirstScreenDesktop";
import HowDoesItWorkDesktop from "./components/desktop/HowDoesItWorkDesktop";
import LandingFooterDesktop from "./components/desktop/LandingFooterDesktop";

import BackgroundNoise from "../../assets/v2/images/BackgroundNoise.png";

import trackingService from "../../services/tracking";

import svgIcons from "../../assets/v2/svgIcons";
import WeAreStartupDesktop from "./components/desktop/WeAreStartupDesktop";
import WeAreStartup from "./components/WeAreStartup/WeAreStartup";
import Price from "./components/Price";
import TeamDesktop from "./components/TeamDesktop";

const { ArrowUp } = svgIcons;

const TEXTS = [
  "NFT<br />коллекции",
  "смарт-<br />контракта",
  "мобильного<br />приложения",
  "веб-<br />приложения",
];

const Landing = () => {
  useScrollToAnchor();

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onPrimaryClick = () => {
    trackingService.trackLandingCtaClick();
    navigate("/calculator");
  };

  return (
    <div className="bg-downriver min-h-screen font-primary">
      <div className="bg-primaryDarkBlue pb-7 rounded-b-[40px] lg:hidden">
        <Topbar className="lg:hidden mx-auto max-w-lg" />
        <div className="container w-full max-w-lg mx-auto lg:hidden">
          <FirstScreen
            onPrimaryClick={onPrimaryClick}
            TEXTS={TEXTS}
            setIsModalOpen={setIsModalOpen}
            className="px-4"
          />
          <div className="px-8 pt-20">
            <Screenshots />
          </div>
        </div>
      </div>
      <div className="bg-primaryDarkBlue hidden lg:block">
        <div
          style={{
            background: `linear-gradient(180deg, rgba(5, 19, 34, 0.88) 0%, rgba(5, 19, 34, 0.00) 17.86%), url(${BackgroundNoise}) bottom / cover no-repeat`,
          }}
        >
          <TopbarDesktop
            backgroundColor="bg-primaryDarkBlue"
            className="hidden lg:block mx-auto max-w-screen-xl px-12"
          />
          <FirstScreenDesktop
            className="hidden lg:block max-w-screen-xl mx-auto px-12"
            onPrimaryClick={onPrimaryClick}
            TEXTS={TEXTS}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </div>
      <div className="hidden lg:block w-full bg-primaryDarkBlue rounded-b-[40px] pb-20">
        <div className="container w-full max-w-lg lg:max-w-screen-xl mx-auto px-3 lg:px-12">
            <Screenshots />
        </div>
      </div>

      <div className="w-full bg-downriver py-7 lg:py-16">
        <div className="container w-full max-w-lg lg:max-w-screen-xl mx-auto px-3 lg:px-12">
          <div className="flex flex-col gap-7 lg:gap-10 items-center">
            <PrimaryButton
              className="w-full lg:w-[360px]"
              onClick={onPrimaryClick}
            >
              Изложите свою идею
            </PrimaryButton>
          </div>
        </div>
      </div>

      <div className="bg-primaryDarkBlue py-7 lg:py-16 rounded-t-[40px] rounded-b-[40px] mb-[50px]">
        <div className="container w-full max-w-lg mx-auto lg:max-w-screen-xl px-8 lg:px-12">
          <WhyUs className="" />
          <Price />
        </div>
      </div>

      <div className="hidden lg:block w-full rounded-b-[40px] pb-20">
        <WeAreStartupDesktop className="max-w-screen-xl mx-auto px-12" />
        <div className="flex flex-col items-center mt-10">
          <PrimaryButton
            className="w-full lg:w-[360px]"
            onClick={onPrimaryClick}
          >
            Рассчитать в ИИ-калькуляторе
          </PrimaryButton>
        </div>
      </div>
      <div className="lg:hidden w-full rounded-b-[40px] mb-10 container w-full max-w-lg mx-auto lg:max-w-screen-xl px-8">
        <WeAreStartup className="mx-auto mb-10" />
        <div className="px-3 flex">
          <PrimaryButton
            className="w-[300px] mx-auto lg:w-[360px]"
            onClick={onPrimaryClick}
          >
            Рассчитать в ИИ-калькуляторе
          </PrimaryButton>
        </div>
      </div>

      <div className="bg-primaryDarkBlue py-7 lg:py-16 rounded-t-[40px] rounded-b-[40px]">
        <div className="container w-full max-w-lg lg:max-w-screen-xl mx-auto px-8 lg:px-12">
          <div className="flex flex-col gap-14">
            <Faq className="" />
            <Team className="lg:hidden" />
            <TeamDesktop />
          </div>
        </div>
      </div>

      <div className="w-full bg-downriver py-7">
        <div className="container w-full max-w-lg lg:max-w-screen-xl mx-auto px-3 lg:px-12">
          <div className="px-4 flex flex-col items-center">
            <SecondaryButton className="w-full lg:w-[360px] lg:hidden">
              Вы разработчик или дизайнер?
            </SecondaryButton>
            <div className="w-full">
              <div className="flex flex-row justify-end items-center">
                <button
                  className="text-white w-8 h-8 p-1 mt-14 mb-4"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <ArrowUp />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primaryDarkBlue pb-7 rounded-t-[40px] lg:hidden">
        <div className="container w-full max-w-lg mx-auto">
          <div className="px-4">
            <LandingFooter className="pt-7" />
          </div>
        </div>
      </div>
      <div
        className="hidden lg:block lg:py-10"
        style={{ background: "rgba(120, 237, 255, 0.10)" }}
      >
        <div className="container w-full max-w-lg lg:max-w-screen-xl mx-auto px-3 lg:px-12">
          <LandingFooterDesktop className="" />
        </div>
      </div>

      <ModalDownloadApp
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Landing;
