import React from "react";
import { useLocation } from "react-router-dom";
import { Banner } from "flowbite-react";
import { HiX } from "react-icons/hi";
import { Link } from "react-router-dom";

import { OnboardingBannerContext } from "../contexts/OnboardingBannerContext";


const OnboardingBanner = () => {
  const location = useLocation();
  const { isVisible, estimatesId, onClose } = React.useContext(OnboardingBannerContext);

  if (location.pathname.includes("/onboarding")) {
    return null;
  }

  if (!estimatesId || !isVisible) {
    return null;
  }

  return (
    <Banner>
      <div className="flex w-full justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
        <div className="mx-auto flex items-center">
          <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
            <span className="[&_p]:inline">
              Хотите продолжить оценку Вашей идеи?&nbsp;
              <Link
                to={`/onboarding/brief/${estimatesId}`}
                className="decoration-600 dark:decoration-500 inline font-medium text-cyan-600 underline decoration-solid underline-offset-2 hover:no-underline dark:text-cyan-500"
              >
                Вернуться к оценке
              </Link>
            </span>
          </p>
        </div>
        <Banner.CollapseButton
          color="gray"
          className="border-0 bg-transparent text-gray-500 dark:text-gray-400"
          onClick={onClose}
        >
          <HiX className="h-4 w-4" />
        </Banner.CollapseButton>
      </div>
    </Banner>
  );
};

export default OnboardingBanner;
