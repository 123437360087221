import React from "react";

const Scheduled = ({ payload }) => {
  const { startTime } = payload;

  const russianFormatted = new Date(startTime).toLocaleString("ru-RU", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "long",
  });
  return (
    <p className="font-primary">
      Консультация по техническому заданию назначена на{" "}
      <span className="font-primarySemiBold">{russianFormatted}</span>.
    </p>
  );
};

export default Scheduled;
