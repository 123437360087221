import React from "react";
import TextareaAutosize from "react-textarea-autosize";

import SpecialistCard from "./SpecialistCard";

import { useCopyToClipboard } from "../../../../../../../hooks/useCopyToClipboard";
import useBackendAction from "../../../../../../../hooks/useBackendAction";
import iconify from "../../../../../../../assets/iconify";

const { Copy } = iconify;

const RecruitmentDelegatedCard = ({ payload, icon }) => {
  const { copy } = useCopyToClipboard();
  const { message: messageText, actions } = payload;
  const { contact: contactAction } = actions;

  const mutation = useBackendAction({ action: contactAction, mutationKey: "todoList" });

  const onContact = (callback) => {
    mutation.mutate();
    callback();
  };
  return (
    <div className="w-full text-white">
      <div className="flex flex-col gap-4">
        <div>Мы рекомендуем вам проверенных дизайнеров.</div>
        <div>Сообщение, которое поможет вам начать диалог:</div>
        <div className="flex flex-col">
          <TextareaAutosize
            className="w-full p-2 border border-primaryAccent rounded-md rounded-b-none bg-primaryAccent focus:border-none focus:outline-none focus:ring-2 focus:ring-brandPurple"
            defaultValue={messageText}
            disabled
          />
          <button
            onClick={() => copy(messageText)}
            className="p-3 h-full text-secondaryText border border-primaryAccent bg-primaryAccent focus:ring-0 focus:outline-none rounded-lg rounded-t-none text-sm"
          >
            <div className="flex flex-row justify-center items-center gap-2">
              <Copy />
              <div>Скопировать</div>
            </div>
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <SpecialistCard
            specialist={{
              name: "Алексей",
              title: "UI/UX-дизайнер со стажем в 10 лет",
              telegram: "alpotapov",
            }}
            onContact={onContact}
          />
          <SpecialistCard
            specialist={{
              name: "Алексей",
              title: "UI/UX-дизайнер со стажем в 10 лет",
            }}
            onContact={onContact}
          />
          <SpecialistCard
            specialist={{
              name: "Алексей",
              title: "UI/UX-дизайнер со стажем в 10 лет",
            }}
            onContact={onContact}
          />
        </div>
      </div>
    </div>
  );
};

export default RecruitmentDelegatedCard;
