import React from "react";
import { Card } from "flowbite-react";

const SpecialistRecruitedCard = ({ payload, icon }) => {
  const { jobTitle, specialist } = payload;
  const { firstName, lastName } = specialist;

  return (
    <Card className="w-full bg-green-100">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-2">
          {icon}
          <div>{jobTitle}</div>
        </div>
        <div>
          <p>{`${firstName} ${lastName} принял приглашение`}</p>
        </div>
      </div>
    </Card>
  );
};

export default SpecialistRecruitedCard;
