import { apiClient } from "./http";

const provideDescription = async (ideaObject) => {
  const response = await apiClient.post(`/api/v1/estimates`, ideaObject);
  return response.data;
};

const provideData = async ({ estimatesId, briefObject, legalObject }) => {
  try {
    const response = await apiClient.post(`/api/v1/estimates/${estimatesId}`, {
      estimatesId,
      ...(legalObject ? { legal: legalObject } : {}),
      ...(briefObject ? { brief: briefObject } : {}),
    });
    return response.data;
  } catch (err) {
    console.error(err.response);
    throw err;
  }
};

const getEstimates = async (estimatesId) => {
  try {
    const response = await apiClient.get(`/api/v1/estimates/${estimatesId}`);
    return response.data;
  } catch (err) {
    console.error(err.response);
    throw err;
  }
};

const estimatesService = {
  getEstimates,
  provideData,
  provideDescription,
};

export default estimatesService;
