import React from "react";
import { Outlet } from "react-router-dom";

import Menu from "./components/Menu";

import useAuth from "../../modules/auth/useAuth";

const AppBase = () => {
  const { isAuthenticated } = useAuth();
  return (
    <div className="flex flex-col min-h-screen bg-page">
      {/* {isAuthenticated ? <Appbar /> : <Topbar />} */}
      <div className="flex-grow w-full mb-16">
        <Outlet />
      </div>
      {isAuthenticated && <Menu />}
    </div>
  );
};

export default AppBase;
