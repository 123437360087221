import React from "react";
import { useScript } from "@uidotdev/usehooks";

import config from "../../../../../../../config";

import PrimaryButton from "../../../../../../../components/PrimaryButton";

const { tinkoff } = config;
const { terminalKey } = tinkoff;

const TinkoffPaymentWidget = ({ payload, leadId }) => {
  const formRef = React.useRef(null);

  const status = useScript(
    `https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js`,
    {
      removeOnUnmount: false,
    }
  );

  const onPay = (e) => {
    e.preventDefault();
    window.pay(e.target);
    return false;
  };

  const triggerSubmit = () => {
    if (formRef.current) {
      window.pay(formRef.current);
    }
  }

  const { paymentDetails } = payload;
  const { email, phone, name, amount, order, description } = paymentDetails;

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2">
        <p>{description}</p>
        <p>Сумма: {amount} руб.</p>
        <p>Email: {email}</p>
        <p>Телефон: {phone}</p>
      </div>
      {status === "ready" ? (
        <>
          <form
            ref={formRef}
            className="payform-tinkoff text-primaryAccent hidden"
            name="payform-tinkoff"
            onSubmit={onPay}
          >
            <input className="payform-tinkoff-row" type="hidden" name="terminalkey" value={terminalKey} />
            <input className="payform-tinkoff-row" type="hidden" name="frame" value="false" />
            <input className="payform-tinkoff-row" type="hidden" name="language" value="ru"/>
            <input className="payform-tinkoff-row" type="hidden" placeholder="Сумма заказа" name="amount" value={amount} required />
            <input className="payform-tinkoff-row" type="hidden" placeholder="Номер заказа" name="order" value={order} />
            <input className="payform-tinkoff-row" type="hidden" placeholder="Описание заказа" name="description" value={description}/>
            <input className="payform-tinkoff-row" type="hidden" placeholder="ФИО плательщика" name="name" value={name} />
            <input className="payform-tinkoff-row" type="hidden" placeholder="E-mail" name="email" value={email} />
            <input className="payform-tinkoff-row" type="hidden" placeholder="Контактный телефон" name="phone" value={phone} />
          </form>
          <PrimaryButton className="w-full mt-10" onClick={triggerSubmit}>Перейти в Тинькофф Касса</PrimaryButton>
        </>
      ) : null}
    </div>
  );
};

export default TinkoffPaymentWidget;
