import React from "react";

import SecondaryButton from "../SecondaryButton";
import CrossButton from "../CrossButton";

import Title from "../../../../assets/v2/images/text_logo_sprint_ap.png";
import DownloadInAppStore from "../../../../assets/v2/images/DownloadInAppStore.png";

import config from "../../../../config";

const { appStoreLink } = config.app;

const ModalDesktop = ({ onClose, className }) => {
  return (
    <div className="p-3 h-full hidden lg:block">
      <div className="max-w-lg mx-auto h-full flex flex-col justify-around">
        <div
          style={{
            WebkitBackdropFilter: "blur(5px)",
            backdropFilter: "blur(20px)",
            background: "rgba(118, 155, 193, 0.30)",
          }}
          className="rounded p-10"
        >
          <div className="grow flex flex-col justify-around items-center">
            <div className="flex flex-col items-center gap-8 px-4">
              <div className="text-brightLightBlue text-center mb-1">
                Приложение Спринт АП сейчас в стадии бета-тестирования и доступно
                на iOS через Testflight.
              </div>
              <div className="text-brightLightBlue text-center mb-20">
                Скачайте приложение и назначьте бесплатную консультацию с
                системным аналитиком.
              </div>
              <a href={appStoreLink} target="_blank" rel="noreferrer">
                <img
                  src={DownloadInAppStore}
                  className="h-[50px] mb-20"
                  alt="Скачать в AppStore"
                />
              </a>
              <SecondaryButton className="w-[216px]" onClick={onClose}>
                Закрыть
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDesktop;
