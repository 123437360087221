export const TokenTypes = Object.freeze({
  ACCESS: '@access_token',
  REFRESH: '@refresh_token',
});

export const storeToken = (type, token) => {
  try {
    localStorage.setItem(type, token);
  } catch (e) {
    console.error(e);
  }
};

export const getToken = (type) => {
  try {
    const value = localStorage.getItem(type);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

export const removeToken = (type) => {
  try {
    localStorage.removeItem(type);
  } catch (e) {
    console.error(e);
  }
};

export const clear = () => {
  try {
    localStorage.removeItem(TokenTypes.ACCESS);
    localStorage.removeItem(TokenTypes.REFRESH);
  } catch (e) {
    console.error(e);
  }
};
