import React from "react";

import Appbar from "../../../../components/Appbar";

import iconify from "../../../../assets/iconify";

const { Chevron, EmptyCircle, CheckedCircle, ArrowBack } = iconify;

const timelineData = [
  {
    id: 1,
    title: "Подготовка",
    duration: "1 неделя",
    color: "color7",
    passed: false,
    current: true,
  },
  {
    id: 2,
    title: "Спринт 1",
    duration: "2 недели",
    color: "color8",
    passed: false,
    current: false,
  },
  {
    id: 3,
    title: "Спринт 2",
    duration: "2 недели",
    color: "color4",
    passed: false,
    current: false,
  },
  {
    id: 4,
    title: "Спринт 3",
    duration: "2 недели",
    color: "color9",
    passed: false,
    current: false,
  },
  {
    id: 5,
    title: "Бета-тестирование",
    duration: "1-2 недели",
    color: "color10",
    passed: false,
    current: false,
  },
  {
    id: 6,
    title: "Релиз",
    duration: "1 неделя",
    color: "color10",
    passed: false,
    current: false,
  },
];

const TimelineCard = ({ item }) => {
  const { passed, title, duration, color, current } = item;
  const activeColor = current ? color : "primaryText";
  return (
    <div className="flex flex-row items-center gap-3">
      <div className={`text-${color}`}>
        {passed ? <CheckedCircle /> : <EmptyCircle />}
      </div>
      <div
        className={`flex-grow rounded-2xl bg-primaryText text-white border border-${activeColor}`}
      >
        <div className="flex flex-row justify-between items-center py-2 pr-4">
          <div className={`flex-shrink h-10 w-1.5 bg-${color} rounded-xl`} />
          <div className="flex-grow ml-4 py-2 flex flex-col">
            <div>{title}</div>
            <div className="mt-1 text-xs text-secondaryText">{duration}</div>
          </div>
          <Chevron />
        </div>
      </div>
    </div>
  );
};

const TimelineLineSegment = ({ top, shouldHide, lineType }) => {
  if (shouldHide) return null;
  return (
    <div
      className={`border-l border-secondaryText absolute h-[65px] ml-[7px]`}
      style={{
        marginTop: top,
        borderStyle: lineType,
      }}
    />
  );
};

const Timeline = ({ timeline }) => {
  const calculateLineProps = (idx) => {
    const height = 73 * idx;
    const top = 44 + height + 22 * idx + 6;
    const isLast = idx === timeline.length - 1;
    const lineType =
      !isLast && (timeline[idx + 1].passed || timeline[idx + 1].current)
        ? "solid"
        : "dashed";
    return { top, shouldHide: isLast, lineType };
  };
  return timeline.map((item, idx) => (
    <React.Fragment key={item.id}>
      <TimelineCard item={item} />
      <TimelineLineSegment {...calculateLineProps(idx)} />
    </React.Fragment>
  ));
};

const ProjectTimeline = () => {
  return (
    <div className="fill-current stroke-current w-full">
      <Appbar className={"mb-10"} title={"Этапы проекта"} showDrawer />
      <div className="max-w-sm mx-auto">
        <div className="relative px-4 flex flex-col gap-4">
          <Timeline timeline={timelineData} />
          <span className="border-primaryText bg-color7 bg-color8 bg-color4 bg-color9 bg-color10 text-color7 text-color8 text-color4 text-color9 text-color10 border-color7 border-color8 border-color4 border-color9 border-color10" />
        </div>
      </div>
    </div>
  );
};

export default ProjectTimeline;
