import React from "react";
import TextareaAutosize from "react-textarea-autosize";

import PrimaryButton from "../components/PrimaryButton";
import CheckboxInput from "../components/Checkbox";

import EmailEmpty from "../../../assets/v2/icons/EmailEmpty.png";
import EmailFilled from "../../../assets/v2/icons/EmailFilled.png";

import svgIcons from "../../../assets/v2/svgIcons";

import "../components/spinner.css";

const { ArrowUp } = svgIcons;

export const EmailInput = ({ register, isActive }) => {
  return (
    <div className="mt-4 relative text-brightLightBlue">
      <img
        src={isActive ? EmailFilled : EmailEmpty}
        alt="Email"
        className="absolute top-2.5 left-3 w-6 h-6"
      />
      <input
        className="w-full p-2.5 pl-9 border border-middleBlue rounded-xl bg-primaryDarkBlue focus:outline-none focus:ring-2 focus:ring-middleBlue placeholder-style"
        placeholder="Ваш email"
        {...register("email", {
          required: "Пожалуйста, укажите ваш email",
        })}
      />
    </div>
  );
};

const SkipButton = ({ onClick, className, active }) => {
  const activeStyle = `bg-brightLightBlue border-brightLightBlue text-bigStone`;
  const disabledStyle = `bg-primaryDarkBlue border-middleBlue text-middleBlue`;
  return (
    <div className={className}>
      <button
        className={`text-sm flex flex-row items-center pl-[10px] border rounded-2xl font-primary ${
          active ? activeStyle : disabledStyle
        }`}
        onClick={onClick}
      >
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>Пропустить</div>
        <div
          style={{ transform: "rotate(90deg)", width: 24, height: 24 }}
          className="ml-1 flex flex-row justify-around items-center"
        >
          <ArrowUp width={8} height={8} />
        </div>
      </button>
    </div>
  );
};

const IdeaMobile = ({
  register,
  watch,
  errors,
  handleSubmit,
  progress,
  isContinueButtonActive,
  setIsModalCancelOpen,
  onGetEstimates,
  isModalCancelOpen,
  contentConfig,
  showSkipIdea,
  showSkipUsp,
  isPending,
  privacyPolicy,
}) => {
  return (
    <div className="w-full h-full">
      <div className="flex-1 w-full h-full bg-primaryDarkBlue lg:hidden">
        <div className="bg-bigStone font-primary pb-5 rounded-b-[40px]">
          <div className="max-w-sm mx-auto px-4">
            <div className="w-full py-3 px-4">
              <div className="h-1 rounded-2xl border border-middleBlue">
                <div
                  className="h-full bg-brightLightBlue rounded-l-2xl"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>

            <div className="mt-3">
              <h2 className="text-base text-sinbad font-primaryBold">
                Оставьте вашу почту и мы отправим вам предварительное ТЗ
              </h2>
            </div>

            <EmailInput register={register} isActive={true} />

            <div className="flex flex-col justify-start gap-2 mt-3">
              <div className="flex flex-row items-center gap-2">
                <CheckboxInput
                  id="privacyPolicyAccepted"
                  value={watch("privacyPolicyAccepted")}
                  register={{
                    ...register("privacyPolicyAccepted", {
                      required:
                        "Использование ИИ-калькулятора возможно только после согласия с политикой конфиденциальности",
                    }),
                  }}
                />

                <label
                  htmlFor="privacyPolicyAccepted"
                  className="select-none text-middleBlue font-primary text-13"
                >
                  Соглашаюсь с{" "}
                  <a
                    className="underline"
                    href={privacyPolicy}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Политикой обработки персональных данных
                  </a>
                </label>
              </div>
              {errors && errors.privacyPolicyAccepted && (
                <div className="text-errorRed">
                  <span className="text-sm mt-2">
                    {errors.privacyPolicyAccepted.message}
                  </span>
                </div>
              )}
            </div>

            <div className="mt-4 mb-4">
              <div
                className={`font-primaryBold text-base text-middleBlue text-sinbad`}
              >
                Опишите вашу идею в два твита
              </div>
              <div className="font-primary text-xs mt-1.5 text-errorRed">
                {errors.ideaDescription?.message}
              </div>
            </div>

            <div className="mt-4 text-brightLightBlue">
              <TextareaAutosize
                className="w-full p-4 border border-middleBlue rounded-2xl bg-primaryDarkBlue focus:border-none focus:outline-none focus:ring-2 focus:ring-middleBlue placeholder-style"
                placeholder="Без каких функций не имеет смысла показывать приложение конечному
            пользователю?"
                {...register("ideaDescription", {
                  required: "Пожалуйста, опишите вашу идею",
                  minLength: {
                    value: 280,
                    message: "Минимум 280 символов",
                  },
                  maxLength: {
                    value: 560,
                    message: "Максимум 560 символов",
                  },
                })}
              />
            </div>

            {showSkipIdea ? (
              <SkipButton
                className="mt-3 ml-auto w-fit"
                active
                onClick={() => setIsModalCancelOpen(true)}
              />
            ) : (
              <div className="mt-3 ml-auto w-fit h-[36px]"></div>
            )}

            <div className="mt-4 mb-4">
              <div
                className={`font-primaryBold text-base text-middleBlue text-sinbad`}
              >
                Ваше уникальное торговое предложение
              </div>
              <div className="font-primary text-xs mt-1.5 text-errorRed">
                {errors.uspDescription?.message}
              </div>
            </div>

            <div className="mt-4 text-brightLightBlue">
              <TextareaAutosize
                className="w-full p-4 border border-middleBlue rounded-2xl bg-primaryDarkBlue focus:border-none focus:outline-none focus:ring-2 focus:ring-middleBlue placeholder-style"
                placeholder="Какую задачу клиента решает ваше приложение? Какая функция очень полезна пользователю? В чем новизна вашего проекта?"
                {...register("uspDescription", {
                  required:
                    "Пожалуйста, опишите ваше уникальное торговое предложение",
                  minLength: {
                    value: 20,
                    message: "Минимум 20 символов",
                  },
                  maxLength: {
                    value: 280,
                    message: "Максимум 280 символов",
                  },
                })}
              />
            </div>

            {showSkipUsp ? (
              <SkipButton
                className="mt-3 ml-auto w-fit"
                active
                onClick={() => setIsModalCancelOpen(true)}
              />
            ) : (
              <div className="mt-3 ml-auto w-fit h-[36px]"></div>
            )}
          </div>
        </div>
        <div className="max-w-sm mx-auto px-4 flex flex-row justify-around">
          <PrimaryButton
            disabled={isPending}
            className={"w-[216px] my-5"}
            onClick={handleSubmit(onGetEstimates)}
          >
            {isPending ? (
              <div className="flex justify-center items-center">
                <div className="spinner"></div>
              </div>
            ) : (
              <span
                className={`font-primaryBold text-lg ${
                  !isContinueButtonActive ? "text-middleBlue" : "text-darkBlue"
                }`}
              >
                Далее
              </span>
            )}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default IdeaMobile;
