import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import PrimaryButton from "../../../../../../../components/PrimaryButton";

import backendActionService from "../../../../../../../services/backendAction";

const Options = ({ className, payload, leadId }) => {
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm({});
  const { options, action, questionKey } = payload;
  const { body, url } = action || {};

  const mutation = useMutation({
    mutationFn: backendActionService.executePost(url),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: leadId ? ["lead", leadId] : ["todoList"],
      });
    },
  });

  const onAnswer = (data) => {
    console.log('mutating', { data, body });
    mutation.mutate({  
      ...body,
      questionKey,
      ...data
    });
  };
  return (
    <div className={className}>
      <fieldset className="flex max-w-md flex-col gap-2">
        {options.map((option) => (
          <div className="flex items-center gap-2" key={option.value}>
            <input
              type="radio"
              id={`${option.value}`}
              value={option.value}
              defaultChecked={option.defaultChecked}
              {...register(`value`)}
            />
            <label htmlFor={`${option.value}`}>{option.text}</label>
          </div>
        ))}
      </fieldset>

      <div className="flex flex-col gap-4 mt-10">
        <PrimaryButton onClick={handleSubmit(onAnswer)}>Перейти к оплате</PrimaryButton>
      </div>
    </div>
  );
};

export default Options;
