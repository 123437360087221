import React from "react";

import Infobox from "../../../../../components/Infobox";

const UserStories = ({ className, asset }) => {
  const { content } = asset || {};
  const { preliminary, stories } = content || {};

  const storiesWithIds = (stories || []).map((story, idx) => ({
    ...story,
    id: idx,
  }));

  return (
    <div className={className}>
      {preliminary && <Infobox>Предварительная версия от ИИ</Infobox>}
      <div className="mt-4 flex flex-col gap-2 pb-3 text-white">
        {storiesWithIds.map((story, index) => (
          <div className="rounded-xl bg-primaryText p-5" key={story.id}>
            <div className="font-primaryBold text-base mb-2">/ {index + 1}</div>
            {story.story}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserStories;
