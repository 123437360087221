import React from "react";
import Typed from "typed.js";

const HeaderTyping = ({ texts }) => {
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: texts,
      typeSpeed: 80,
      backSpeed: 50,
      backDelay: 2000,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, [texts]);

  return (
    <div className="h-[80px] text-brightBlue">
       <span ref={el} />
    </div>
  );
};

export default HeaderTyping;
