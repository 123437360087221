import React from "react";
import { useCopyToClipboard } from "../hooks/useCopyToClipboard";
import iconify from "../assets/iconify";

const { Copy } = iconify;

const CopyComponent = ({ id, defaultValue }) => {
  const { copy } = useCopyToClipboard();
  console.log(copy);
  return (
    <div className="relative flex flex-row">
      <input
        id={id}
        type={"text"}
        className="w-full p-3 border border-primaryAccent rounded-lg rounded-r-none bg-primaryAccent focus:border-none focus:outline-none focus:ring-2 focus:ring-brandPurple"
        disabled
        defaultValue={defaultValue}
      />
      <button
        onClick={() => copy(defaultValue)}
        className="p-3 h-full text-secondaryText border border-primaryAccent bg-primaryAccent focus:ring-0 focus:outline-none rounded-lg rounded-l-none text-sm"
      >
        <Copy />
      </button>
    </div>
  );
};

export default CopyComponent;
