import React from "react";
import { Link } from "react-router-dom";

import Title from "../../../assets/v2/images/text_logo_sprint_ap.png";
const TopbarDesktop = ({ className, backgroundColor }) => {
  return (
    <div className={backgroundColor}>
      <div className={className}>
        <div className="py-3 fill-current stroke-current">
          <div className="flex flex-row justify-between items-center h-[40px] py-2">
            <Link to="/">
              <img src={Title} alt="title" className="h-[26px]" />
            </Link>
            <div className="flex flex-row">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopbarDesktop;
