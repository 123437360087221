import React from "react";
import CurvedArrow from "react-curved-arrow";

const HintComponent = ({ className, children }) => {
  return (
    <div>
      <div className="flex flex-row justify-end items-center mt-5 mb-10">
        <div className="ml-10 lineFrom w-1 h-1" />
        <h3 className="text-sm text-white font-primaryBold">
          {children}
        </h3>
      </div>
      <CurvedArrow
        fromSelector={".lineFrom"}
        fromOffsetX={-10}
        toSelector={".lineTo"}
        toOffsetY={30}
        width={4}
        color={"#C290BD"}
        middleY={50}
        middleX={-50}
        dynamicUpdate
      />
    </div>
  );
};

export default HintComponent;