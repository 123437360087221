import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";

import RadioInput from "../../../../../../../components/RadioInput";
import PrimaryButton from "../../../../../../../components/PrimaryButton";

import backendActionService from "../../../../../../../services/backendAction";

import iconify from "../../../../../../../assets/iconify";

const { Chevron } = iconify;

const TabResponses = ({ responses, todoId }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      candidate: null,
    },
  });

  const onSubmit = (data) => {
    const { candidate: candidateId } = data;
    if (!candidateId) return;
    const candidate = responses[candidateId];
    const { actions } = candidate;
    const { HIRED } = actions;
    const backendCall = backendActionService.executePost(HIRED.url, HIRED.body);
    backendCall()
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["todoList"] });
      })
      .catch((error) => {
        console.warn("error", error);
      });
  };

  return (
    <div className="flex flex-col gap-4">
      {Object.entries(responses).map(([key, candidate]) => {
        const { profile, state, actions } = candidate;
        const { name, summary, hourlyRate } = profile;
        const onClick = () =>
          navigate(`/app/team/profile/${key}/todo/${todoId}`, {
            state: actions,
          });

        return (
          <div className="flex flex-row gap-2" key={key}>
            <div className="flex flex-col justify-around">
              <RadioInput
                id={`candidate-${key}`}
                value={key}
                currentValue={watch("candidate")}
                name="candidate"
                register={register("candidate")}
              />
            </div>
            <button
              type="button"
              onClick={onClick}
              className="bg-primaryText flex flex-row gap-4 p-4 text-left rounded-2xl"
            >
              <div>
                <img
                  src="https://via.placeholder.com/50x66"
                  alt="Specialist"
                  className="rounded-md"
                />
              </div>
              <div className="flex flex-col grow">
                <h2 className="font-primaryBold text-base">{name}</h2>
                <div className="text-secondaryText text-xs">{summary}</div>
                <div className="text-secondaryText text-xs">{hourlyRate}</div>
                <div className="text-brandPurple text-xs">
                  {state === "CONTACTED" && "Вы обменялись контактами"}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="w-6 h-6">
                  <Chevron />
                </div>
              </div>
            </button>
          </div>
        );
      })}
      <PrimaryButton onClick={handleSubmit(onSubmit)} className="w-full">
        Принять специалиста в команду
      </PrimaryButton>
    </div>
  );
};

export default TabResponses;
