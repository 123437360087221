import React from "react";

import SecondaryButton from "../../../../../../../components/SecondaryButton";

const Closed = ({ payload }) => {
  const { customerTelegramLink } = payload;

  const openTelegram = () => {
    window.open(customerTelegramLink, "_blank");
  };

  return (
    <div className="font-primary">
      <div className="flex flex-col gap-4">
        <div>Вы сдали работу.</div>
        <div>
          Заказчик проведет приемку. Мы уведомим вас о результатах.
        </div>
        <SecondaryButton onClick={openTelegram}>
          Перейти в чат с заказчиком
        </SecondaryButton>
      </div>
    </div>
  );
};

export default Closed;
