import React from "react";
import {Accordion} from "flowbite-react";
import svgIcons from "../../../assets/v2/svgIcons";


const priceList = [
    {title: "MVP – мобильное приложение", price: "250 000"},
    {title: "MVP – NFT-коллекция", price: "350 000"},
    {title: "MVP – web-приложение", price: "350 000"},
    {title: "MVP – web3 приложение", price: "350 000"}
]

const {ArrowDown} = svgIcons;

const AccordionArrow = ({className}) => {
    return (
        <div className={className}>
            <ArrowDown/>
        </div>
    );
};

const accordionContentTheme = {
    base: "py-5 last:rounded-b-lg text-base lg:text-lg mb-2 text-sinbad font-primary",
};

const accordionTitleTheme = {
    base: "flex w-full items-start justify-between pt-3 pb-5 pr-4 text-left font-primaryBold text-brightLightBlue text-lg lg:text-2xl",
    arrow: {
        base: "",
    },
    open: {
        on: "",
    },
};

const accordionRootTheme = {
    base: "divide-y divide-sinbad/[.4] border-sinbad/[.4]",
};
const accordionRootThemeBorder = {
    base: "border-none",
};

function Price() {
    return (
        <div className={"mt-[50px]"}>
            <h2
                className="text-2xl lg:text-54 lg:leading-56 text-mintTulip font-primaryBold mb-6"
                style={{
                    background:
                        "radial-gradient(241.26% 136.11% at 54.55% -7.03%, #B5D4E5 46%, rgba(225, 244, 255, 0.00) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                }}>
                Наши цены
            </h2>
            {priceList.map((item, index) => (
                <Accordion
                    collapseAll
                    flush
                    arrowIcon={AccordionArrow}
                    theme={index !== priceList.length - 1 ? accordionRootTheme : accordionRootThemeBorder}
                >
                    <Accordion.Panel>
                        <Accordion.Title theme={accordionTitleTheme}>
                            {item.title}
                            <div className={"font-primary text-sinbad my-2"}>от&nbsp;&nbsp;{item.price} &#8381;</div>
                        </Accordion.Title>
                        <Accordion.Content theme={accordionContentTheme}>
                            {/* Спринт – это короткий 1-2-х недельный этап в разработке проекта.
                            Таких спринтов может быть несколько – количество зависит от
                            масштабности проекта. За спринт команда проходит последовательные
                            шаги от написания кода до презентации новой функциональности. И по */}
                        </Accordion.Content>
                    </Accordion.Panel>
                </Accordion>
            ))}
        </div>

    )
}

export default Price;
