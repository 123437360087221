import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import Appbar from "../../../../components/Appbar";
import Description from "./components/Description";
import UserStories from "./components/UserStories";

import backendActionService from "../../../../services/backendAction";

import iconify from "../../../../assets/iconify";

const { Chevron } = iconify;

const BackButton = ({ backLink }) => {
  const navigate = useNavigate();
  return (
    <button
      className="w-6 h-6 text-white"
      style={{ transform: "rotate(180deg)" }}
      onClick={() => navigate(backLink)}
    >
      <Chevron />
    </button>
  );
};

const contentTypes = {
  initialInfo: {
    title: "Общая информация",
    content: (asset) => <Description asset={asset} />,
  },
  userStoriesAi: {
    title: "Пользовательские истории",
    content: (asset) => <UserStories asset={asset} />,
  },
};

const ProjectDetails = () => {
  const { projectId, contentType } = useParams();
  const { data, isLoading } = useQuery({
    queryFn: backendActionService.executeGet(`/api/v1/project/${projectId}`),
    queryKey: ["project", projectId],
  });

  const { assets } = data || { assets: [] };

  return (
    <div className="fill-current stroke-current w-full">
      <Appbar
        className={"mb-5"}
        title={contentTypes[contentType].title}
        backButton={<BackButton backLink={`/app/project/${projectId}`} />}
      />
      <div className="max-w-sm mx-auto">
        <div className="relative px-4 flex flex-col gap-2">
          {contentTypes[contentType].content(
            assets.find((item) => item.type === contentType)
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
