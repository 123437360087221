import { Outlet } from "react-router-dom";

import Topbar from "../../components/Topbar";

export default function LegalBase() {
  return (
    <div className="min-h-screen flex flex-col fill-current stroke-current">
      <Topbar />
      <div className="flex-1 w-full h-full bg-page pb-20">
          <Outlet />
        </div>
    </div>
  );
}