import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import PrimaryButton from "../../../../../../../components/PrimaryButton";
import CopyComponent from "../../../../../../../components/CopyComponent";
import backendActionService from "../../../../../../../services/backendAction";

const InviteLinkCard = ({ payload }) => {
  const queryClient = useQueryClient();
  const { inviteLink, inviteText, delegateAction } = payload;
  const { url } = delegateAction;

  const mutation = useMutation({
    mutationFn: backendActionService.executePost(url),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["todoList"] });
    },
  });

  return (
    <div className="w-full text-white">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div>{inviteText}</div>
          <div className="max-w-md">
            <CopyComponent id="inviteLink" defaultValue={inviteLink} />
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-10">
          <div>Возникли сложности с поиском?</div>
          <PrimaryButton
            className="w-full"
            onClick={() => mutation.mutate({ designer: "delegate" })}
          >
            Делегировать поиск специалиста
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default InviteLinkCard;
