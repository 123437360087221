import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';

import estimatesService from "../../../../../services/estimates";

import { OnboardingBannerContext } from "../../../contexts/OnboardingBannerContext";
import useScrollToAnchor from "../../../../../hooks/useScrollToAnchor";

import PrimaryButton from "../../../../../components/PrimaryButton";
import StepsComponent from "../components/StepsComponent";

const StepIdeaDescription = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useScrollToAnchor();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      ideaDescription: "",
      name: "",
      uspDescription: "",
    },
  });
  const { onNewEstimate } = React.useContext(OnboardingBannerContext);

  const mutation = useMutation({
    mutationFn: estimatesService.provideDescription,
    onSuccess: (estimatesId) => {
      onNewEstimate({ estimatesId });
      console.log({ estimatesId });
      navigate(`/onboarding/brief/${estimatesId}/project`);
    },
  });

  const onGetEstimates = async (ideaObject) => {
    mutation.mutate(ideaObject);
  };

  return (
    <div className="bg-page font-primary">
      <div className="max-w-sm mx-auto p-4">
        
        <StepsComponent step={0} onClick={[() => navigate('#brief')]} />

        <h2 className="text-4xl text-white font-primaryBold mb-4 mt-10" id="brief">
          <div>Бриф</div>
          <div className="text-2xl text-secondaryText mt-1">Общие вопросы</div>
        </h2>

        <div className="mt-4 mb-4 text-white">
          <div className="font-primary">
            Опишите минимальную версию вашей идеи
          </div>
          <div className="font-primary text-secondaryText text-xs mt-1.5">
            1 твит или 200-300 знаков
          </div>
        </div>

        <div className="mt-4 text-white">
          <TextareaAutosize
            className="w-full p-2 border border-primaryAccent rounded-md bg-primaryAccent focus:border-none focus:outline-none focus:ring-2 focus:ring-brandPurple"
            placeholder="Без каких функций не имеет смысла показывать приложение конечному
          пользователю?"
            {...register("ideaDescription")}
          />
        </div>

        <div className="mt-4 mb-4 text-white">
          <div className="font-primary">
            Ваше уникальное торговое предложение
          </div>
          <div className="font-primary text-secondaryText text-xs mt-1.5">
            1 твит или 200-300 знаков
          </div>
        </div>

        <div className="mt-4 text-white">
          <TextareaAutosize
            className="w-full p-2 border border-primaryAccent rounded-md bg-primaryAccent focus:border-none focus:outline-none focus:ring-2 focus:ring-brandPurple"
            placeholder="Какую задачу клиента решает ваше приложение? Какие 2-3 функции очень полезны пользователю? В чем новизна вашего проекта?"
            {...register("uspDescription")}
          />
        </div>

        <div className="mt-4 text-white">
          <div className="font-primary">Как называется ваш проект?</div>
          <div className="font-primary text-secondaryText text-xs mt-1.5">
            Название можно будет сменить позднее
          </div>
        </div>

        <div className="mt-4 text-white">
          <input
            className="w-full p-2 border border-primaryAccent rounded-md bg-primaryAccent focus:outline-none focus:ring-2 focus:ring-brandPurple"
            rows="4"
            placeholder="Название проекта"
            {...register("name")}
          />
        </div>

        <PrimaryButton
          className={"w-full mt-20"}
          onClick={handleSubmit(onGetEstimates)}
        >
          Далее
        </PrimaryButton>
      </div>
    </div>
  );
};

export default StepIdeaDescription;
