import React from "react";

const PrimaryButton = ({ children, className, onClick }) => {
  return (
    <button
      className={`${className} rounded-[24px] bg-mintTulip py-3 px-3 hover:bg-brightLightBlue focus:bg-brightLightBlue transition-all`}
      onClick={onClick}
    >
      <span className="text-base font-primarySemiBold text-darkBlue">
        {children}
      </span>
    </button>
  );
};

export default PrimaryButton;