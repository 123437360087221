import React from "react";
import { Link } from "react-router-dom";
import Drawer from "react-modern-drawer";

import Title from "../assets/v2/images/text_logo_sprint_ap.png";
import iconify from "../assets/iconify";
import Hamburger from "../assets/v2/icons/Hamburger.png";
import Cross from "../assets/v2/icons/Cross.png";

const { Enter } = iconify;

const HamburgerButton = () => (
  <div className="px-3 py-2 rounded-2xl" style={{ backgroundColor: "#3E679252"}}>
    <img src={Hamburger} alt="Открыть меню" className="w-4 h-3" />
  </div>
);

const CrossButton = () => (
  <div className="px-3 py-2 rounded-2xl" style={{ backgroundColor: "#3E679252"}}>
    <img src={Cross} alt="Закрыть меню" className="w-[10px] h-[10px]" />
  </div>
)

const Topbar = ({ className }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  return (
    <div className={className}>
      <div className="bg-primaryDarkBlue p-3 fill-current stroke-current">
        <div className="max-w-sm mx-auto">
          <div className="flex flex-row justify-between items-center border border-middleBlue rounded-[20px] h-[40px] px-2 py-2">
            <Link to="/">
              <img src={Title} alt="title" className="h-[18px] pl-1" />
            </Link>
            <button
              onClick={toggleDrawer}
              className="text-white hover:text-brandPurple focus:text-brandSecondaryPurple"
            >
              <HamburgerButton />
            </button>
          </div>
          <Drawer
            open={isDrawerOpen}
            onClose={toggleDrawer}
            direction="right"
            duration={300}
            style={{ width: "230px" }}
          >
            <div className="bg-darkBlue w-full h-full flex flex-col">
              <div className="bg-primaryDarkBlue">
                <div className="my-4 flex flex-row justify-end items-center border border-middleBlue rounded-[20px] px-2 py-2">
                  <button
                    onClick={toggleDrawer}
                    className="text-white hover:text-brandPurple focus:text-brandSecondaryPurple"
                  >
                    <CrossButton />
                  </button>
                </div>
              </div>
              <div className="bg-darkBlue text-white font-primaryBold text-xl px-4 flex flex-col gap-5">
                <Link
                  to="/"
                  onClick={toggleDrawer}
                  className="hover:text-brandPurple focus:text-brandSecondaryPurple"
                >
                  <div className="">Главная</div>
                </Link>
                <Link
                  to="/onboarding/brief"
                  onClick={toggleDrawer}
                  className="hover:text-brandPurple focus:text-brandSecondaryPurple"
                >
                  <div className="">Калькулятор</div>
                </Link>
                <Link
                  to="/#team"
                  onClick={toggleDrawer}
                  className="hover:text-brandPurple focus:text-brandSecondaryPurple"
                >
                  <div className="">Команда</div>
                </Link>
                <Link
                  to="/#contact"
                  onClick={toggleDrawer}
                  className="hover:text-brandPurple focus:text-brandSecondaryPurple"
                >
                  <div className="">Контакты</div>
                </Link>
              </div>
              <div className="flex-grow flex flex-col justify-end px-4 pb-16">
                <Link
                  to="/app"
                  className="flex flex-row gap-1 items-center text-white font-primaryBold hover:text-brandPurple focus:text-brandSecondaryPurple"
                >
                  <Enter />
                  <h2 className="text-xl">Войти в приложение</h2>
                </Link>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
