import React from "react";

import HeaderTyping from "./HeaderTyping";

import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";

const FirstScreenDesktop = ({
  className,
  onPrimaryClick,
  TEXTS,
  setIsModalOpen,
}) => {
  return (
    <div className={className}>
      <div className="relative w-full pt-6 pb-24">
        <h1 className="relative text-100 leading-100 font-primaryBold text-middleBlue z-3">
          <div>Разработка</div>
          <HeaderTyping texts={TEXTS} className="ml-[100px]" />
          <div className="ml-[165px]">под контролем</div>
        </h1>
        <div className="relative mt-12 ml-2 text-32 text-shipCove font-primary">
          <span className="">Приложение для предпринимателей - от идеи до релиза<br />за 4 недели силами фрилансеров</span>
        </div>

        <div className="mt-16 flex flex-row gap-28 justify-center items-center">
          <PrimaryButton className="w-[360px]" onClick={onPrimaryClick}>
            Рассчитать в ИИ-калькуляторе
          </PrimaryButton>

          <SecondaryButton
            className="w-[360px]"
            onClick={() => setIsModalOpen(true)}
          >
            Скачать приложение
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default FirstScreenDesktop;
