import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import PrimaryButton from "../../../../../components/PrimaryButton";

import estimatesService from "../../../../../services/estimates";

const StepLegalQuestions = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const { estimatesId } = useParams();
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      countryOfResidence: "",
    },
  });

  const mutation = useMutation({
    mutationFn: estimatesService.provideData,
    onSuccess: (estimatesId) => {
      queryClient.invalidateQueries({ queryKey: ["estimates", estimatesId] });
      navigate(`/onboarding/brief/${estimatesId}`);
    },
  });

  const onGetEstimates = async (legalObject) => {
    mutation.mutate({ estimatesId, legalObject });
  };

  return (
    <div className="max-w-sm mx-auto p-4 font-primary">
      <h2
        className="text-4xl text-white font-primaryBold mb-4"
        id="brief"
      >
        <div>Бриф</div>
        <div className="text-2xl text-secondaryText mt-1">
          Юридические вопросы
        </div>
      </h2>

      <div className="mt-4 mb-4 text-white">
        <div className="font-primary">В какой стране вы находитесь?</div>
        <div className="font-primary text-secondaryText text-xs mt-1.5">
          Это важно для организации расчетов с исполнителями
        </div>
      </div>

      <div className="mt-4 text-white">
        <input
          className="w-full p-2 border border-primaryAccent bg-primaryAccent rounded-md focus:ring-brandPurple focus:ring-2 focus:outline-none"
          placeholder="Страна"
          {...register("countryOfResidence")}
        />
      </div>

      <PrimaryButton
        onClick={handleSubmit(onGetEstimates)}
        className={"w-full mt-20"}
      >
        Получить предварительное ТЗ
      </PrimaryButton>
    </div>
  );
};

export default StepLegalQuestions;
