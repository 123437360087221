import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";

import PrimaryButton from "../../../../components/PrimaryButton";

import Plan from "./components/Plan";
import NextSteps from "./components/NextSteps";
import UserStories from "./components/UserStories";

import { OnboardingBannerContext } from "../../contexts/OnboardingBannerContext";

import estimatesService from "../../../../services/estimates";
import leadService from "../../../../services/lead";

import iconify from "../../../../assets/iconify";

const { telegram: Telegram } = iconify;

const Estimates = () => {
  const { estimatesId } = useParams();
  const navigate = useNavigate();
  const { onSeen } = React.useContext(OnboardingBannerContext);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createLeadMutation = useMutation({
    mutationFn: leadService.create,
    onSuccess: (leadId) => {
      navigate(`/app/todo/lead/${leadId}`);
    },
  });

  const onScheduled = async () => {
    createLeadMutation.mutate({
      estimatesId,
    });
  };

  const { data, isLoading } = useQuery({
    queryKey: ["estimates", estimatesId],
    queryFn: () => estimatesService.getEstimates(estimatesId),
  });

  const {
    specialists,
    preliminaryPlan,
    preliminaryDeadline,
    preliminaryBudget,
    userStories,
  } = (data && data.estimates) || {};

  React.useEffect(() => {
    if (data && data.estimates) {
      onSeen();
    }
  }, [data, onSeen]);

  if (isLoading) {
    return (
      <div className="p-4 bg-white shadow-lg rounded-md">
        <h1 className="text-2xl font-bold mb-2">Новый проект</h1>
        <h2 className="text-xl mb-4">Оценка объемов работы</h2>
        <p className="mb-4">Анализируем ваши ответы.</p>
      </div>
    );
  }

  return (
    <div className="max-w-sm mx-auto p-4 font-primary">
      <h2 className="text-3xl text-white font-primaryBold">
        Техническое задание
      </h2>
      <p className="mb-4 text-secondaryText text-sm">
        Предварительная версия от ИИ
      </p>

      <Plan
        className="mt-8"
        plan={preliminaryPlan}
        budget={preliminaryBudget}
        deadline={preliminaryDeadline}
      />

      <UserStories className="mt-8" userStories={userStories} />

      <NextSteps className="mt-8" />

      <div className="mt-8">
        <p className="text-white font-primary">
          Остались вопросы?{" "}
          <a href="https://t.me/alpotapov" target="_blank" rel="noreferrer" className="">
            <span className="underline">Свяжитесь с нами через Telegram</span>
            <span className="no-underline"> </span>
            <Telegram className="no-underline inline w-4 h-4" />
          </a>
        </p>
        <PrimaryButton className={"w-full mt-10"} onClick={onScheduled}>
          Выбрать время консультации
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Estimates;
