const trackLandingCtaClick = () => {
  // eslint-disable-next-line no-undef
  ym(95763288, "reachGoal", "landingctaclick");
};

const trackingService = {
  trackLandingCtaClick,
};

export default trackingService;
