import { useBackendQueryWithoutAuth } from "./useBackendQueryWithoutAuth";

const useLegalDocuments = () => {
  const { data, isLoading, error } = useBackendQueryWithoutAuth({
    queryKey: ["legal"],
    url: "/api/v2/legal",
  });

  const { privacyPolicy } = data || {};

  return { privacyPolicy };
};

export default useLegalDocuments;
