import React from "react";
import { Toast } from "flowbite-react";
import { HiFire } from "react-icons/hi";

const SuccessToast = ({ message, unsetMessage }) => {
  return (
    <>
      {message ? (
        <div className="absolute right-8 top-8">
          <Toast className="bg-brandSecondaryPurple text-white font-primaryBold">
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-brandPurple text-white">
              <HiFire className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm font-normal">{message}</div>
            <Toast.Toggle className="bg-brandSecondaryPurple hover:bg-brandPurple text-white hover:text-white" onClick={unsetMessage} />
          </Toast>
        </div>
      ) : null}
    </>
  );
};

export default SuccessToast;
