import React from "react";

const PrimaryButton = ({ children, className, onClick, disabled }) => {
  return (
    <button
      className={`${className} rounded-[18px] h-[38px] ${disabled ? 'bg-primaryDarkBlue border border-middleBlue' : 'bg-mintTulip hover:bg-brightLightBlue focus:bg-brightLightBlue'}  transition-all`}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={`font-primaryBold text-13 ${disabled ? 'text-middleBlue' : 'text-darkBlue'}`}>
        {children}
      </span>
    </button>
  );
};

export default PrimaryButton;