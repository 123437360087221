import React from "react";

import TimelineCard from "../../../../../components/TimelineCard";
import TimelineLineSegment from "../../../../../components/TimelineSegment";

const StepsComponent = ({ step, onClick }) => {
  const items = [
    {
      current: step === 0,
      passed: step > 0,
      title: "Заполните бриф",
      duration: "Опишите идею в два твита и ответьте на 5 вопросов \"да/нет\"",
      color: "color9",
      onClick: onClick[0] ? onClick[0] : undefined,
    },
    {
      current: step === 1,
      passed: step > 1,
      title: "Получите предварительное ТЗ",
      duration:
        "Наш ИИ-калькулятор составит пользовательские истории и выдаст оценку сроков и стоимости",
      color: "color7",
      onClick: onClick[1] ? onClick[1] : undefined,
    },
    {
      current: step === 2,
      passed: false,
      title: "Сформируйте команду",
      duration:
        "Проведите интервью со специалистами и подпишите с ними договор",
      color: "color10",
      onClick: onClick[2] ? onClick[2] : undefined,
    },
    {
      current: step === 3,
      passed: false,
      title: "Начните проект!",
      duration:
        "Спринт АП найдет фрилансеров и организует взаимодействие с командой в стиле Agile",
      color: "color4",
      onClick: onClick[3] ? onClick[3] : undefined,
    },
  ];
  return (
    <div className="flex flex-col gap-2">
      {/* <TimelineLineSegment segmentHeight={30} top={-10} lineType={"dashed"} /> */}
      <TimelineCard item={items[0]} onClick={items[0].onClick} />
      <TimelineLineSegment segmentHeight={60} top={60} lineType={"dashed"} />
      <TimelineCard item={items[1]} onClick={items[1].onClick} />
      <TimelineLineSegment segmentHeight={60} top={175} lineType={"dashed"} />
      <TimelineCard item={items[2]} onClick={items[2].onClick} />
      <TimelineLineSegment segmentHeight={60} top={285} lineType={"dashed"} />
      <TimelineCard item={items[3]} onClick={items[3].onClick} />
    </div>
  );
};

export default StepsComponent;
