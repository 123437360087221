import { apiClient } from "./http";

const getTodoList = async () => {
  try {
    const response = await apiClient.get(`/api/v1/todo`);
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

const closeTodo = async ({ todoId }) => {
  try {
    const response = await apiClient.put(
      `/api/v1/todo/${todoId}`
    );
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

const todoService = {
  getTodoList,
  closeTodo,
};

export default todoService;
