import React from "react";
import { differenceInSeconds } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import estimatesService from "../../services/estimates";

import useScrollToAnchor from "../../hooks/useScrollToAnchor";

import CheckMarkEnabled from "../../assets/v2/icons/CheckMarkEnabled.png";
import CheckMarkDisabled from "../../assets/v2/icons/CheckMarkDisabled.png";
import CheckMarkProgress from "../../assets/v2/icons/CheckMarkProgress.png";
import { useParams } from "react-router-dom";

const IndicatorInProgress = ({ className }) => {
  return (
    <img
      src={CheckMarkProgress}
      alt="CheckMarkEnabled"
      className={`${className} rotate w-[19px] h-[19px] ml-[2px]`}
    />
  );
};

const IndicatorDone = ({ className }) => {
  return (
    <img
      src={CheckMarkEnabled}
      alt="CheckMarkEnabled"
      className={`${className} w-6 h-6`}
    />
  );
};

const IndicatorWaiting = ({ className }) => {
  return (
    <img
      src={CheckMarkDisabled}
      alt="CheckMarkEnabled"
      className={`${className} w-6 h-6`}
    />
  );
};

const Step = ({ currentIndex, index, title, classForIndicator, className }) => {
  const done = currentIndex > index;
  const inProgress = currentIndex === index;
  const indicator = done ? (
    <IndicatorDone className={classForIndicator} />
  ) : inProgress ? (
    <IndicatorInProgress className={classForIndicator} />
  ) : (
    <IndicatorWaiting className={classForIndicator} />
  );
  return (
    <div className={`${className} flex flex-row items-center gap-2 p-4 pl-0`}>
      {indicator}
      <div
        className={`${
          !done && !inProgress ? "text-middleBlue" : "text-brightLightBlue"
        }`}
      >
        {title}
      </div>
    </div>
  );
};

const calculateProgress = (startDate, expectedCompletionDate) => {
  const start = new Date(startDate);
  const end = new Date(expectedCompletionDate);
  const now = new Date();

  const totalDuration = differenceInSeconds(end, start);
  const timeElapsed = differenceInSeconds(now, start);
  const progress = Math.min(
    100,
    Math.max(0, (timeElapsed / totalDuration) * 100)
  );

  return Math.round(progress);
};

const stepProgression = [0, 10, 25, 35, 50, 65];
const lastIndex = 6;
const REFETCH_INTERVAL = 3000;

const Await = () => {
  const { estimatesId } = useParams();
  const navigate = useNavigate();
  const [startDate] = React.useState(new Date());
  const [expectedCompletionDate, setExpectedCompletionDate] = React.useState();
  const intervalRef = React.useRef(null);
  const finalizingRef = React.useRef(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [progress, setProgress] = React.useState(0);

  const onFinish = React.useCallback(() => {
    if (finalizingRef.current) return;
    finalizingRef.current = true;
    clearInterval(intervalRef.current);
    setCurrentIndex(lastIndex);
    setTimeout(() => {
      console.log("DONE!");
      navigate(`/calculator/${estimatesId}/specs`);
    }, 1000);
  }, [navigate, estimatesId]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (!expectedCompletionDate) return;
    intervalRef.current = setInterval(() => {
      setProgress(calculateProgress(startDate, expectedCompletionDate));
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [expectedCompletionDate, startDate, onFinish]);

  React.useEffect(() => {
    const nextIndex = stepProgression.findIndex(
      (stepProgress) => stepProgress > progress
    );
    if (nextIndex === -1) {
      setCurrentIndex(stepProgression.length - 1);
      return;
    }
    setCurrentIndex(nextIndex - 1);
  }, [progress]);

  const { data } = useQuery({
    queryKey: ["estimates", estimatesId],
    queryFn: () => estimatesService.getEstimates(estimatesId),
    refetchOnMount: "always",
    refetchInterval: (queryData) => {
      const { data } = queryData.state;
      const { status } = data || {};
      if (!status || status === "incomplete") return REFETCH_INTERVAL;
      return false;
    },
  });

  React.useEffect(() => {
    const { status, expectedCompletionDate: receivedDate } = data || {};
    if (!expectedCompletionDate) {
      setExpectedCompletionDate(receivedDate);
    }
    if (!status || status === "incomplete") return;
    if (status === "complete") {
      onFinish();
      return;
    }
  }, [data, onFinish, expectedCompletionDate]);

  useScrollToAnchor();

  return (
    <div className="flex-1 w-full h-full bg-primaryDarkBlue">
      <div className="font-primary pb-8 lg:hidden">
        <div className="max-w-sm mx-auto px-4">
          <div className="mt-3">
            <h2 className="text-2xl text-sinbad font-primaryBold text-center">
              ИИ-калькулятор
              <br />
              работает
            </h2>
            <div className="mt-1 text-brightLightBlue text-center">
              Просьба дождаться результата работы
              <br />
              ИИ-калькулятора и никуда не переходить
            </div>
          </div>

          <div className="mt-6 flex flex-col divide-y divide-middleBlue border-b border-middleBlue">
            <Step
              currentIndex={currentIndex}
              index={0}
              title="Анализирует собранные данные"
            />
            <Step
              currentIndex={currentIndex}
              index={1}
              title="Пишет пользовательские истории"
            />
            <Step
              currentIndex={currentIndex}
              index={2}
              title="Составляет список специалистов"
            />
            <Step
              currentIndex={currentIndex}
              index={3}
              title="Составляет план разработки"
            />
            <Step
              currentIndex={currentIndex}
              index={4}
              title="Оценивает сроки и бюджет"
            />
            <Step
              currentIndex={currentIndex}
              index={5}
              title="Готовит техническое задание"
            />
          </div>
        </div>
      </div>

      {/* for desktop */}
      <div className="font-primary pb-8 hidden lg:block mt-14">
        <div className="mx-auto px-4" style={{ width: "57rem" }}>
          <div className="mt-3">
            <h2 className="text-28 text-brightLightBlue font-primaryBold text-center mb-4">
              ИИ-калькулятор работает
            </h2>
            <div className="mt-1 text-sinbad text-md text-center">
              Просьба дождаться результата работы ИИ-калькулятора и никуда не
              переходить
            </div>
          </div>

          <div className="mt-[50px] w-[690px] text-md flex flex-wrap justify-between items-center ml-auto mr-auto">
            <Step
              currentIndex={currentIndex}
              index={0}
              title="Анализирует собранные данные"
              classForIndicator="w-7 h-7"
              className="w-[325px]"
            />
            <Step
              currentIndex={currentIndex}
              index={1}
              title="Пишет пользовательские истории"
              classForIndicator="w-7 h-7"
              className="w-[325px]"
            />
            <Step
              currentIndex={currentIndex}
              index={2}
              title="Составляет список специалистов"
              classForIndicator="w-7 h-7"
              className="w-[325px]"
            />
            <Step
              currentIndex={currentIndex}
              index={3}
              title="Составляет план разработки"
              classForIndicator="w-7 h-7"
              className="w-[325px]"
            />
            <Step
              currentIndex={currentIndex}
              index={4}
              title="Оценивает сроки и бюджет"
              classForIndicator="w-7 h-7"
              className="w-[325px]"
            />
            <Step
              currentIndex={currentIndex}
              index={5}
              title="Готовит техническое задание"
              classForIndicator="w-7 h-7"
              className="w-[325px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Await;
