import React from "react";
import { useNavigate } from "react-router-dom";

import iconify from "../assets/iconify";

const { Chevron } = iconify;

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className="w-6 h-6 text-white"
      style={{ transform: "rotate(180deg)" }}
      onClick={() => navigate(-1)}
    >
      <Chevron />
    </button>
  );
};

export default BackButton;
