import React from "react";

import iconify from "../assets/iconify";

const { EyeClosed, EyeOpen } = iconify;

const PasswordInput = ({
  id,
  register,
  helperText,
  placeholder,
  disabled,
  defaultValue,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  return (
    <div>
      <div className="relative text-white">
        <input
          id={id}
          type={isPasswordVisible ? "text" : "password"}
          className="w-full p-2 border border-primaryAccent rounded-md bg-primaryAccent focus:border-none focus:outline-none focus:ring-2 focus:ring-brandPurple"
          placeholder={placeholder}
          {...register}
          disabled={disabled}
          defaultValue={defaultValue}
          autoComplete="off"
          autoCorrect="off"
        />
        <div className="absolute top-2.5 right-4">
          <button type="button" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
            {isPasswordVisible ? <EyeOpen /> : <EyeClosed />}
          </button>
        </div>
      </div>
      <div className="text-errorRed mt-1">
        <span className="text-sm font-primary">{helperText}</span>
      </div>
    </div>
  );
};

export default PasswordInput;
