import React from "react";
import { Link } from "react-router-dom";

import Title from "../../../assets/v2/images/text_logo_sprint_ap.png";

const Topbar = ({ className }) => {
  return (
    <div className="bg-primaryDarkBlue p-3 fill-current stroke-current">
      <div className={className}>
        <div className="flex flex-row justify-between items-center border border-middleBlue rounded-[20px] h-[40px] pl-6 pr-2 py-2">
          <Link to="/">
            <img src={Title} alt="title" className="h-[18px]" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
