import React from "react";

import svgIcons from "../../../../assets/v2/svgIcons";

const {Circle, Arrow, Code, People, Nine, Three, Seven, Five} = svgIcons;

const Card = ({className, content}) => {
    const {
        id,
        text,
        SvgIcon,
        backgroundConfig,
        idSize,
        titleColor,
        prevTitle,
        title,
        titleLeft,
        paddingLeft,
        textWidth,
        svgStyles,
        svgSize,
        idColor,
        idIcons
    } = content;
    return (
        <div
            className="relative flex justify-center items-start pr-[25px] pl-[70px] pt-[60px] pb-[40px] h-full rounded-3xl font-primary"
            style={{
                border: "1px solid #3E6792",
                background: backgroundConfig,
                WebkitBackdropFilter: "blur(5px)",
                backdropFilter: "blur(5px)",
            }}>
            <div className="flex flex-row">
        <span
            className={`text-3xl font-primaryBold mr-3`}
            style={{color: titleColor}}
        >{prevTitle}</span>
                <h3
                    className="flex self-start font-primaryBold mt-2"
                    style={{color: titleColor, width: idSize}}
                >
                    {idIcons.map((IdIcon) => <IdIcon fill={idColor} width="100%" height="100%"/>)}
                </h3>
                <div
                    className={"flex flex-col"}
                    style={{width: textWidth}}
                >
         <span
             className={`text-3xl font-primaryBold pl-${titleLeft}`}
             style={{color: titleColor}}
         >{title}</span>
                    <div className={`mt-1 text-lg text-shipCove pl-${paddingLeft}`}>{text}</div>
                </div>
                <div
                    className={`absolute ${svgStyles}`}
                >
                    <SvgIcon width={svgSize} height={svgSize}/>
                </div>
            </div>
        </div>
    );
};

const cards = [
    {
        id: 9,
        idSize: "2.5rem",
        idIcons: [Nine],
        titleLeft: "3",
        idColor: "#00C2FF",
        idIconSize: "4rem",
        text: "корпоративного опыта в крупных немецких компаниях в сфере страхования (Check24), инженерии(Osram), энергетики (Sungrow) у наших проектных менеджеров. Они эффективно используют методологию AGILE и SCRAM",
        titleColor: "#00C2FF",
        prevTitle: "",
        title: "лет",
        backgroundConfig: "#112A39",
        paddingLeft: "3",
        textWidth: "75%",
        SvgIcon: Circle,
        svgStyles: "bottom-0 left-0"
    },
    {
        id: 7,
        idSize: "2.5rem",
        idIcons: [Seven],
        titleLeft: "3",
        idColor: "#A0C4D8",
        idIconSize: "4rem",
        text: "работали техническими консультантами. Формат пользовательских историй обеспечил эффективное взаимодействие Заказчика и Исполнителя и позволил применять актуальные технологии (AI, NFT, смарт-контракты, WEB3)",
        titleColor: "#A0C4D8",
        prevTitle: "в",
        title: "-ми фриланс-проектах",
        backgroundConfig: "#051322",
        paddingLeft: "3",
        textWidth: "75%",
        SvgIcon: Arrow,
        svgStyles: "top-2 right-2"
    },
    {
        id: 35,
        idSize: "5rem",
        idIcons: [Three, Five],
        titleLeft: "3",
        idColor: "#78EDFF",
        idIconSize: "4rem",
        text: "прошли наши разработчики и дизайнеры, получив призовые места и награды от Мюнхенского технологического университета, Европейского космического агентства, SAP, ETH Global. А мы организовали работу большой и распределенной команды",
        titleColor: "#78EDFF",
        prevTitle: "",
        title: "+ хакатонов",
        backgroundConfig: "#051322",
        paddingLeft: "3",
        textWidth: "75%",
        svgStyles: "bottom-0 left-0",
        SvgIcon: Code,
        svgSize: "100%",
    },
    {
        id: 3,
        idSize: "2.5rem",
        idIcons: [Three],
        titleLeft: "2",
        idColor: "#1D8FFB",
        idIconSize: "4rem",
        text: "были фуллстэк разработчиками приложений: медтехнологии (Германия), образовательный курс (Россия), бронирование помещений (Дубаи). Через 4 недели после старта клиенты Заказчика уже использовали MVP-версию",
        titleColor: "#1D8FFB",
        prevTitle: "в",
        title: "-х стартапах",
        backgroundConfig: "#13253C",
        paddingLeft: "2",
        textWidth: "75%",
        svgStyles: "bottom-0 left-0",
        SvgIcon: People,
    },
];

const WeAreStartupDesktop = ({className}) => {
    return (
        <div className={className}>
            <h2
                className="text-54 leading-56 text-mintTulip font-primaryBold mb-5"
                style={{
                    background:
                        "radial-gradient(104.46% 89.47% at 0% 50%, #A0C4D8 60.97%, rgba(154, 190, 211, 0.00) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                }}
            >
                Мы стартап с большим потенциалом
            </h2>
            <div className="flex flex-row gap-8">
                <div className="w-[45%]">
                    <Card content={cards[0]}/>
                </div>
                <div className="w-[55%]">
                    <Card content={cards[1]}/>
                </div>
            </div>

            <div className="mt-8 flex flex-row gap-8">
                <div className="w-[55%]">
                    <Card content={cards[2]}/>
                </div>
                <div className="w-[45%]">
                    <Card content={cards[3]}/>
                </div>
            </div>
        </div>
    );
};

export default WeAreStartupDesktop;
