import React from "react";

import svgIcons from "../../../../assets/v2/svgIcons";
import ModalCard from "./ModalCard";

const { Arrow, People, CircleMobile, CodeMobile, CodeModal, Nine, Five, Three, Seven } = svgIcons;

const NumberCard = ({ content }) => {
  const { id, SvgIcon, svgPosition, svgWidth, svgHeight, marginForNumber, idIcons, idColor, idWidth, idHeight } = content;
  const [isCardModalOpen, setIsCardModalOpen] = React.useState(false);
  React.useEffect(() => {
    if (isCardModalOpen) {
      document.body.style.overflow = "hidden";
    }
    if (!isCardModalOpen) {
      document.body.style.overflow = "unset";
    }
  }, [isCardModalOpen]);
  return (

    <div
      className={`h-[139px] rounded-3xl relative font-primary items-center flex overflow-hidden`}
      style={{
        border: "1px solid #3E6792",
        background: "#051322",
        WebkitBackdropFilter: "blur(5px)",
        backdropFilter: "blur(5px)",
      }}
      onClick={() => {setIsCardModalOpen(!isCardModalOpen)}}
      >
      <div
        className="flex flex-row relative top-[0] items-center m-auto"
        style={{ marginLeft: marginForNumber }}
      >
        <h3
          className={`font-primaryBold h-[125px flex items-center`}
          style={{ zIndex: 1 }}
        >
          {idIcons.map((IdIcon) => <IdIcon width={idWidth} height={idHeight} fill={idColor} />)}
        </h3>
        <div
          className={`absolute`}
          style={svgPosition}
        >
        <SvgIcon width={svgWidth} height={svgHeight} />
        </div>
      </div>
      <div className={`${isCardModalOpen ? 'block' : 'hidden'}`}>
        <ModalCard
          isOpen={isCardModalOpen} onClose={() => setIsCardModalOpen(false)}
          content={cards.find((card) => card.id === id)}
        />
      </div>
    </div>
  );
};

const cards = [
  {
    id: 9,
    idIcons: [Nine],
    titleLeft: "4rem",
    idColor: "#00C2FF",
    idIconSize: "4rem",
    text: "корпоративного опыта в крупных немецких компаниях в сфере страхования (Check24), инженерии(Osram), энергетики (Sungrow) у наших проектных менеджеров. Они эффективно используют методологию Agile и SCRUM",
    titleColor: "#00C2FF",
    prevTitle: "",
    title: "лет",
    backgroundConfig: "#0B1825",
    widthContent: "63%",
    SvgIcon: CircleMobile,
    svgStyles: "bottom-[80px] left-[-50px]",
    svgSize: "300px",
    titleSize: "text-3xl",
    textLeft: "3.7rem",
    textTop: "6.2rem",
    numberLeft: "4rem",
    numberSize: "4rem",
  },
  {
    id: 7,
    idIcons: [Seven],
    idColor: "#769BC1",
    idIconSize: "4rem",
    text: "в роли технического консультанта. Постановка задач в формате пользовательских историй, понятных и Заказчику, и Исполнителю. Доступность тестовой версии для Заказчика спустя 2 недели после начала проекта. Позволяем ловить волну и быть на острие актуальных новинок (AI, NFT, web3)/",
    titleColor: "#A0C4D8",
    title: "фриланс-проектов",
    backgroundConfig: "#0B1825",
    widthContent: "68%",
    SvgIcon: Arrow,
    svgStyles: "bottom-[0.5rem] left-[0.5rem]",
    svgSize: "240px",
    titleSize: "text-2xl",
    textLeft: "3.5rem",
    textTop: "6.2rem",
    numberTop: "4px",
    numberSize: "4rem",
    titleLeft: "2.7rem",
  },
  {
    id: 35,
    idIcons: [Three, Five],
    idColor: "#78EDFF",
    idIconSize: "5rem",
    text: "под эгидой Мюнхенского технологического университета, Европейского космического агентства, SAP, ETH Global. Для вас работают победители и призеры этих хакатонов.\nТалантливые разработчики и дизайнеры со всего мира. Мы организуем работу большой и распределенной команды.",
    titleColor: "#78EDFF",
    prevTitle: "",
    title: "+ хакатонов",
    backgroundConfig: "#0B1825",
    widthContent: "53%",
    svgStyles: "bottom-0 left-0",
    SvgIcon: CodeModal,
    svgSize: "139%",
    titleSize: "text-3xl",
    textLeft: "4rem",
    textTop: "-0.5rem",
    textSize: "1rem",
    pTop: "16rem",
    numberTop: "-0.4rem",
    numberLeft: "3rem",
    numberSize: "4rem",
    titleLeft: "2",
  },
  {
    id: 3,
    idIcons: [Three],
    idIconSize: "4rem",
    idColor: "#1D8FFB",
    text: "в роли подрядчика: медицинские технологии (Германия), бронирование помещений (Дубаи), образовательный курс в приложении (Россия). Создание MVP (минимального продукта): через четыре недели после старта проектов клиенты Заказчика уже пользовались готовым приложением.",
    titleColor: "#1D8FFB",
    prevTitle: "в",
    title: "-х",
    backgroundConfig: "#0B1825",
    widthContent: "58%",
    svgStyles: "bottom-0 left-0",
    SvgIcon: People,
    svgSize: "254px",
    titleSize: "text-3xl",
    nextTitle: "стартапах",
    pTop: "16rem",
    textLeft: "4rem",
    numberSize: "4rem",
    titleLeft: "3rem",
  },
];
const numberCards = [
  {
    id: 9,
    idIcons: [Nine],
    idColor: "#3E6792",
    idWidth: "52px",
    idHeight: "61px",
    SvgIcon: CircleMobile,
    svgWidth: "99px",
    svgHeight: "99px",
    titleColor: "#3E6792",
    svgPosition: {
      right: "-24px",
    },
    backgroundConfig: "#091A2C"
  },
  {
    id: 7,
    idIcons: [Seven],
    idColor: "#769BC1",
    idWidth: "52px",
    idHeight: "61px",
    SvgIcon: Arrow,
    svgWidth: "40px",
    svgHeight: "40px",
    titleColor: "#769BC1",
    svgPosition: {
      bottom: "0",
      left: "60px",
    },
    marginForNumber: "3rem",
    backgroundConfig: "#091A2C"
  },
  {
    id: 35,
    idIcons: [Three, Five],
    idColor: "#769BC1",
    idWidth: "52px",
    idHeight: "61px",
    SvgIcon: CodeMobile,
    svgWidth: "163px",
    svgHeight: "163px",
    titleColor: "#769BC1",
    svgPosition: {
      right: "-30px",
    },
    backgroundConfig: "#091A2C"
  },
  {
    id: 3,
    idIcons: [Three],
    idColor: "#3E6792",
    idWidth: "52px",
    idHeight: "61px",
    SvgIcon: People,
    svgWidth: "105px",
    svgHeight: "92px",
    titleColor: "#3E6792",
    svgPosition: {
      bottom: "-40px",
      left: "-4px",
    },
    backgroundConfig: "#091A2C"
  }
];

const WeAreStartup = ({ className }) => {
  return (
    <div className={className}>
      <h2
          className="text-2xl pl-4 flex flex-col lg:text-54 lg:leading-56 text-mintTulip font-primaryBold mb-8"
          style={{
            background:
              "radial-gradient(104.46% 89.47% at 46% 12%, rgb(160, 196, 216) 76.97%, rgba(154, 190, 211, 0) 100%) text",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          <span>
            Мы стартап
          </span>
          <span>
            с большим потенциалом
          </span>
        </h2>
      <div className="flex flex-row gap-6 px-[10px]">
        <div className="w-[40%]">
          <NumberCard
            content={numberCards[0]}
          />
        </div>
        <div className="w-[60%]">
            <NumberCard
              content={numberCards[1]}
            />
        </div>
      </div>

      <div className="mt-6 flex flex-row gap-6 px-[10px]">
        <div className="w-[60%]">
            <NumberCard
              content={numberCards[2]}
            />
        </div>
        <div className="w-[40%]">
            <NumberCard
              content={numberCards[3]}
            />
        </div>
      </div>
    </div>
  );
};

export default WeAreStartup;
