import React from "react";

import PlanTimelineCard from "./PlanTimelineCard";
import PlanTimelineSegment from "./PlanTimelineSegment";

const PlanTimeline = ({ timeline }) => {
  const calculateLineProps = (idx) => {
    const segmentHeight = 43;
    const distanceBetweenCircles = 44;
    const circleHeight = 16;
    const topMargin = distanceBetweenCircles * idx + circleHeight * idx + 9;
    const top = 28 + topMargin;
    const isLast = idx === timeline.length - 1;
    const lineType =
      !isLast && (timeline[idx + 1].passed || timeline[idx + 1].current)
        ? "solid"
        : "dashed";
    return { top, shouldHide: isLast, lineType, segmentHeight };
  };
  return (
    <div className="relative flex flex-col gap-1">
      {timeline.map((item, idx) => (
        <React.Fragment key={item.id}>
          <PlanTimelineCard item={item} idx={idx} />
          <PlanTimelineSegment {...calculateLineProps(idx)} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default PlanTimeline;
