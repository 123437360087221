import {Label} from "flowbite-react";
import {useForm} from "react-hook-form";
import {useMutation} from "@tanstack/react-query";

import backendActionService from "../../../services/backendAction";

import iconify from "../../../assets/iconify";
import React from "react";

const {mail: MailIcon, telegram: TelegramIcon, UserForm, EmailForm} = iconify;

const checkboxLabelTheme = {
    root: {
        base: "text-xl font-primaryLight",
    },
};

const ContactButton = ({children, className, onClick}) => {
    return (
        <button
            className={`${className} rounded-[25px] rounded border border-middleBlue bg-none py-3 mx-auto w-[216px] h-[48px] text-white hover:bg-[#3E679240] focus:bg-middleBlue transition-all`}
            onClick={onClick}
        >
      <span className="text-lg text-middleBlue font-primaryMedium">
        {children}
      </span>
        </button>
    );
};

const ContactForm = () => {
    const mutation = useMutation({
        mutationFn: backendActionService.executePost("/api/v1/contact"),
        onSuccess: (data) => {
            reset();
            console.log("message sent", data);
        },
    });
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            message: "",
            privacyPolicyAccepted: false,
        },
    });

    const onSubmit = (data) => {
        console.log("submitting", data);
        mutation.mutate(data);
    };

    return (
        <div className="hidden lg:block w-auto">
            <form onSubmit={handleSubmit(onSubmit)}
                  className="w-[462px] mx-auto mt-8 mx-4 px-8 pt-8 pb-7 rounded-2xl bg-[#3E679240] gap-8 flex flex-col">
                <div className="text-xl font-primaryMedium text-shipCove text-center">
                    Напишите нам
                </div>

                <div className="flex-1 relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <UserForm/>
                    </div>
                    <input id="name" type="text" placeholder="Ваше имя" {...register('name', {
                        required: "Пожалуйста, укажите ваше имя",
                    })}
                           className="w-full h-[48px] mx-auto ps-8 rounded-lg border border-[#3E679266] bg-darkBlue text-xl text-middleBlue placeholder-middleBlue focus:ring-[0.1px] focus:outline-none focus:ring-middleBlue"/>
                    {errors && errors.name && (
                        <div className="absolute px-1 text-errorRed">
                            <span className="text-xs">{errors.name.message}</span>
                        </div>
                    )}
                </div>

                <div className="flex-1 relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <EmailForm/>
                    </div>
                    <input id="email" type="email" placeholder="Ваш email" {...register('email', {
                        required: "Пожалуйста, укажите вашу почту",
                    })}
                           className="w-full h-[48px] mx-auto pl-8 rounded-lg border border-[#3E679266] bg-darkBlue text-xl text-middleBlue placeholder-middleBlue focus:ring-[0.1px] focus:outline-none focus:ring-middleBlue"/>
                    {errors && errors.email && (
                        <div className="absolute px-1 text-errorRed">
                            <span className="text-xs">{errors.email.message}</span>
                        </div>
                    )}
                </div>

                <div className="flex-1 relative">
                    <textarea id="message" placeholder="Сообщение" {...register('message', {
                        required: "Пожалуйста, напишите сообщение",
                        minLength: {
                            value: 10,
                            message: 'Должно быть не менее 10 символов'
                        }
                    })}
                              className="w-full min-h-[152px] max-h-[152px] mx-auto ps-3 rounded-lg border border-[#3E679266] bg-darkBlue text-xl text-middleBlue placeholder-middleBlue focus:ring-[0.1px] focus:outline-none focus:ring-middleBlue"/>
                    {errors && errors.message && (
                        <div className="absolute bottom-[-17px] px-1 text-errorRed">
                            <span className="text-xs">{errors.message.message}</span>
                        </div>
                    )}
                </div>

                <div>
                    <div className="flex-1 flex flex-row items-start gap-4">
                        <input
                            id="privacyPolicyAccepted"
                            type="checkbox"
                            className="h-6 w-6 rounded border border-middleBlue bg-[#3E679240]"
                            {...register("privacyPolicyAccepted", {
                                required:
                                    "Отправка сообщения возможна только после согласия с политикой конфиденциальности",
                            })}
                        />
                        <Label htmlFor="privacyPolicyAccepted" theme={checkboxLabelTheme} className="text-middleBlue">
                            Согласен с{" "}
                            <a href="/legal/privacyPolicy"
                               className="underline underline-offset-4 hover:text-[#3E679266] focus:text-middleBlue">
                                Политикой обработки персональных данных
                            </a>
                        </Label>
                    </div>
                    {errors && errors.privacyPolicyAccepted && (
                        <div className="text-errorRed dark:text-red-500">
              <span className="text-xs mt-2">
                {errors.privacyPolicyAccepted.message}
              </span>
                        </div>
                    )}
                </div>

                <ContactButton className="flex justify-center items-center" onClick={handleSubmit(onSubmit)}>
                    Отправить
                </ContactButton>
            </form>
        </div>
    );
};

export default ContactForm;
