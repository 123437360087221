import React from "react";
import DetailsDesktop from "./DetailsDesktop";
import DetailsMobile from "./DetailsMobile";


const Details = () => {
  return (
    <div>
      <DetailsDesktop />
      <DetailsMobile />
    </div>
  );
};

export default Details;
