import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useBackendQueryWithoutAuth } from "../../../hooks/useBackendQueryWithoutAuth";
import { useBackendMutationWithoutAuth } from "../../../hooks/useBackendMutationWithoutAuth";

import ScheduleDesktop from "./ScheduleDesktop";
import { useWindowSize } from "@uidotdev/usehooks";
import ScheduleMobile from "./ScheduleMobile";
import ScheduleMeetingModal from "./ScheduleMeetingModal/ScheduleMeetingModal";
import { EmailInputDesktop } from "../Idea/IdeaDesktop";
import CheckboxInput from "../components/Checkbox";
import useLegalDocuments from "../../../hooks/useLegalDocuments";
import { EmailInput } from "../Idea/IdeaMobile";

const ScheduleMeeting2 = () => {
  const size = useWindowSize();
  const navigate = useNavigate();

  const [chosenDateIndex, setChosenDateIndex] = useState(undefined);
  const [chosenSlotIndex, setChosenSlotIndex] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);

  const slotSelectionIsDisabled = !chosenDateIndex && chosenDateIndex !== 0;

  const { data: slots } = useBackendQueryWithoutAuth({
    url: "/api/v2/onboarding/meeting",
    queryKey: ["meetingSlots"],
  });

  const mutation = useBackendMutationWithoutAuth({
    url: `/api/v2/onboarding/meeting/direct`,
    method: "POST",
    mutationKey: ["onboardingMeeting"],
    data: {},
    onSuccess: () => {
      setOpenModal(true);
    },
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      day: "",
      time: "",
      email: "",
      privacyPolicyAccepted: false,
    },
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setPageTitle, setOnBack } = useOutletContext();

  React.useEffect(() => {
    setPageTitle("Время консультации");
    setOnBack(() => () => {
      navigate(-1);
    });
  }, [setPageTitle, setOnBack, navigate]);

  const onChooseTime = (data) => {
    console.log("submit:", data);
    if (chosenDateIndex === undefined || chosenSlotIndex === undefined) {
      return;
    }
    if (slots) {
      mutation.mutate({
        date: slots[chosenDateIndex].date,
        slot: slots[chosenDateIndex].slots[chosenSlotIndex],
      });
      setOpenModal(true);
    }
  };

  if (!slots) {
    return <div className="flex-1 lg:bg-primaryDarkBlue bg-[#091A2C]"></div>;
  }
  return (
    <div
      onSubmit={handleSubmit(onChooseTime)}
      className={`flex-1 lg:bg-primaryDarkBlue bg-[#091A2C] ${
        openModal ? "blur-2xl" : "blur-none"
      }`}
    >
      {size.width < 1024 ? (
        <>
          <EmailWithCheckbox
            register={register}
            watch={watch}
            errors={errors}
          />
          <ScheduleMobile
            slots={slots}
            chosenDateIndex={chosenDateIndex}
            setChosenDateIndex={setChosenDateIndex}
            chosenSlotIndex={chosenSlotIndex}
            setChosenSlotIndex={setChosenSlotIndex}
            slotSelectionIsDisabled={slotSelectionIsDisabled}
            onPress={() => handleSubmit(onChooseTime)}
          />
        </>
      ) : (
        <>
          <EmailWithCheckboxDesktop
            register={register}
            watch={watch}
            errors={errors}
          />
          <ScheduleDesktop
            slots={slots}
            chosenDateIndex={chosenDateIndex}
            setChosenDateIndex={setChosenDateIndex}
            chosenSlotIndex={chosenSlotIndex}
            setChosenSlotIndex={setChosenSlotIndex}
            slotSelectionIsDisabled={slotSelectionIsDisabled}
            onPress={() => handleSubmit(onChooseTime)}
          />
        </>
      )}
      {slots[chosenDateIndex]?.slots[chosenSlotIndex] && (
        <ScheduleMeetingModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          time={slots[chosenDateIndex]?.slots[chosenSlotIndex]}
        />
      )}
    </div>
  );
};

export default ScheduleMeeting2;

const EmailWithCheckboxDesktop = ({ register, errors, watch }) => {
  const { privacyPolicy } = useLegalDocuments();
  return (
    <div className="flex flex-row justify-center items-center w-full max-w-3xl mx-auto mt-5 gap-3">
      <div className="w-1/2 flex flex-col">
        <EmailInputDesktop register={register} isActive={true} />
        {errors && errors.email && (
          <div className="text-errorRed">
            <span className="text-sm mt-2">{errors.email.message}</span>
          </div>
        )}
      </div>
      <div className="w-1/2 flex flex-col justify-start">
        <div className="flex flex-row gap-2">
          <CheckboxInput
            id="privacyPolicyAccepted"
            value={watch("privacyPolicyAccepted")}
            register={{
              ...register("privacyPolicyAccepted", {
                required:
                  "Использование ИИ-калькулятора возможно только после согласия с политикой конфиденциальности",
              }),
            }}
            className="bg-primaryDarkBlue w-7 h-7 checked:bg-primaryDarkBlue"
          />
          <label
            htmlFor="privacyPolicyAccepted"
            className="select-none text-middleBlue font-primaryLight text-lg leading-5"
          >
            Соглашаюсь с{" "}
            <a
              className="underline"
              href={privacyPolicy}
              target="_blank"
              rel="noreferrer"
            >
              Политикой обработки персональных данных
            </a>
          </label>
        </div>
        {errors && errors.privacyPolicyAccepted && (
          <div className="text-errorRed">
            <span className="text-sm mt-2">
              {errors.privacyPolicyAccepted.message}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
const EmailWithCheckbox = ({ register, errors, watch }) => {
  const { privacyPolicy } = useLegalDocuments();
  return (
    <div className="flex flex-col justify-center self-center w-full max-w-sm mx-auto px-5">
      <EmailInput register={register} isActive={true} />
      {errors && errors.email && (
        <div className="text-errorRed">
          <span className="text-sm mt-2">{errors.email.message}</span>
        </div>
      )}
      <div className="flex flex-col justify-start gap-2 mt-3">
        <div className="flex flex-row items-center gap-2">
          <CheckboxInput
            id="privacyPolicyAccepted"
            value={watch("privacyPolicyAccepted")}
            register={{
              ...register("privacyPolicyAccepted", {
                required:
                  "Использование ИИ-калькулятора возможно только после согласия с политикой конфиденциальности",
              }),
            }}
          />
          <label
            htmlFor="privacyPolicyAccepted"
            className="select-none text-middleBlue font-primary text-13"
          >
            Соглашаюсь с{" "}
            <a
              className="underline"
              href={privacyPolicy}
              target="_blank"
              rel="noreferrer"
            >
              Политикой обработки персональных данных
            </a>
          </label>
        </div>
        {errors && errors.privacyPolicyAccepted && (
          <div className="text-errorRed">
            <span className="text-sm mt-2">
              {errors.privacyPolicyAccepted.message}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
