import React from "react";
import { Link } from "react-router-dom";

import config from '../../../config';
import Title from "../../../assets/images/title.png";
import iconify from "../../../assets/iconify";

const { inn, ogrnip, name } = config.legalEntity;

const Footer = ({
  hideLogo,
  hideTermsOfUsage,
  hideOffer,
  hidePrivacyPolicy,
}) => {
  const ArrowUp = iconify.arrowUp;
  return (
    <div className="bg-page">
      <div className="max-w-sm mx-auto p-4">
        <div className="flex flex-row justify-between items-center">
          {!hideLogo ? (
            <img src={Title} alt="title" className="h-[15px]" />
          ) : (
            <div className="h-[15px]" />
          )}
          <button
            className="text-white w-8 h-8"
            onClick={() => window.scrollTo(0, 0)}
          >
            <ArrowUp />
          </button>
        </div>
        <div className="w-1/2 mt-6 text-white flex flex-col gap-4">
          {!hideTermsOfUsage && (
            <Link to="/legal/termsOfUsage" className="underline">
              Условия использования
            </Link>
          )}
          {!hideOffer && (
            <Link to="/legal/offer" className="underline">
              Договор-оферта
            </Link>
          )}
          {!hidePrivacyPolicy && (
            <Link to="/legal/privacyPolicy" className="underline">
              Политика обработки персональных данных
            </Link>
          )}
        </div>
        <div className="mt-4 mb-8 text-white font-primary flex flex-row justify-between items-end">
          <div>
            <div>{name}</div>
            <div>ИНН {inn}</div>
            <div>ОГРН {ogrnip}</div>
          </div>
          <div>© 2023</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
