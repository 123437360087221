const save = ({ estimatesId, hasSeenEstimates }) => {
  const existingEstimates = JSON.parse(localStorage.getItem("estimates"));
  const { estimatesId: exisitingEstimatesId } = existingEstimates || {};
  localStorage.setItem(
    "estimates",
    JSON.stringify({
      ...(exisitingEstimatesId ? existingEstimates : { estimatesId }),
      ...(hasSeenEstimates ? { hasSeenEstimates } : {}),
    })
  );
};

const load = () => {
  const estimates = localStorage.getItem("estimates");
  if (!estimates) {
    return null;
  }
  return JSON.parse(estimates);
};

const estimatesRepository = {
  save,
  load,
};

export default estimatesRepository;
