import React from "react";
import RadioInput from "../components/Radio";
import {differenceInCalendarDays} from "date-fns";
import {formatInTimeZone} from "date-fns-tz";


function ItemTime({slot, index, isActive, onSelect}) {

    return (
        <button
            className={`flex-1 rounded-xl bg-primaryDarkBlue pt-3 pb-3 gap-3 flex flex-row justify-center items-center ${isActive ? 'border-[#036A90] cursor-default' : 'lg:border-[#3E679266] border-[#051322]'} border-[1px]`}
            onClick={onSelect}>
            <RadioInput
                id={index}
                value={slot}
                currentValue={index}
                // register={{ ...register(date) }}
                color="bg-primaryDarkBlue"
                backgroundSize="27px 27px"
                className="border-[#1C354F]"
                style={{borderColor: "#1C354F"}}
                isActive={isActive}
            />
            <div
                className={`lg:text-lg text-base ${isActive ? 'text-[#00C2FF]' : 'text-shipCove'} font-primaryLight`}>
                {`${formatInTimeZone(
                    slot.start,
                    "Europe/Moscow",
                    "HH:mm"
                )} - ${formatInTimeZone(slot.end, "Europe/Moscow", "HH:mm")}`}
            </div>
        </button>
    )
}

export default ItemTime
