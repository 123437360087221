import React from "react";
import { useParams, useLocation } from "react-router-dom";

import Appbar from "../../../../components/Appbar";
import BackButton from "../../../../components/BackButton";

import useBackendQuery from "../../../../hooks/useBackendQuery";

import BehanceIcon from "../../../../assets/images/Behance.png";
import CheckedRound from "../../../../assets/icons/CheckedRound.png";
import RankSenior from "../../../../assets/icons/RankSenior.png";
import ContactSection from "./components/ContactSection";

const action = ({ specialistProfileId }) => ({
  method: "GET",
  url: `/api/v1/specialist/${specialistProfileId}`,
});

const SpecialistProfile = () => {
  const { profileId } = useParams();
  const { state: actions } = useLocation();
  const { CONTACTED } = actions;
  const { data, error, loading } = useBackendQuery({
    action: action({ specialistProfileId: profileId }),
    queryKey: ["specialistProfile", profileId],
  });
  console.log({ profileId, data });
  const { specialistDetails } = data || {};
  const {
    name,
    summary,
    hourlyRate,
    skills,
    industries,
    clients,
    whyMe,
    legalForms,
  } = specialistDetails || {};
  return (
    <div className="fill-current stroke-current w-full">
      <Appbar title="UI/UX-дизайнер" backButton={<BackButton />} />
      <div className="max-w-sm mx-auto text-white font-primary">
        <div className="px-4 py-4">
          <div className="bg-primaryText flex flex-row gap-4 p-4 rounded-2xl">
            <div>
              <img
                src="https://via.placeholder.com/50x66"
                alt="Specialist"
                className="rounded-md"
              />
            </div>
            <div className="flex flex-col grow">
              <h2 className="font-primaryBold text-base">{name}</h2>
              <div className="text-secondaryText text-xs">{summary}</div>
              <div className="text-secondaryText text-xs">{hourlyRate}</div>
            </div>
            <div className="flex flex-col justify-around">
              <img
                className="w-[22px] h-[22px]"
                src={BehanceIcon}
                alt="Behance"
              />
            </div>
          </div>
          <div className="flex flex-row justify-between p-4 text-xs">
            <div className="flex flex-row items-center gap-2">
              <img className="w-4 h-4" src={CheckedRound} alt="Checked" />
              <div>Спринт АП доверяет</div>
            </div>
            <div className="flex flex-row items-center gap-2">
              <img className="w-4 h-4" src={RankSenior} alt="Senior" />
              <div>Senior</div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="px-4">
            <h2 className="font-primaryBold text-cherub">Компетенции</h2>
            <ul className="list-disc pl-5">
              {skills &&
                skills.map((skill) => (
                  <li key={skill} className="text-secondaryText text-sm">
                    {skill}
                  </li>
                ))}
            </ul>
          </div>

          <div className="px-4">
            <h2 className="font-primaryBold text-cherub">
              Сфера специализации
            </h2>
            <ul className="list-disc pl-5">
              {industries &&
                industries.map((industry) => (
                  <li key={industry} className="text-secondaryText text-sm">
                    {industry}
                  </li>
                ))}
            </ul>
          </div>

          <div className="px-4">
            <h2 className="font-primaryBold text-cherub">Клиенты</h2>
            <ul className="list-disc pl-5">
              {clients &&
                clients.map((client) => (
                  <li key={client} className="text-secondaryText text-sm">
                    {client}
                  </li>
                ))}
            </ul>
          </div>

          <div className="px-4">
            <h2 className="font-primaryBold text-cherub">
              Почему я подхожу для вашего проекта?
            </h2>
            <div className="text-secondaryText text-sm">{whyMe}</div>
          </div>

          <div className="px-4">
            <h2 className="font-primaryBold text-cherub">
              Система налогообложения
            </h2>
            <ul className="list-disc pl-5">
              {legalForms &&
                legalForms.map((legalForm) => (
                  <li key={legalForm} className="text-secondaryText text-sm">
                    {legalForm}
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {CONTACTED && <ContactSection CONTACTED={CONTACTED} profileId={profileId} />}
      </div>
    </div>
  );
};

export default SpecialistProfile;
