import React from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "./useAuth";

const Logout = ({ children }) => {
  const navigate = useNavigate();
  const { onLogout } = useAuth();

  React.useEffect(() => {
    if (!onLogout) return;
    onLogout();
    navigate("/");
  }, [onLogout, navigate])

  return <div className="w-full h-full min-h-screen bg-page" />;
};

export default Logout;
