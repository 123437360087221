import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import TextInput from "../../../../../../../components/TextInput";
import PrimaryButton from "../../../../../../../components/PrimaryButton";

import backendActionService from "../../../../../../../services/backendAction";
import PayLaterButton from "./PayLaterButton";

const PaymentDetailsForm = ({ payload, leadId }) => {
  const queryClient = useQueryClient();
  const { paymentDetails, questionKey } = payload;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: paymentDetails.name,
      email: paymentDetails.email,
      phone: "",
    },
  });

  const { action } = payload;

  const mutation = useMutation({
    mutationFn: backendActionService.executePost(action.url),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: leadId ? ["lead", leadId] : ["todoList"],
      });
    },
  });

  const onAnswer = (data) => {
    mutation.mutate({
      value: data,
      questionKey: questionKey,
    });
  };

  return (
    <div className="flex flex-col gap-2 font-primary">
      <p className="text-white">Сведения для оплаты</p>
      <div className="mt-2">
        <div className="mb-2 block">
          <label htmlFor="name">ФИО</label>
        </div>
        <TextInput
          id="name"
          type="text"
          register={register("name", {
            required: "Пожалуйста, укажите ФИО",
            pattern: {
              value: /^[\u0400-\u04FF\s]+$/,
              message: "Пожалуйста, используйте только кириллицу",
            },
          })}
          color={errors && errors.name ? "failure" : "gray"}
          helperText={
            errors && errors.name && <>{errors.name.message}</>
          }
        />
      </div>
      <div>
        <div className="mb-2 block">
          <label htmlFor="email">Email</label>
        </div>
        <TextInput
          id="email"
          type="email"
          register={register("email", {
            required: "Пожалуйста, укажите Ваш email",
            pattern: {
              value: /.+@.+\..+/,
              message: "Введите действительный адрес электронной почты"
            }
          })}
          color={errors && errors.email ? "failure" : "gray"}
          helperText={errors && errors.email && <>{errors.email.message}</>}
        />
      </div>
      <div>
        <div className="mb-2 block">
          <label htmlFor="phone">Телефон</label>
        </div>
        <TextInput
          id="phone"
          type="text"
          register={register("phone", {
            required: "Пожалуйста, укажите телефон",
            // pattern: {
            //   value: /^[\u0400-\u04FF]+$/,
            //   message: "Пожалуйста, используйте только кириллицу",
            // },
          })}
          color={errors && errors.phone ? "failure" : "gray"}
          helperText={errors && errors.phone && <>{errors.phone.message}</>}
        />
      </div>

      <PrimaryButton className="mt-10" onClick={handleSubmit(onAnswer)}>
        Сохранить реквизиты
      </PrimaryButton>
    </div>
  );
};

export default PaymentDetailsForm;
