import React from "react";
import { useForm } from "react-hook-form";

import useBackendAction from "../../../../../../../hooks/useBackendAction";
import PrimaryButton from "../../../../../../../components/PrimaryButton";
import TextInput from "../../../../../../../components/TextInput";
import SecondaryButton from "../../../../../../../components/SecondaryButton";

const Open = ({ payload }) => {
  const { actions, deadline, projectLink, customerTelegramLink } = payload;
  const { submit } = actions;
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      figmaLink: "",
    },
  });

  const submitMutation = useBackendAction({
    action: submit,
    onSuccess: () => {
      console.log("Accepted");
    },
    invalidateQueriesKey: ["todoList"],
  });

  const onSubmit = (data) => {
    submitMutation.mutate(data);
  };

  return (
    <div className="flex flex-col gap-4 font-primary">
      <p>Дедлайн: {deadline}</p>
      <SecondaryButton onClick={() => window.open(projectLink, "_blank")}>
        Перейти к материалам проекта
      </SecondaryButton>
      <SecondaryButton onClick={() => window.open(customerTelegramLink, "_blank")}>
        Перейти в чат с заказчиком
      </SecondaryButton>

      <div className="mt-2">
        <div className="mb-2 block">
          <label htmlFor="figmaLink">Ссылка на Figma</label>
        </div>
        <TextInput
          id="figmaLink"
          type="text"
          register={register("figmaLink", {
            required: "Пожалуйста, укажите ссылку на Figma",
          })}
          placeholder={"https://www.figma.com/file/..."}
          color={errors && errors.figmaLink ? "failure" : "gray"}
          helperText={errors && errors.figmaLink && <>{errors.figmaLink.message}</>}
        />
      </div>

      <PrimaryButton onClick={handleSubmit(onSubmit)}>
        Сдать дизайн
      </PrimaryButton>
    </div>
  );
};

export default Open;
