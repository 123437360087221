import React from "react";

import CheckboxUnchecked from "../../../assets/v2/icons/CheckboxDisabled.png";
import CheckboxChecked from "../../../assets/v2/icons/CheckboxEnabled.png";

const CheckboxInput = ({
  id,
  register,
  value,
  className,
  onClick,
  disabled,
}) => {
  return (
    <input
      id={id}
      type="checkbox"
      className={`${className} w-6 h-6 p-0 m-0 bg-bigStone rounded border-0 ring-none hover:ring-0 hover:border-0 checked:bg-bigStone checked:outline-0 checked:border-0 checked:ring-0 focus:ring-0 focus:border-0 focus:outline-0 focus:ring-offset-0 hover:outline-0`}
      style={{
        backgroundImage:
          (value && value.length > 0) || value === true
            ? `url(${CheckboxChecked})`
            : `url(${CheckboxUnchecked})`,
        backgroundSize: "cover",
      }}
      {...register}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default CheckboxInput;
