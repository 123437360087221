import React from "react";

import TextInputCopy from "../../../../../../components/TextInputCopy";

const Open = ({ payload }) => {
  const { startTime, joinUrl } = payload;

  const russianFormatted = new Date(startTime).toLocaleString("ru-RU", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "long",
  });
  return (
    <div className="flex flex-col gap-4">
      <p className="font-primary">
        Консультация по техническому заданию назначена на{" "}
        <span className="font-primarySemiBold">{russianFormatted}</span>.
      </p>
      <TextInputCopy id="joinUrl" type="text" defaultValue={joinUrl} />
    </div>
  );
};

export default Open;
