import Emitter from "tiny-emitter";

class EventEmitterSingleton {
  static getInstance() {
    if (!this.instance) {
      this.instance = new EventEmitterSingleton();
    }
    return this.instance;
  }

  constructor() {
    if (EventEmitterSingleton.instance) {
      return EventEmitterSingleton.instance;
    }

    this.emitter = new Emitter();

    this.on = this.emitter.on.bind(this.emitter);
    this.off = this.emitter.off.bind(this.emitter);
    this.emit = this.emitter.emit.bind(this.emitter);

    EventEmitterSingleton.instance = this;
  }
}

const eventEmitter = EventEmitterSingleton.getInstance();

export default eventEmitter;
