import React from "react";
import TextareaAutosize from "react-textarea-autosize";

import CheckboxInput from "../components/Checkbox";

import EmailEmpty from "../../../assets/v2/icons/EmailEmpty.png";
import EmailFilled from "../../../assets/v2/icons/EmailFilled.png";

import svgIcons from "../../../assets/v2/svgIcons";

import "../components/spinner.css";

const { ArrowUp } = svgIcons;

export const EmailInputDesktop = ({ register, isActive }) => {
  return (
    <div className="relative text-brightLightBlue mr-6">
      <img
        src={isActive ? EmailFilled : EmailEmpty}
        alt="Email"
        className="absolute top-3 left-2 w-7 h-7"
      />
      <input
        className="w-full p-2.5 pl-10 text-20 border border-middleBlue rounded-2xl bg-primaryDarkBlue focus:outline-none focus:ring-2 focus:ring-middleBlue placeholder-style"
        placeholder="Ваш email"
        {...register("email", {
          required: "Пожалуйста, укажите ваш email",
        })}
      />
    </div>
  );
};

const SkipButton = ({ onClick, className, active }) => {
  const activeStyle = `bg-brightLightBlue border-brightLightBlue text-bigStone`;
  const disabledStyle = `bg-primaryDarkBlue border-middleBlue text-middleBlue`;
  return (
    <div className={className}>
      <button
        className={`text-sm flex flex-row items-center pl-[10px] border rounded-2xl font-primary ${
          active ? activeStyle : disabledStyle
        } transition-all`}
        onClick={onClick}
      >
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>Пропустить</div>
        <div
          style={{ transform: "rotate(90deg)", width: 24, height: 24 }}
          className="ml-1 flex flex-row justify-around items-center"
        >
          <ArrowUp width={8} height={8} />
        </div>
      </button>
    </div>
  );
};

const IdeaDesktop = ({
  register,
  watch,
  errors,
  handleSubmit,
  progress,
  isContinueButtonActive,
  setIsModalCancelOpen,
  onGetEstimates,
  isModalCancelOpen,
  contentConfig,
  showSkipIdea,
  showSkipUsp,
  isPending,
  privacyPolicy,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full h-full">
      <div className="flex-1 w-full h-full">
        <div className="w-full pt-6 font-primary pb-2 rounded-b-[40px]">
          <div className="mx-auto px-4" style={{ width: "57rem" }}>
            <div className="max-w-lg m-auto">
              <div className="w-full py-3 px-4">
                <div className="h-1 rounded-2xl border border-middleBlue">
                  <div
                    className="h-full bg-brightLightBlue rounded-l-2xl"
                    style={{ width: `${progress}%` }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <h2 className="text-sinbad font-primaryLight text-22">
                Оставьте вашу почту и мы отправим вам предварительное ТЗ
              </h2>
            </div>

            <div className="flex items-start justify-between w-full mt-3">
              <div className="w-1/2 flex flex-col">
                <EmailInputDesktop register={register} isActive={true} />
                {errors && errors.email && (
                  <div className="text-errorRed">
                    <span className="text-sm mt-2">{errors.email.message}</span>
                  </div>
                )}
              </div>
              <div className="w-1/2 flex flex-col justify-start">
                <div className="flex flex-row gap-2">
                  <CheckboxInput
                    id="privacyPolicyAccepted"
                    value={watch("privacyPolicyAccepted")}
                    register={{
                      ...register("privacyPolicyAccepted", {
                        required:
                          "Использование ИИ-калькулятора возможно только после согласия с политикой конфиденциальности",
                      }),
                    }}
                    className="bg-primaryDarkBlue w-7 h-7 checked:bg-primaryDarkBlue"
                  />

                  <label
                    htmlFor="privacyPolicyAccepted"
                    className="select-none text-middleBlue font-primaryLight text-20 leading-5"
                  >
                    Соглашаюсь с{" "}
                    <a
                      className="underline"
                      href={privacyPolicy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Политикой обработки персональных данных
                    </a>
                  </label>
                </div>
                {errors && errors.privacyPolicyAccepted && (
                  <div className="text-errorRed">
                    <span className="text-sm mt-2">
                      {errors.privacyPolicyAccepted.message}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-12 mb-4">
              <div
                className={`font-primaryLight text-20 text-middleBlue text-sinbad`}
              >
                Опишите вашу идею в два твита
              </div>
              <div className="font-primary text-base mt-1.5 text-errorRed">
                {errors.ideaDescription?.message}
              </div>
            </div>

            <div className="mt-4 text-brightLightBlue text-20">
              <TextareaAutosize
                className="w-full p-4 border border-middleBlue rounded-2xl bg-primaryDarkBlue focus:border-none focus:outline-none focus:ring-2 focus:ring-middleBlue placeholder-style"
                placeholder="Без каких функций не имеет смысла показывать приложение конечному
            пользователю?"
                {...register("ideaDescription", {
                  required: "Пожалуйста, опишите вашу идею",
                  minLength: {
                    value: 280,
                    message: "Минимум 280 символов",
                  },
                  maxLength: {
                    value: 560,
                    message: "Максимум 560 символов",
                  },
                })}
              />
            </div>

            {showSkipIdea ? (
              <SkipButton
                className="mt-3 ml-auto w-fit"
                active
                onClick={() => setIsModalCancelOpen(true)}
              />
            ) : (
              <div className="mt-3 ml-auto w-fit h-[36px]"></div>
            )}

            <div className="mt-4 mb-4">
              <div
                className={`font-primaryLight text-20 text-middleBlue text-sinbad`}
              >
                Ваше уникальное торговое предложение
              </div>
              <div className="font-primary text-base mt-1.5 text-errorRed">
                {errors.uspDescription?.message}
              </div>
            </div>

            <div className="mt-4 text-20 text-brightLightBlue">
              <TextareaAutosize
                className="w-full p-4 border border-middleBlue rounded-2xl bg-primaryDarkBlue focus:border-none focus:outline-none focus:ring-2 focus:ring-middleBlue placeholder-style"
                placeholder="Какую задачу клиента решает ваше приложение? Какая функция очень полезна пользователю? В чем новизна вашего проекта?"
                {...register("uspDescription", {
                  required:
                    "Пожалуйста, опишите ваше уникальное торговое предложение",
                  minLength: {
                    value: 20,
                    message: "Минимум 20 символов",
                  },
                  maxLength: {
                    value: 280,
                    message: "Максимум 280 символов",
                  },
                })}
              />
            </div>

            {showSkipUsp ? (
              <SkipButton
                className="mt-3 ml-auto w-fit"
                active
                onClick={() => setIsModalCancelOpen(true)}
              />
            ) : (
              <div className="mt-3 ml-auto w-fit h-[36px]"></div>
            )}
          </div>
        </div>
        <div className="px-4 pb-8 flex flex-row justify-around">
          <button
            disabled={isPending}
            className={`w-[300px] h-[60px] my-5 rounded-[50px] text-lg ${
              !isContinueButtonActive
                ? "bg-primaryDarkBlue border border-middleBlue"
                : "bg-mintTulip hover:bg-brightLightBlue focus:bg-brightLightBlue"
            }  transition-all`}
            onClick={handleSubmit(onGetEstimates)}
          >
            {isPending ? (
              <div className="flex justify-center items-center">
                <div className="spinner"></div>
              </div>
            ) : (
              <span
                className={`font-primaryBold text-lg ${
                  !isContinueButtonActive ? "text-middleBlue" : "text-darkBlue"
                }`}
              >
                Далее
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default IdeaDesktop;
