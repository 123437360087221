import React from "react";

import TabCandidates from "./components/TabCandidates";
import TabResponses from "./components/TabResponses";

const Open = ({ className, payload, todoId }) => {
  const { candidates } = payload;

  const candidatesTab = Object.entries(candidates).reduce(
    (acc, [key, candidate]) => {
      if (
        candidate.state === "NEW" ||
        candidate.state === "CONTACTED" ||
        candidate.state === "REJECTED"
      ) {
        acc[key] = candidate;
      }
      return acc;
    },
    {}
  );

  const responsesTab = Object.entries(candidates).reduce(
    (acc, [key, candidate]) => {
      if (candidate.state === "INTERESTED" || candidate.state === "HIRED") {
        acc[key] = candidate;
      }

      return acc;
    },
    {}
  );

  console.log({ candidatesTab, responsesTab });

  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <div className="flex flex-col gap-4">
      <div>
        Мы рекомендуем вам проверенных дизайнеров из нашего пула талантов.
      </div>
      <div>
        Ознакомьтесь с их профилями и свяжитесь с понравившимися через Телеграм.
      </div>
      <div className="rounded-md shadow-sm w-full flex flex-row" role="group">
        <button
          type="button"
          className="grow px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          onClick={() => setActiveTab(0)}
        >
          Кандидаты
        </button>
        <button
          type="button"
          className="grow px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          onClick={() => setActiveTab(1)}
        >
          Отклики
        </button>
      </div>
      {activeTab === 0 && (<TabCandidates candidates={candidatesTab} todoId={todoId} />)}
      {activeTab === 1 && (<TabResponses responses={responsesTab} todoId={todoId} />)}
    </div>
  );
};

export default Open;
