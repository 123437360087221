import React from "react";

const UserStories = ({ className, userStories }) => {
  const storiesWithIds = userStories.map((story, idx) => ({
    ...story,
    id: idx,
  }));
  return (
    <div className={className}>
      <h3 className="text-xl text-white font-primary">
        Пользовательские истории
      </h3>
      <p className="text-secondaryText font-primary mt-2">
        Изменения в техническое задание и пользовательские истории можно внести во время консультации с нашим специалистом.
      </p>
      <div className="mt-4 flex flex-row gap-4 pb-3 overflow-x-scroll text-white">
        {storiesWithIds.map((story, index) => (
          <div className="shrink-0 w-[300px] rounded-xl bg-primaryText p-5" key={story.id}>
            <div className="font-primaryBold text-base mb-2">/ {index + 1}</div>
            {story.story}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserStories;
