import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import TodoList from "./components/TodoList";
import SuccessToast from "./components/SuccessToast";
import HintCompotent from "./components/HintComponent";

import Appbar from "../../../../components/Appbar";

import todoService from "../../../../services/todo";

import "./checkboxAnimation.css";

const LeadTodoList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data } = useQuery({
    queryKey: ["todoList"],
    queryFn: () => todoService.getTodoList(),
    initialData: [],
  });

  const { todoList, upcomingList, title } = data || {};

  const closeTodoMutation = useMutation({
    mutationFn: ({ todoId }) => todoService.closeTodo({ todoId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["todoList"] });
      setMessage("Задача выполнена!");
    },
    onError: () => {},
  });

  const [message, setMessage] = React.useState(undefined);

  const onTodoClosed = ({ todoId }) => {
    closeTodoMutation.mutate({ todoId });
  };

  const onGoToTodo = ({ todoId }) => {
    navigate(`/app/todo/${todoId}`);
  }

  return (
    <>
      <div className="bg-primaryText w-full mb-2">
        {/* <Topbar /> */}
        <Appbar title={title} />
      </div>
      <div className="text-white max-w-sm mx-auto px-4 pb-4 fill-current stroke-current">
        <TodoList
          upcomingList={upcomingList || []}
          todoList={todoList || []}
          onTodoClosed={onTodoClosed}
          onGoToTodo={onGoToTodo}
          message={message}
          unsetMessage={() => setMessage(undefined)}
        />
      </div>
      <SuccessToast
        message={message}
        unsetMessage={() => setMessage(undefined)}
      />
    </>
  );
};

export default LeadTodoList;
