import React from "react";

import EasyProcess from "../../../assets/v2/images/Easy_Process.png";
import OnlineControl from "../../../assets/v2/images/Control_Online.png";
import SafePayment from "../../../assets/v2/images/Safe_Payment.png";

const Card = ({ content }) => {
  const { title, description, image, style } = content;
  return (
    <div
      className="py-6 rounded-3xl font-primary flex flex-col items-center gap-3 lg:w-1/3 lg:border-t lg:border-b lg:border-brightBlue"
      style={style}
    >
      <img src={image} alt="placeholder" className="w-[87px] h-[85px]" />
      <h3 className="text-xl lg:text-28 font-primaryBold text-sinbad">{title}</h3>
      <div className="px-8 text-sm lg:text-base font-primary text-center text-shipCove">
        {/* lg:text-22 lg:leading-normal */}
        {description}
      </div>
    </div>
  );
};

const cards = [
  {
    id: 1,
    title: "Простой процесс",
    description:
      "Мы подберем компетентных специалистов под ваш проект и снимем с вас вопросы сложной технической коммуникации",
    image: EasyProcess,
    style: {
      backgroundColor: "#0F3444",
    },
  },
  {
    id: 2,
    title: "Контроль — онлайн",
    description:
      "В любой момент у вас есть доступ: к команде, записям презентаций, ссылкам на ресурсы, написанному коду — все под рукой",
    image: OnlineControl,
    style: {
      backgroundColor: "#1B344F",
    },
  },
  {
    id: 3,
    title: "Безопасная оплата",
    description:
      "Cервис “Безопасная сделка” от Тинькофф Банк гарантирует сохранность средств и контролирует оплату после выполнения условий договора",
    image: SafePayment,
    style: {
      backgroundColor: "#0A284C",
    },
  },
];

const WhyUs = ({ className }) => {
  return (
    <div className={className}>
      <h2
        className="text-2xl lg:text-54 lg:leading-56  text-mintTulip font-primaryBold"
        style={{
          background:
            "linear-gradient(122deg, #B5D4E5 39.71%, rgba(187, 217, 234, 0.00) 102.2%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Почему <br className="lg:hidden" />
        выбирают нас
      </h2>

      <div className="mt-8 flex flex-col gap-4 lg:flex-row">
        <Card content={cards[0]} />
        <Card content={cards[1]} />
        <Card content={cards[2]} />
      </div>
    </div>
  );
};

export default WhyUs;
