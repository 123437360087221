import React from "react";

import TextInput from "../../../../../components/TextInput";
import CheckboxInput from "../../../../../components/CheckboxInput";
import PasswordInput from "../../../../../components/PasswordInput";

const RegistrationForm = ({ errors, register, watch }) => {
  return (
    <div className="mt-4 mb-4 flex flex-col gap-2">
      <div>
        <div className="mb-2 block">
          <label htmlFor="firstName">Имя</label>
        </div>
        <TextInput
          id="firstName"
          type="text"
          register={register("firstName", {
            required: "Пожалуйста, укажите Ваше имя",
            pattern: {
              value: /^[\u0400-\u04FF]+$/,
              message: "Пожалуйста, используйте только кириллицу",
            },
          })}
          helperText={
            errors && errors.firstName && <>{errors.firstName.message}</>
          }
        />
      </div>
      <div>
        <div className="mb-2 block">
          <label htmlFor="lastName">Фамилия</label>
        </div>
        <TextInput
          id="lastName"
          type="text"
          register={register("lastName", {
            required: "Пожалуйста, укажите Вашу фамилию",
            pattern: {
              value: /^[\u0400-\u04FF]+$/,
              message: "Пожалуйста, используйте только кириллицу",
            },
          })}
          helperText={
            errors && errors.lastName && <>{errors.lastName.message}</>
          }
        />
      </div>
      <div>
        <div className="mb-2 block">
          <label htmlFor="email">Email</label>
        </div>
        <TextInput
          id="email"
          type="email"
          register={register("email", {
            required: "Пожалуйста, укажите Ваш email",
            pattern: {
              value: /.+@.+\..+/,
              message: "Введите действительный адрес электронной почты",
            },
          })}
          helperText={errors && errors.email && <>{errors.email.message}</>}
        />
      </div>
      <div>
        <div className="mb-2 block">
          <label htmlFor="password">Пароль</label>
        </div>
        <PasswordInput
          id="password"
          register={register("password", {
            required: "Пожалуйста, укажите пароль",
          })}
          color={errors && errors.password ? "failure" : "gray"}
          helperText={
            errors && errors.password && <>{errors.password.message}</>
          }
        />
      </div>
      <div className="my-4">
        <div className="my-4 flex flex-row items-center gap-2">
          <CheckboxInput
            id="termsOfUsage"
            value={watch("termsOfUsage")}
            register={register("termsOfUsage", {
              required:
                "Использование сервиса возможно только после согласия с правилами пользования сервисом",
            })}
          />

          <label htmlFor="termsOfUsage select-none">
            Я соглашаюсь с{" "}
            <a
              className="underline text-secondaryAccent"
              href={"/legal/termsOfUsage"}
              target="_blank"
              rel="noreferrer"
            >
              условиями использования сервиса
            </a>
          </label>
        </div>
        {errors && errors.termsOfUsage && (
          <div className="text-errorRed">
            <span className="text-sm mt-2">{errors.termsOfUsage.message}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationForm;
