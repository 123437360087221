import { apiClient } from "./http";

const create = async (calendlyPayload) => {
  try {
    const response = await apiClient.post(`/api/v1/lead`, calendlyPayload);
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

const getTodoList = async (leadId) => {
  try {
    const response = await apiClient.get(`/api/v1/lead/todo/${leadId}`);
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

const closeTodo = async ({ leadId, todoId }) => {
  try {
    const response = await apiClient.put(
      `/api/v1/lead/todo/${leadId}/${todoId}`
    );
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

const registerUser = async (userData) => {
  console.log({ userData });
  const { leadId } = userData;
  try {
    const response = await apiClient.post(`/api/v1/lead/${leadId}/register`, userData);
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
}

const leadService = {
  getTodoList,
  create,
  closeTodo,
  registerUser,
}

export default leadService;
