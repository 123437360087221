import { apiClient } from "./http";

const get = async (inviteId) => {
  try {
    const response = await apiClient.get(`/api/v1/invite/${inviteId}`);
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

const accept = async ({ inviteId, ...userData}) => {
  try {
    const response = await apiClient.post(`/api/v1/invite/${inviteId}`, userData);
    return response.data;
  } catch (err) {
    console.log(err.response.data);
    throw err;
  }

}

const inviteService = {
  get,
  accept,
}

export default inviteService;