import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { differenceInSeconds } from "date-fns";

import LoadingState from "../components/LoadingState";

import { OnboardingBannerContext } from "../../../contexts/OnboardingBannerContext";

import estimatesService from "../../../../../services/estimates";

const REFETCH_INTERVAL = 3000;

const calculateProgress = (startDate, expectedCompletionDate) => {
  const start = new Date(startDate);
  const end = new Date(expectedCompletionDate);
  const now = new Date();

  const totalDuration = differenceInSeconds(end, start);
  const timeElapsed = differenceInSeconds(now, start);
  const progress = Math.min(
    100,
    Math.max(0, (timeElapsed / totalDuration) * 100)
  );

  return Math.round(progress);
};

const StepLoadingState = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { estimatesId } = useParams();
  const navigate = useNavigate();
  const { hasSeenEstimates } = React.useContext(OnboardingBannerContext);
  const [progress, setProgress] = React.useState(null);
  const [isFinalizing, setIsFinalizing] = React.useState(false);
  const intervalRef = React.useRef(null);
  const finalizingIntervalRef = React.useRef(null);

  const onFinish = React.useCallback(() => {
    navigate(`/onboarding/estimates/${estimatesId}`);
  }, [navigate, estimatesId]);

  const onDataRequired = React.useCallback(
    (nextStep) => {
      navigate(`/onboarding/brief/${estimatesId}/${nextStep}`);
    },
    [navigate, estimatesId]
  );

  const { data, isRefetching, isLoading } = useQuery({
    queryKey: ["estimates", estimatesId],
    queryFn: () => estimatesService.getEstimates(estimatesId),
    refetchOnMount: "always",
    refetchInterval: (queryData) => {
      const { data } = queryData.state;
      const { status } = data || {};
      if (!status || status === "incomplete") return REFETCH_INTERVAL;
      return false;
    },
  });

  React.useEffect(() => {
    if (isRefetching || isLoading) return;
    const { status, nextStep } = data || {};
    if (!status || status === "incomplete") return;
    if (status === "complete") {
      if (hasSeenEstimates) {
        onFinish();
      }
      if (!isFinalizing) {
        setIsFinalizing(true);
      }
      return;
    }
    if (status === "dataRequired") {
      onDataRequired(nextStep);
    }
  }, [
    data,
    isRefetching,
    isLoading,
    hasSeenEstimates,
    isFinalizing,
    onFinish,
    onDataRequired,
  ]);

  React.useEffect(() => {
    if (!data) return;
    const { expectedCompletionDate, startDate } = data || {};
    if (!expectedCompletionDate || !startDate) return;
    intervalRef.current = setInterval(() => {
      setProgress(calculateProgress(startDate, expectedCompletionDate));
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [data]);

  React.useEffect(() => {
    if (!finalizingIntervalRef.current) {
      if (isFinalizing && progress < 100) {
        clearInterval(intervalRef.current);

        const step = Math.round((100 - progress) / 30);

        finalizingIntervalRef.current = setInterval(() => {
          setProgress((prevProgress) => {
            const newProgress = prevProgress + step;
            // TODO: uncomment to see the error
            // console.log({ newProgress });
            if (newProgress >= 100) {
              clearInterval(finalizingIntervalRef.current);
              onFinish();
              return 100;
            }
            return newProgress;
          });
        }, 100);
      }
    }
  }, [isFinalizing, onFinish]);

  return (
    <div className="max-w-sm mx-auto p-4 font-primary">
      <h2 className="text-4xl text-white font-primaryBold mb-4">
        ИИ-калькулятор работает
      </h2>
      {progress && (
        <div className="flex flex-col">
          <LoadingState progress={progress} />
        </div>
      )}
    </div>
  );
};

export default StepLoadingState;
