import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import Infobox from "../../../../../components/Infobox";
import PrimaryButton from "../../../../../components/PrimaryButton";

import useBackendAction from "../../../../../hooks/useBackendAction";

const ContactSection = ({ CONTACTED, profileId }) => {
  const { '*': optionalParams } = useParams();
  const [, todoId] = optionalParams.split("/");
  const navigate = useNavigate();
  const contactedMutation = useBackendAction({
    action: CONTACTED,
    invalidateQueriesKey: ["specialistProfile", profileId],
    onSuccess: () => {
      console.log({ todoId });
      navigate(`/app/todo/${todoId}`);
    },
  });

  return (
    <div className="px-4 mt-10 mb-10 flex flex-col gap-8">
      <Infobox>
        Мы подготовили для вас текст сообщения, которое поможет начать диалог с
        дизайнером -{" "}
        <span className="underline text-brandPurple">
          посмотреть и скопировать
        </span>
      </Infobox>
      <PrimaryButton onClick={() => contactedMutation.mutate()}>
        Связаться
      </PrimaryButton>
    </div>
  );
};


export default ContactSection;