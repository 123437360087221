import React from "react";

const SecondaryButton = ({ className, children, onClick }) => {
  return (
    <button
      className={`${className} rounded-[24px] py-3 px-3 text-white hover:text-brandPurple focus:text-brandSecondaryPurple transition-all`}
      onClick={onClick}
    >
      <span className="text-base font-primarySemiBold underline">
        {children}
      </span>
    </button>
  );
};

export default SecondaryButton;
