import React from "react";
import { Link } from "react-router-dom";
import Drawer from "react-modern-drawer";

import Title from "../assets/images/title.png";
import iconify from "../assets/iconify";

const { Cross, Hamburger, Enter, Exit } = iconify;

const Placeholder = () => <div className="w-6 h-6" />;

const Appbar = ({ className, title, backButton, showDrawer }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  return (
    <div className={`${className} bg-primaryText fill-current stroke-current`}>
      <div className="max-w-sm mx-auto">
        <div className="flex flex-row justify-between items-center p-4">
          {backButton ? backButton : <Placeholder />}
          <div className="text-white text-xl font-primaryBold">{title}</div>
          {showDrawer ? (
            <button
              onClick={toggleDrawer}
              className="w-6 h-6 text-white hover:text-brandPurple focus:text-brandSecondaryPurple"
            >
              <Hamburger />
            </button>
          ) : (
            <Placeholder />
          )}
        </div>
        <Drawer
          open={isDrawerOpen}
          onClose={toggleDrawer}
          direction="right"
          duration={300}
          style={{ width: "100%" }}
        >
          <div className="bg-page w-full h-full flex flex-col">
            <div className="flex flex-row justify-between items-center p-4">
              <Link to="/">
                <img src={Title} alt="title" className="h-[15px]" />
              </Link>
              <button
                onClick={toggleDrawer}
                className="text-white hover:text-brandPurple focus:text-brandSecondaryPurple"
              >
                <Cross />
              </button>
            </div>
            <div className="text-white font-primaryBold text-4xl px-4 mt-10 flex flex-col gap-5">
              <Link
                to="/app"
                onClick={toggleDrawer}
                className="hover:text-brandPurple focus:text-brandSecondaryPurple"
              >
                <h2 className="">Следующие шаги</h2>
              </Link>
              <Link
                to="/app/project"
                onClick={toggleDrawer}
                className="hover:text-brandPurple focus:text-brandSecondaryPurple"
              >
                <h2 className="">Этапы проекта</h2>
              </Link>
              <Link
                to="/app/project/sprint/1"
                onClick={toggleDrawer}
                className="hover:text-brandPurple focus:text-brandSecondaryPurple"
              >
                <h2 className="">Текущий этап</h2>
              </Link>
            </div>
            <div className="flex-grow flex flex-col justify-end px-4 pb-16">
              <Link
                to="/app/logout"
                className="flex flex-row gap-2 items-center text-white font-primaryBold hover:text-brandPurple focus:text-brandSecondaryPurple"
              >
                <Exit />
                <h2 className="text-xl">Выйти из приложения</h2>
              </Link>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default Appbar;
