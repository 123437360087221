import { useMutation, useQueryClient } from "@tanstack/react-query";

import backendActionService from "../services/backendAction";

const useBackendAction = ({ action, invalidateQueriesKey, onSuccess, fileUpload }) => {
  const queryClient = useQueryClient();
  const { url, body, method } = action || {};
  const mutation = useMutation({
    mutationFn: backendActionService.executePost(url, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: invalidateQueriesKey });
      if (onSuccess) {
        onSuccess();
      }
    }
  });

  const uploadMutation = useMutation({
    mutationFn: backendActionService.upload(url, method),
  });

  return fileUpload ? uploadMutation : mutation;
};

export default useBackendAction;
