import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import TodoList from "./components/TodoList";
import SuccessToast from "./components/SuccessToast";

import HintComponent from "./components/HintComponent";

import Appbar from "../../../../components/Appbar";

import leadService from "../../../../services/lead";

import "./checkboxAnimation.css";

const LeadTodoList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { leadId } = useParams();

  const {
    data,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["lead", leadId],
    queryFn: () => leadService.getTodoList(leadId),
    initialData: [],
  });

  const closeTodoMutation = useMutation({
    mutationFn: ({ leadId, todoId }) =>
      leadService.closeTodo({ leadId, todoId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["lead", leadId] });
      setMessage("Задача выполнена!");
      setTimeout(() => setMessage(undefined), 3000);
    },
    onError: () => {}
  });

  const [message, setMessage] = React.useState(undefined);

  const onTodoClosed = ({ leadId, todoId }) => {
    closeTodoMutation.mutate({ leadId, todoId });
  };

  const onGoToTodo = ({ todoId }) => {
    navigate(`/app/todo/lead/${leadId}/${todoId}`);
  }

  const { title: listTitle } = data || {};
  const todoList = (data && data.todoList) || [];

  const shouldShowHint = todoList && todoList.length === 1 && todoList[0].states.state === "READY_TO_CLOSE";
  return (
    <>
      <div className="bg-primaryText w-full mb-2">
        <Appbar title={listTitle} />
      </div>
      <div className="text-white max-w-sm mx-auto px-4 pb-4 fill-current stroke-current">

        {shouldShowHint && (
          <HintComponent>Отмечайте выполненные задачи галочками</HintComponent>
        )}

        <TodoList
          upcomingList={[]}
          todoList={todoList}
          onTodoClosed={onTodoClosed}
          onGoToTodo={onGoToTodo}
          message={message}
        />
      </div>
      <SuccessToast
        message={message}
        unsetMessage={() => setMessage(undefined)}
      />
    </>
  );
};

export default LeadTodoList;
