import React, {useRef, useState} from "react";

import Screen1 from "../../../assets/v2/images/scroll_1.png";
import Screen2 from "../../../assets/v2/images/scroll_2.png";
import Screen3 from "../../../assets/v2/images/scroll_3.png";
import Screen4 from "../../../assets/v2/images/scroll_4.png";
import Screen5 from "../../../assets/v2/images/scroll_release.png";

const screenshots = [Screen1, Screen2, Screen3, Screen4, Screen5]

const Screenshots = ({className}) => {

    return (
        <div className={className}>
            <h3
                className="text-2xl lg:text-54 lg:leading-56 text-mintTulip font-primaryBold"
                style={{
                    background:
                        "radial-gradient(379.94% 192.24% at 98.12% 117.71%, #B5D4E5 41.21%, rgba(187, 217, 234, 0.00) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                }}
            >
                Приложение в режиме онлайн покажет каждый этап разработки
            </h3>

            <div className="mt-8 flex flex-row gap-8 no-scrollbar" style={{
                minWidth: '100%',
                overflowX: "auto",
                WebkitOverflowScrolling: "touch",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "scrollbar-color": "transparent",
            }}>
                {screenshots.map((item, index) => (
                    <img key={index}
                         src={item}
                         alt={`screen${index + 1}`}
                         className="h-[600px] flex-none"
                    />
                ))}
            </div>
        </div>
    );
};

export default Screenshots;
