import React from "react";

import PlanTimeline from "./PlanTimeline";
import PlanTimelineDesktop from "./PlanTimelineDesktop";

const Plan = ({ className, plan, budget, deadline }) => {
  const budgetBody = budget.slice(0, -2);
  const budgetTotal = budget.slice(-2);
  const planWithCosts = plan.map((item, idx) => {
    const cost = idx < budgetBody.length ? budgetBody[idx].cost : null;
    return { ...item, cost };
  });
  return (
    <div>
      <div className={`${className} font-primary text-sm lg:hidden`}>
        <div className="text-base font-primaryLight text-shipCove my-2">
          Сроки работ - {deadline}
        </div>
        <PlanTimeline timeline={planWithCosts} />
        <div className="text-sinbad text-12 font-primaryBold mt-4 flex flex-col gap-2 pr-4">
          {budgetTotal.map((item, idx) => (
            <div className="flex flex-row justify-end" key={item.id}>
              <div className="flex-grow pl-9 pr-4 text-right">
                {item.title}
                {idx === 1 && <span>&nbsp;&nbsp;</span>}
              </div>
              <div>{item.cost}</div>
            </div>
          ))}
        </div>
      </div>

      {/* for desktop */}
      <div className={`${className} font-primary hidden lg:block`}>
        <div className="mt-1 text-center text-middleBlue text-shipCove my-2 mb-7">
        <span className="text-2xl">План</span> <span className="font-primaryExtraLight text-lg">(cроки работ - {deadline})</span>
        </div>
        <PlanTimelineDesktop timeline={planWithCosts} />
        <div className="text-sinbad font-primaryBold mt-4 flex flex-col gap-2 pr-1">
          {budgetTotal.map((item, idx) => (
            <div className="flex flex-row justify-end items-center" key={item.id}>
              <div className="text-2xl flex-grow pl-9 pr-10 text-right">
                {item.title}{item.title === "Итого" ? ":" : ""}
                {idx === 1 && <span>&nbsp;&nbsp;</span>}
              </div>
              <div className="text-xl flex items-center">{item.cost}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Plan;
