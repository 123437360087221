import React from "react";
import TextInput from "../../../../../components/TextInput";
import PasswordInput from "../../../../../components/PasswordInput";

function SigninForm({ register, errors, watch, setValue, className }) {
  return (
    <div className={`${className} flex flex-col gap-4`}>
      <div className="text-white">
        <TextInput
          placeholder="E-mail"
          type="email"
          register={{...register("email", {
            required: "Пожалуйста, введите ваш email",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Неверный формат email",
            },
          })}}
          helperText={errors && errors.email && errors.email.message}
          autoComplete="off"
          autoCorrect="off"
        />
      </div>

      <div className="">
        <PasswordInput
          placeholder="Пароль"
          register={{...register("password", {
            required: "Пожалуйста, введите ваш пароль",
          })}}
          helperText={errors && errors.password && errors.password.message}
        />
      </div>
    </div>
  );
}

export default SigninForm;
