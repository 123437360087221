import React from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import estimatesService from "../../services/estimates";

import useScrollToAnchor from "../../hooks/useScrollToAnchor";

import ModalCancelCalculator from "./components/ModalCancelCalculator";
import PrimaryButton from "./components/PrimaryButton";
import CheckboxInput from "./components/Checkbox";
import RadioInput from "./components/Radio";

import "./components/spinner.css";

const TextInput = ({
  id,
  type,
  register,
  color,
  helperText,
  placeholder,
  disabled,
  defaultValue,
  className,
}) => {
  return (
    <div className="mt-4 relative text-brightLightBlue">
      <input
        id={id}
        type={type}
        className={`${className} w-full px-4 py-[10px] border rounded-2xl bg-primaryDarkBlue focus:outline-none focus:ring-2 focus:ring-middleBlue placeholder-style`}
        placeholder={placeholder}
        {...register}
        disabled={disabled}
        defaultValue={defaultValue}
      />
    </div>
  );
};

const DetailsMobile = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contentConfig = {
    text: "ИИ-калькулятор начал работу. Вы хотите прервать процесс, тогда вам потребуется ввести данные заново?",
    nextBtnText: "Продолжить",
    secondaryBtnText: "Ввести данные заново",
  };

  useScrollToAnchor();
  const navigate = useNavigate();
  const { estimatesId } = useParams();
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { setOnBack } = useOutletContext();

  React.useEffect(() => {
    setOnBack(() => () => {
      console.log("showing modal");
      setIsModalOpen(true);
    });
  }, [setOnBack]);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      projectName: null,
      projectType: null,
      platformWeb: false,
      platformIos: false,
      platformAndroid: false,
      countryOfResidence: null,
    },
  });

  const platformIsChecked =
    watch("platformWeb") || watch("platformIos") || watch("platformAndroid");

  const progress =
    75 +
    (!platformIsChecked ? 0 : 1) * 5 +
    (!watch("projectName") ? 0 : 1) * 5 +
    (!watch("projectType") ? 0 : 1) * 5 +
    (!watch("countryOfResidence") ? 0 : 1) * 5 +
    (!watch("hasUiUxDesigner") ? 0 : 1) * 5;

  const isContinueButtonActive =
    watch("projectName") &&
    watch("projectType") &&
    watch("countryOfResidence") &&
    platformIsChecked &&
    watch("hasUiUxDesigner");

  const mutation = useMutation({
    mutationFn: estimatesService.provideData,
    onSuccess: (estimatesId) => {
      queryClient.invalidateQueries({ queryKey: ["estimates", estimatesId] });
      navigate(`/calculator/${estimatesId}/await`);
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const onGetEstimates = async (briefObject) => {
    const {
      projectName,
      projectType,
      platformWeb,
      platformIos,
      platformAndroid,
      hasUiUxDesigner,
      countryOfResidence,
    } = briefObject;

    mutation.mutate({
      estimatesId,
      briefObject: {
        name: projectName,
        projectType,
        platform: {
          web: platformWeb,
          ios: platformIos,
          android: platformAndroid,
        },
        hasUiUxDesigner,
        countryOfResidence,
      },
    });
  };

  return (
    <div className="flex-1 w-full h-full">
      <div className="bg-primaryDarkBlue lg:hidden">
        <div className="bg-bigStone font-primary pb-8 rounded-b-[40px]">
          <div className="max-w-sm mx-auto px-4">
            <div className="w-full py-3 px-4">
              <div className="h-1 rounded-2xl border border-middleBlue">
                <div
                  className="h-full bg-brightLightBlue rounded-l-2xl"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>

            <div className="mt-3">
              <h2 className="text-base text-sinbad font-primaryBold">
                Как называется ваш проект?
              </h2>
            </div>
            <div className="relative">
              <TextInput
                id="projectName"
                type="text"
                placeholder={"Название проекта"}
                register={register("projectName", {
                  required: "Пожалуйста, укажите название проекта",
                  minLength: {
                    value: 3,
                    message: "Название, не менее 3 символов",
                  },
                })}
                className={"border-middleBlue"}
              />
              {errors && errors.projectName && (
                <div className="absolute px-1 text-errorRed">
                  <span className="text-xs">{errors.projectName.message}</span>
                </div>
              )}
            </div>
            <div className="mt-6 mb-2">
              <h2
                className={`${
                  watch("projectName") ? "text-sinbad" : "text-middleBlue"
                } font-primaryBold`}
              >
                Что вы хотите создать?
              </h2>
            </div>

            <select
              {...register("projectType", {
                required: "Пожалуйста, укажите тип проекта",
              })}
              className="border rounded-2xl w-full px-4 py-[10px] bg-primaryDarkBlue border-middleBlue text-brightLightBlue"
            >
              <option value="nft">NFT коллекцию</option>
              <option value="mobileApp">Мобильное приложение</option>
              <option value="web3">web3 приложение (смарт-контракт)</option>
              <option value="webApp">Веб приложение</option>
              <option value="telegramBot">Telegram бота</option>
            </select>

            <div className="mt-6 mb-2">
              <h2
                className={`${
                  watch("projectType") ? "text-sinbad" : "text-middleBlue"
                } font-primaryBold`}
              >
                На каких платформах вы хотите выпустить проект?
              </h2>
            </div>

            <div className="flex flex-row justify-between mx-4 mt-2">
              <div className="flex flex-row gap-2">
                <CheckboxInput
                  id="platformWeb"
                  value={watch("platformWeb")}
                  register={{
                    ...register("platformWeb"),
                  }}
                />
                <label
                  htmlFor="platformWeb"
                  className={`${
                    watch("platformWeb") ? "text-brightBlue" : "text-middleBlue"
                  } ${
                    watch("projectType") ? "text-sinbad" : "text-middleBlue"
                  }`}
                >
                  Веб
                </label>
              </div>
              <div className="flex flex-row gap-2">
                <CheckboxInput
                  id="platformIos"
                  value={watch("platformIos")}
                  register={{
                    ...register("platformIos"),
                  }}
                />
                <label
                  htmlFor="platformIos"
                  className={`${
                    watch("platformIos") ? "text-brightBlue" : "text-middleBlue"
                  } ${
                    watch("projectType") ? "text-sinbad" : "text-middleBlue"
                  }`}
                >
                  iOS
                </label>
              </div>
              <div className="flex flex-row gap-2">
                <CheckboxInput
                  id="platformAndroid"
                  value={watch("platformAndroid")}
                  register={{
                    ...register("platformAndroid"),
                  }}
                />
                <label
                  htmlFor="platformAndroid"
                  className={`${
                    watch("platformAndroid")
                      ? "text-brightBlue"
                      : "text-middleBlue"
                  } ${
                    watch("projectType") ? "text-sinbad" : "text-middleBlue"
                  }`}
                >
                  Android
                </label>
              </div>
            </div>

            <div className="mt-6 mb-2">
              <h2
                className={`${
                  platformIsChecked ? "text-sinbad" : "text-middleBlue"
                } font-primaryBold`}
              >
                Готов ли у вас дизайн в Figma?
              </h2>
            </div>

            <div className="flex flex-row gap-14 mx-4 mt-2">
              <div className="flex flex-row gap-2">
                <RadioInput
                  id="hasUiUxDesigner-yes"
                  value={"true"}
                  register={{ ...register("hasUiUxDesigner") }}
                  currentValue={watch("hasUiUxDesigner")}
                />
                <label
                  htmlFor="hasUiUxDesigner-yes"
                  className={`${
                    watch("hasUiUxDesigner") === "true"
                      ? "text-brightBlue"
                      : "text-middleBlue"
                  } ${platformIsChecked ? "text-sinbad" : "text-middleBlue"}`}
                >
                  Да
                </label>
              </div>
              <div className="ml-1 flex flex-row gap-2">
                <RadioInput
                  id="hasUiUxDesigner-no"
                  value={"false"}
                  register={{ ...register("hasUiUxDesigner") }}
                  currentValue={watch("hasUiUxDesigner")}
                />
                <label
                  htmlFor="hasUiUxDesigner-no"
                  className={`${
                    watch("hasUiUxDesigner") === "false"
                      ? "text-brightBlue"
                      : "text-middleBlue"
                  } ${platformIsChecked ? "text-sinbad" : "text-middleBlue"}`}
                >
                  Нет
                </label>
              </div>
            </div>

            <div className="mt-6 mb-2">
              <h2
                className={`${
                  watch("hasUiUxDesigner") ? "text-sinbad" : "text-middleBlue"
                } font-primaryBold`}
              >
                В какой стране вы находитесь?
              </h2>
              <div className="text-12 text-middleBlue">
                Это важно для организации расчетов с исполнителями
              </div>
            </div>

            <select
              {...register("countryOfResidence", {
                required: "Пожалуйста, укажите страну",
              })}
              className="border rounded-2xl w-full px-4 py-[10px] bg-primaryDarkBlue border-middleBlue text-brightLightBlue"
            >
              <option value="russia">Россия</option>
              <option value="other">Другая страна</option>
            </select>
          </div>
        </div>
        <div className="max-w-sm mx-auto px-4 flex flex-row justify-around">
          <PrimaryButton
            className={"w-[216px] my-5"}
            onClick={handleSubmit(onGetEstimates)}
            disabled={!isContinueButtonActive}
          >
            {mutation.isPending ? (
              <div className="flex justify-center items-center">
                <div className="spinner"></div>
              </div>
            ) : (
              <span
                className={`font-primaryBold text-lg ${
                  !isContinueButtonActive ? "text-middleBlue" : "text-darkBlue"
                }`}
              >
                Далее
              </span>
            )}
          </PrimaryButton>
        </div>
        <ModalCancelCalculator
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          content={contentConfig}
        />
      </div>
    </div>
  );
};

export default DetailsMobile;
