import React from "react";

import PrimaryButton from "../../../../../../../components/PrimaryButton";

const SpecialistCard = ({ specialist, onContact }) => {
  const { name, title, telegram } = specialist;

  const openTelegram = () => {
    window.open(`https://t.me/${telegram}`, "_blank");
  };
  return (
    <div className="flex flex-row items-center gap-2 rounded-xl border border-primaryAccent p-2">
      <div>
        <div className="w-12 h-12 rounded-full bg-primaryAccent" />
      </div>
      <div className="flex flex-col">
        <div>{name}</div>
        <div className="text-sm text-secondaryText">
          {title}
        </div>
        <PrimaryButton onClick={() => onContact(openTelegram)}>Связаться</PrimaryButton>
      </div>
    </div>
  );
};

export default SpecialistCard;
