import React from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";

import roleIcons from "../../../../assets/roleIcons";
import RegistrationForm from "./components/RegistrationForm";
import Topbar from "../../../../components/Topbar";

import useAuth from "../../../../modules/auth/useAuth";
import inviteService from "../../../../services/invite";
import PrimaryButton from "../../../../components/PrimaryButton";

import backendActionService from "../../../../services/backendAction";

const Invite = () => {
  const navigate = useNavigate();
  const { inviteId } = useParams();
  const { manualLogin, isAuthenticated } = useAuth();
  console.log({ isAuthenticated });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      termsOfUsage: false,
    },
  });

  const { data, isLoading } = useQuery({
    queryFn: () => inviteService.get(inviteId),
    queryKey: ["invite", inviteId],
  });

  const { actions } = data || {};
  const { registerAndJoin, join } = actions || {};
  const { url: registerAndJoinUrl } = registerAndJoin || {};
  const { url: joinUrl } = join || {};

  console.log({ joinUrl, registerAndJoinUrl, actions, registerAndJoin, join })

  const acceptMutation = useMutation({
    mutationFn: backendActionService.executePost(registerAndJoinUrl),
    onSuccess: (data) => {
      const { accessToken, refreshToken } = data;
      manualLogin({ accessToken, refreshToken });
      navigate("/app");
    },
  });

  const joinMutation = useMutation({
    mutationFn: backendActionService.executePost(joinUrl),
    onSuccess: (data) => {
      const { projectId } = data;
      navigate(`/app/project/${projectId}`);
    },
  });

  const onRegister = (data) => {
    console.log({ ...data, inviteId });
    acceptMutation.mutate(data);
  };

  const onJoin = () => {
    joinMutation.mutate();
  };

  React.useEffect(() => {
    console.log({ data });
  }, [data]);

  const { role, inviteText, jobTitle } = data || {};

  return (
    <div className="fill-current stroke-current w-full">
      <Topbar />
      <div className="max-w-sm mx-auto text-white font-primary">
        <div className="px-4">
          <h1 className="text-2xl font-bold mb-2 mt-4">Приглашение в проект</h1>
          <p>
            Спринт АП организует взаимодействие заказчика и команды фрилансеров для реализации IT-проектов.
          </p>
          <p className="mt-4">{inviteText}</p>
          <p className="mt-4">
            Обращаем Ваше внимание, что вы получите доступ к конфиденциальной
            информации. Принимая приглашение, вы обязуетесь не разглашать
            информацию о проекте.
          </p>
          {isAuthenticated || isLoading ? null : (
            <div>
              <div className="mt-4 flex flex-row items-center gap-2 text-white">
                {roleIcons[role]}
                <p>{jobTitle}</p>
              </div>

              <RegistrationForm
                errors={errors}
                register={register}
                watch={watch}
              />

              <PrimaryButton
                onClick={handleSubmit(onRegister)}
                className="w-full"
              >
                Зарегистрироваться
              </PrimaryButton>
            </div>
          )}
          {isAuthenticated && !isLoading && (
            <div>
              <PrimaryButton onClick={onJoin} className="w-full mt-10">
                Присоединиться как наблюдатель
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invite;
