import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const API_HOST = process.env.REACT_APP_SERVER_URL;

export const useBackendQueryWithoutAuth = ({ queryKey, url, retry }) => {
  const { data, error, isLoading } = useQuery({
    queryKey,
    retry: retry || 3,
    queryFn: async () => {
      const response = await axios.get(`${API_HOST}${url}`);

      return response.data;
    },
  });

  return { data, error, isLoading };
}