import React from "react";
import TextareaAutosize from "react-textarea-autosize";

const Description = ({ className, asset }) => {
  const { content } = asset || {};
  const { ideaDescription, uspDescription } = content || {};

  return (
    <div className={className}>
      <div className="mb-4 text-white">
        <div className="font-primary">Минимальная версия идеи</div>
      </div>

      <div className="mt-4 text-white">
        <TextareaAutosize
          className="resize-none w-full p-2 border border-primaryAccent rounded-md bg-primaryAccent focus:border-none focus:outline-none focus:ring-2 focus:ring-brandPurple"
          defaultValue={ideaDescription}
          disabled
        />
      </div>

      <div className="mt-4 mb-4 text-white">
        <div className="font-primary">Уникальное торговое предложение</div>
      </div>

      <div className="mt-4 text-white">
        <TextareaAutosize
          className="resize-none w-full p-2 border border-primaryAccent rounded-md bg-primaryAccent focus:border-none focus:outline-none focus:ring-2 focus:ring-brandPurple"
          placeholder="Какую задачу клиента решает ваше приложение? Какие 2-3 функции очень полезны пользователю? В чем новизна вашего проекта?"
          defaultValue={uspDescription}
          disabled
        />
      </div>
    </div>
  );
};

export default Description;
