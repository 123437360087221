import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import backendActionService from "../../../../../../../services/backendAction";

const SecondaryButton = ({ className, payload, leadId }) => {
  const { actionPayLater } = payload;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: backendActionService.mutation(actionPayLater),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: leadId ? ["lead", leadId] : ["todoList"],
      });
    },
  });

  const onClick = () => {
    mutation.mutate();
    navigate("/app");
  };
  return (
    <button
      className={`${className} rounded-[24px] py-3 px-3 text-white hover:text-brandPurple focus:text-brandSecondaryPurple transition-all`}
      onClick={onClick}
    >
      <span className="text-base font-primarySemiBold underline">
        Оплатить счет позднее
      </span>
    </button>
  );
};

export default SecondaryButton;
