import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import estimatesService from "../../../../../services/estimates";
import PrimaryButton from "../../../../../components/PrimaryButton";

const StepProjectQuestions = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const { estimatesId } = useParams();
  const queryClient = useQueryClient();

  const { register, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      projectType: "webApp",
      projectState: "new",
      platform: {
        web: true,
        ios: false,
        android: false,
      },
      hasUiUxDesigner: false,
    },
  });
  const platform = watch("platform");
  const hasUiUxDesigner = watch("hasUiUxDesigner");
  const togglePlatform = (name) => {
    setValue("platform", {
      ...platform,
      [name]: !platform[name],
    });
  };

  const radioButtonSelectedClass = "bg-brandPurple text-white";
  const radioButtonDefaultClass = "text-brandPurple ";

  const mutation = useMutation({
    mutationFn: estimatesService.provideData,
    onSuccess: (estimatesId) => {
      queryClient.invalidateQueries({ queryKey: ["estimates", estimatesId] });
      navigate(`/onboarding/brief/${estimatesId}/legal`);
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const onGetEstimates = async (briefObject) => {
    mutation.mutate({ estimatesId, briefObject });
  };

  return (
    <div className="max-w-sm mx-auto p-4 font-primary">
      <h2
        className="text-4xl text-white font-primaryBold mb-4"
        id="brief"
      >
        <div>Бриф</div>
        <div className="text-2xl text-secondaryText mt-1">Технические вопросы</div>
      </h2>

      <div className="mb-4 text-white ">
        <span className="font-primary">Что вы хотите выпустить?</span>
        <div className="flex space-x-2 mt-2">
          <select
            {...register("projectType")}
            className="border rounded w-full p-2 bg-primaryAccent border-primaryAccent"
          >
            <option value="webApp">Веб приложение</option>
            <option value="mobileApp">Мобильное приложение</option>
            <option value="telegramBot">Telegram бота</option>
            <option value="nft">NFT коллекцию</option>
            <option value="web3">web3 приложение (смарт-контракт)</option>
          </select>
        </div>
      </div>

      <div className="mb-4 text-white ">
        <span className="font-primary">Состояние проекта</span>
        <div className="flex space-x-2 mt-2">
          <select
            {...register("projectState")}
            className="border rounded w-full p-2 bg-primaryAccent border-primaryAccent"
          >
            <option value="new">Новый проект</option>
            <option value="unpublished">
              Доработка неопубликованного проекта
            </option>
            <option value="published">Доработка опубликованного проекта</option>
          </select>
        </div>
      </div>

      <div className="mb-4 text-white ">
        <span className="font-primary">
          На какой платформе вы хотите выпустить приложение?
        </span>
        <div className="flex space-x-2 mt-2">
          <button
            onClick={() => togglePlatform("web")}
            className={`flex-1 px-4 py-2 rounded-md border border-brandPurple ${
              platform.web ? radioButtonSelectedClass : radioButtonDefaultClass
            }`}
          >
            Веб
          </button>
          <button
            onClick={() => togglePlatform("ios")}
            className={`flex-1 px-4 py-2 rounded-md border border-brandPurple ${
              platform.ios ? radioButtonSelectedClass : radioButtonDefaultClass
            }`}
          >
            iOS
          </button>
          <button
            onClick={() => togglePlatform("android")}
            className={`flex-1 px-4 py-2 rounded-md border border-brandPurple ${
              platform.android
                ? radioButtonSelectedClass
                : radioButtonDefaultClass
            }`}
          >
            Android
          </button>
        </div>
      </div>

      <div className="mb-4 text-white">
        <span className="font-primary">Готов ли у вас дизайн проекта?</span>
        <div className="flex space-x-2 mt-2">
          <button
            onClick={() => setValue("hasUiUxDesigner", false)}
            className={`flex-1 px-4 py-2 rounded-md border border-brandPurple ${
              !hasUiUxDesigner
                ? radioButtonSelectedClass
                : radioButtonDefaultClass
            }`}
          >
            Нет
          </button>
          <button
            onClick={() => setValue("hasUiUxDesigner", true)}
            className={`flex-1 px-4 py-2 rounded-md border border-brandPurple ${
              hasUiUxDesigner
                ? radioButtonSelectedClass
                : radioButtonDefaultClass
            }`}
          >
            Да
          </button>
        </div>
      </div>

      <PrimaryButton
        onClick={handleSubmit(onGetEstimates)}
        className={"w-full mt-20"}
      >
        Далее
      </PrimaryButton>
    </div>
  );
};

export default StepProjectQuestions;
