import React from "react";

import useBackendAction from "../../../../../../../hooks/useBackendAction";
import PrimaryButton from "../../../../../../../components/PrimaryButton";
import SecondaryButton from "../../../../../../../components/SecondaryButton";

const Open = ({ payload }) => {
  const { actions } = payload;
  const { accept, reject } = actions;

  const acceptMutation = useBackendAction({
    action: accept,
    onSuccess: () => {
      console.log("Accepted");
    },
    invalidateQueriesKey: ["todoList"],
  });

  const rejectMutation = useBackendAction({
    action: reject,
    onSuccess: () => {
      console.log("Rejected");
    },
    invalidateQueriesKey: ["todoList"],
  });

  console.log({ actions });
  return (
    <div className="flex flex-col gap-4">
      <p className="font-primary">
        Ждем вашего отклика на проект
      </p>

      <PrimaryButton onClick={() => acceptMutation.mutate()}>
        Готов взяться за проект
      </PrimaryButton>

      <SecondaryButton onClick={() => rejectMutation.mutate()}>
        Отказаться от проекта
      </SecondaryButton>
    </div>
  );
};

export default Open;
