import { apiClient } from "./http";

const executePost = (url, initialData) => async (data) => {
  try {
    const response = await apiClient.post(url, { ...initialData, ...data });
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

const executeGet = (url) => async () => {
  try {
    const response = await apiClient.get(url);
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

const upload = (url, method) => async (formData) => {
  const fn = method === "POST" ? apiClient.post : apiClient.put;
  try {
    console.log(url);
    const response = await fn(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

const mutation =
  ({ url, method, body }) =>
  async (data) => {
    const fn = method === "POST" ? apiClient.post : apiClient.put;
    try {
      console.log({
        data,
        body,
        actual: {
          ...data,
          ...body,
        },
      });
      const response = await fn(url, {
        ...data,
        ...body,
      });
      return response.data;
    } catch (err) {
      console.log(JSON.stringify(err));
      throw err;
    }
  };

const backendActionService = {
  executePost,
  executeGet,
  upload,
  mutation,
};

export default backendActionService;
