import React from "react";
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import estimatesService from "../../../services/estimates";

import PrimaryButton from "../components/PrimaryButton";
import ModalCancelCalculator from "../components/ModalCancelCalculator";

import Plan from "./components/Plan";
import Specs from "./components/Specs";

const PreliminarySpecs = () => {
  const navigate = useNavigate();
  const { estimatesId } = useParams();

  const onScheduleMeeting = () => {
    navigate(`/calculator/${estimatesId}/schedule`);
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setPageTitle, setOnBack } = useOutletContext();

  React.useEffect(() => {
    setPageTitle("Техническое задание");
    setOnBack(() => null);
  }, [setPageTitle, setOnBack]);

  const { data, isLoading } = useQuery({
    queryKey: ["estimates", estimatesId],
    queryFn: () => estimatesService.getEstimates(estimatesId),
  });

  React.useEffect(() => {
    if (data && data.status) {
      if (data.status === "dataRequired") {
        if (data.nextStep === "project") {
          navigate(`/calculator/${estimatesId}/details`);
        }
      }
    }
  }, [data, estimatesId, navigate]);

  const { meeting } = data || {};
  const {
    preliminaryPlan,
    preliminaryDeadline,
    preliminaryBudget,
    name,
    techSpecs,
    userInput,
  } = (data && data.estimates) || {};

  if (isLoading) {
    return <div className="flex-1 w-full h-full bg-primaryDarkBlue" />;
  }

  return (
    <div>
      <div className="flex-1 w-full h-full bg-downriver lg:hidden">
        <div className="bg-darkBlue font-primary pb-20 rounded-b-[40px]">
          <div className="max-w-sm mx-auto px-4">
            <div className="pt-3">
              <h2 className="text-xl text-brightLightBlue font-primaryBold">
                {name}
              </h2>
              <div className="mt-2 text-12 text-sinbad">
                Предварительная версия ТЗ от ИИ&#8209;калькулятора
              </div>
              {techSpecs && <Specs techSpecs={techSpecs} userInput={userInput} />}
            </div>
            {preliminaryPlan && preliminaryBudget && preliminaryDeadline && (
              <Plan
                className="mt-10"
                plan={preliminaryPlan}
                budget={preliminaryBudget}
                deadline={preliminaryDeadline}
              />
            )}
          </div>
        </div>
        {!meeting && <>
          <div className="max-w-sm mx-auto px-4">
            <h3
              className="mt-8 text-xl text-mintTulip font-primaryBold"
              style={{
                background:
                  "radial-gradient(155.65% 168.19% at 30.42% 50%, #ADCBEA 0%, rgba(62, 103, 146, 0.00) 100%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Следующие шаги
            </h3>

            <div className="text-sinbad text-12 mt-7 text-center">
              Внести изменения в техническое задание и пользовательские истории
              можно во время консультации с нашим специалистом
            </div>
          </div>
          <div className="max-w-sm mx-auto px-4 mt-10 flex flex-row justify-around">
            <PrimaryButton
              className={"w-[216px] mt-5 mb-8"}
              onClick={onScheduleMeeting}
            >
              Выбрать время консультации
            </PrimaryButton>
          </div>
        </>}
        <ModalCancelCalculator
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>

      {/* desktop */}
      <div className="flex-1 w-full h-full bg-downriver hidden lg:block bg-darkBlue">
        <div className="bg-darkBlue font-primary pb-20">
          <div className="mx-auto px-4" style={{ width: "42rem" }}>
            <div className="pt-3">
              <h2 className="text-28 text-brightLightBlue text-center font-primaryBold">
                {name}
              </h2>
              <div className="mt-1 text-sinbad text-md text-center">
                Предварительная версия ТЗ от ИИ&#8209;калькулятора
              </div>
              {techSpecs && <Specs techSpecs={techSpecs} userInput={userInput} />}
            </div>
            {preliminaryPlan && preliminaryBudget && preliminaryDeadline && (
              <Plan
                className="mt-10"
                plan={preliminaryPlan}
                budget={preliminaryBudget}
                deadline={preliminaryDeadline}
              />
            )}
            {!meeting && <div className="max-w-sm mx-auto px-4 mt-10 flex flex-row justify-around">
              <button
                onClick={onScheduleMeeting}
                className={`rounded-[40px] h-[50px] bg-mintTulip hover:bg-brightLightBlue focus:bg-brightLightBlue transition-all w-[300px] mt-5 mb-8 text-24 font-primaryExtraLight`}
              >
                <span className={`text-24 text-darkBlue'`}>
                  Выбрать время консультации
                </span>
              </button>
            </div>}
          </div>
        </div>
        <ModalCancelCalculator
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default PreliminarySpecs;
