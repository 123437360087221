import React, { createContext, useState } from "react";

import estimatesRepository from "../../../repository/estimates";

const persist = ({ estimatesId, hasSeenEstimates }) => {
  estimatesRepository.save({
    estimatesId,
    hasSeenEstimates,
  });
}

export const OnboardingBannerContext = createContext();

export const OnboardingBannerProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [estimatesId, setEstimatesId] = useState(null);
  const [hasSeenEstimates, setHasSeenEstimates] = useState(false);

  React.useEffect(() => {
    const existingEstimates = estimatesRepository.load();
    if (existingEstimates && existingEstimates.estimatesId) {
      setEstimatesId(existingEstimates.estimatesId);
      setHasSeenEstimates(existingEstimates.hasSeenEstimates);
      setIsVisible(true);
    }
  }, []);

  React.useEffect(() => {
    return () => {
      persist({ estimatesId, hasSeenEstimates });
    };
  }, [estimatesId, hasSeenEstimates]);

  const onClose = () => {
    setIsVisible(false);
  };

  const onNewEstimate = ({ estimatesId }) => {
    setIsVisible(true);
    setHasSeenEstimates(false);
    setEstimatesId(estimatesId);
    persist({ estimatesId, hasSeenEstimates });
  }

  const onSeen = () => {
    setHasSeenEstimates(true);
    persist({ estimatesId, hasSeenEstimates });
  }

  return (
    <OnboardingBannerContext.Provider
      value={{
        isVisible,
        estimatesId,
        hasSeenEstimates,
        onClose,
        onNewEstimate,
        onSeen,
      }}
    >
      {children}
    </OnboardingBannerContext.Provider>
  );
};
