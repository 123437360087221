import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import useAuth from "../../../../../../modules/auth/useAuth";
import leadService from "../../../../../../services/lead";

import PrimaryButton from "../../../../../../components/PrimaryButton";
import PasswordInput from "../../../../../../components/PasswordInput";
import CheckboxInput from "../../../../../../components/CheckboxInput";
import TextInput from "../../../../../../components/TextInput";

const Open = ({ className, payload, todoId }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { manualLogin } = useAuth();
  const { firstName, lastName, email, leadId } = payload;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName,
      lastName,
      email,
      password: "",
      termsAndConditionsAccepted: false,
    },
  });

  const registerMutation = useMutation({
    mutationFn: (data) => leadService.registerUser(data),
    onSuccess: (data) => {
      const { accessToken, refreshToken } = data;
      manualLogin({ accessToken, refreshToken });
      queryClient.invalidateQueries({ queryKey: ["lead", leadId] });
      navigate("/app");
    },
  });

  const onRegister = (data) => {
    registerMutation.mutate({
      ...data,
      leadId,
      todoId,
    });
  };

  return (
    <form className={`space-y-6 ${className}`} action="#">
      <div>
        <div className="mb-2 block">
          <label htmlFor="firstName">Имя</label>
        </div>
        <TextInput
          id="firstName"
          type="text"
          register={{
            ...register("firstName", {
              required: "Пожалуйста, укажите Ваше имя",
              pattern: {
                value: /^[\u0400-\u04FF]+$/,
                message: "Пожалуйста, используйте только кириллицу",
              },
            }),
          }}
          color={errors && errors.firstName ? "failure" : "gray"}
          helperText={
            errors && errors.firstName && <>{errors.firstName.message}</>
          }
        />
      </div>
      <div>
        <div className="mb-2 block">
          <label htmlFor="lastName">Фамилия</label>
        </div>
        <TextInput
          id="lastName"
          type="text"
          register={{
            ...register("lastName", {
              required: "Пожалуйста, укажите Вашу фамилию",
              pattern: {
                value: /^[\u0400-\u04FF]+$/,
                message: "Пожалуйста, используйте только кириллицу",
              },
            }),
          }}
          color={errors && errors.lastName ? "failure" : "gray"}
          helperText={
            errors && errors.lastName && <>{errors.lastName.message}</>
          }
        />
      </div>
      <div>
        <div className="mb-2 block">
          <label htmlFor="email">Email</label>
        </div>
        <TextInput
          id="email"
          type="email"
          register={{
            ...register("email", {
              required: "Пожалуйста, укажите ваш email",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Пожалуйста, укажите корректный email",
              },
            }),
          }}
          color={errors && errors.email ? "failure" : "gray"}
          helperText={
            errors && errors.email && <>{errors.email.message}</>
          }
        />
      </div>
      <div>
        <div className="mb-2 block">
          <label htmlFor="password">Пароль</label>
        </div>
        <PasswordInput
          id="password"
          register={{
            ...register("password", { required: "Пожалуйста, укажите пароль" }),
          }}
          color={errors && errors.password ? "failure" : "gray"}
          helperText={
            errors && errors.password && <>{errors.password.message}</>
          }
        />
      </div>
      <div className="flex flex-col justify-start gap-2">
        <div className="flex flex-row items-center gap-2">
          <CheckboxInput
            id="termsAndConditionsAccepted"
            value={watch("termsAndConditionsAccepted")}
            register={{
              ...register("termsAndConditionsAccepted", {
                required:
                  "Использование сервиса возможно только после согласия с условиями пользования сервисом",
              }),
            }}
          />

          <label htmlFor="termsAndConditionsAccepted select-none">
            Я соглашаюсь с{" "}
            <a
              className="underline text-secondaryAccent"
              href={"/legal/termsOfUsage"}
              target="_blank"
              rel="noreferrer"
            >
              условиями использования сервиса
            </a>
          </label>
        </div>
        {errors && errors.termsAndConditionsAccepted && (
          <div className="text-errorRed">
            <span className="text-sm mt-2">
              {errors.termsAndConditionsAccepted.message}
            </span>
          </div>
        )}
      </div>
      <div className="mt-10" />
      <PrimaryButton className="w-full" onClick={handleSubmit(onRegister)}>
        Зарегистрироваться
      </PrimaryButton>
    </form>
  );
};

export default Open;
