import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Topbar from "./components/Topbar";
import TopbarDesktop from "../UpdatedLanding/components/TopbarDesktop";
import LandingFooterDesktop from "../UpdatedLanding/components/desktop/LandingFooterDesktop";

export default function CalculatorLayout() {
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = React.useState("ИИ-калькулятор");
  const [onBack, setOnBack] = React.useState(() => () => navigate("/"));

  return (
    <div className="min-h-screen font-primary">
      <div className="min-h-screen flex flex-col fill-current stroke-current">
        <TopbarDesktop
            backgroundColor="bg-primaryBlueGreen"
            className="lg:block hidden mx-auto max-w-screen-xl px-12 text-base"
        />
        <Topbar 
          pageTitle={pageTitle}
          onBack={onBack} 
          className="lg:hidden"
        />
        <Outlet context={{ setPageTitle, setOnBack }} />
        <div
            className="lg:block hidden lg:py-10"
            style={{ background: "rgba(120, 237, 255, 0.10)" }}
          >
          <div 
            className="container w-full max-w-lg lg:max-w-screen-xl mx-auto px-3 lg:px-12"
          >
            <LandingFooterDesktop />
          </div>
        </div>
      </div>
    </div>
  );
}
