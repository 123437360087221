import React from "react";

import PlanTimelineCard from "./PlanTimelineCard";
import PlanTimelineSegment from "./PlanTimelineSegment";

const PlanTimeline = ({ className, timeline }) => {
  const calculateLineProps = (idx) => {
    const segmentHeight = 51;
    const distanceBetweenCircles = 55;
    const circleHeight = 9;
    const topMargin = distanceBetweenCircles * idx + circleHeight * idx + 9;
    const top = 28 + topMargin;
    const isLast = idx === timeline.length - 1;
    const lineType =
      !isLast && (timeline[idx + 1].passed || timeline[idx + 1].current)
        ? "solid"
        : "dashed";
    const borderColor = idx === 0 ? "border-sinbad" : "border-middleBlue";
    return { top, shouldHide: isLast, lineType, segmentHeight, borderColor };
  };
  return (
    <div className={className}>
      <div className="relative flex flex-col gap-1">
        {timeline.map((item, idx) => (
          <React.Fragment key={item.id}>
            <PlanTimelineCard item={item} idx={idx} />
            <PlanTimelineSegment {...calculateLineProps(idx)} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PlanTimeline;
