import React from "react";

import SecondaryButton from "./SecondaryButton";

const PayLaterButton = ({ payload, leadId, className }) => {
  const { actionPayLater } = payload;

  if (!actionPayLater) {
    return null;
  }

  return (
    <SecondaryButton payload={payload} className={className} leadId={leadId} />
  );
};

export default PayLaterButton;
