import React from "react";

import RadioUnselected from "../assets/icons/RadioUnselected.png";
import RadioSelected from "../assets/icons/RadioSelected.png";

const RadioInput = ({
  id,
  register,
  value,
  defaultChecked,
  className,
  onClick,
  disabled,
  name,
  currentValue,
}) => {
  console.log({ value, currentValue, isEqual: currentValue === value });
  return (
    <input
      {...register}
      id={id}
      type="radio"
      className={`form-radio w-6 h-6 bg-white checked:bg-brandPurple focus:ring-0 focus:outline-0 focus:ring-offset-0 focus:bg-brandPurple text-brandPurple cursor-pointer`}
      // style={{
      //   backgroundImage:
      //     currentValue === value
      //       ? `url(${RadioSelected})`
      //       : `url(${RadioUnselected})`,
      //   backgroundSize: "cover",
      // }}
      value={value}
      onClick={onClick}
      disabled={disabled}
      name={name}
    />
  );
};

export default RadioInput;
