import React from "react";

const Closed = ({ payload }) => {
  return (
    <div className="flex flex-col gap-8">
      <div>
        Бот будет присылать уведомления о событиях, требующих вашего внимания.
      </div>
    </div>
  );
};

export default Closed;
