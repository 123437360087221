/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Markdown from "react-markdown";

import legalService from "../../../services/legal";

// const data = {
//   privacyPolicy: "http://legal.sprintap.ru/2024-01-29_privacyPolicy.pdf",
// };

const LegalDocument = () => {
  const { documentName } = useParams();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["legal", documentName],
    queryFn: () => legalService.getDocument(documentName),
  });

  React.useEffect(() => {
    console.log({ data });
    if (!isLoading && "href" in data) {
      window.location.href = data.href;
    }
  }, [data, isLoading]);

  console.log({ isLoading, data, isIn: data && "href" in data });

  if (!isLoading && !("href" in data)) {
    return (
      <div className="max-w-sm mx-auto p-4 font-primary">
        <h2 className="text-4xl text-white font-primaryBold mb-4" id="brief">
          <div>404</div>
          <div className="text-2xl text-secondaryText mt-1">
            Документ не найден
          </div>
        </h2>
      </div>
    );
  }

  return <div className="max-w-sm mx-auto p-4 font-primary"></div>;
};

export default LegalDocument;
