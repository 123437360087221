import React from "react";

import { Accordion } from "flowbite-react";

import svgIcons from "../../../assets/v2/svgIcons";

const { ArrowDown } = svgIcons;

const AccordionArrow = ({ className }) => {
  return (
    <div className={className}>
      <ArrowDown />
    </div>
  );
};

const accordionContentTheme = {
  base: "py-5 last:rounded-b-lg text-base lg:text-lg mb-2 text-sinbad font-primary",
};

const accordionTitleTheme = {
  base: "flex w-full items-center justify-between py-5 pr-4 text-left font-primaryBold text-sinbad text-lg lg:text-2xl",
  arrow: {
    base: "",
  },
  open: {
    on: "",
  },
};

const accordionRootTheme = {
  base: "divide-y divide-sinbad/[.4] border-sinbad/[.4]",
};

const Faq = ({ className }) => {
  return (
    <div className={className}>
      <h2
        className="text-2xl lg:text-54 lg:leading-56 text-mintTulip font-primaryBold mb-6"
        style={{
          background:
            "radial-gradient(241.26% 136.11% at 54.55% -7.03%, #B5D4E5 46%, rgba(225, 244, 255, 0.00) 100%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Если остались <br className="lg:hidden" />
        вопросы
      </h2>
      <Accordion
        collapseAll
        flush
        arrowIcon={AccordionArrow}
        theme={accordionRootTheme}
      >
        <Accordion.Panel>
          <Accordion.Title theme={accordionTitleTheme}>
            Что такое спринт и как Спринт АП организует работу?
          </Accordion.Title>
          <Accordion.Content theme={accordionContentTheme}>
            Спринт – это короткий 1-2-х недельный этап в разработке проекта.
            Таких спринтов может быть несколько – количество зависит от
            масштабности проекта. За спринт команда проходит последовательные
            шаги от написания кода до презентации новой функциональности. И по
            окончании спринта можно проверить, что все поставленные задачи
            выполнены. Спринт АП автоматизирует этот процесс – разбивает проект
            на спринты, напоминает о сроках, хранит презентации и код по каждому
            спринту. Это позволяет Заказчику легко сориентироваться по прогрессу
            проекта.
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title theme={accordionTitleTheme}>
            Что такое минимальная версия проекта и почему с нее нужно начинать?
          </Accordion.Title>
          <Accordion.Content theme={accordionContentTheme}>
            Английский термин MVP определяет это как продукт, обладающий
            минимальными, но достаточными для удовлетворения первых потребителей
            функциями. Основная задача MVP — получение обратной связи для
            формирования гипотез дальнейшего развития продукта. Собрать
            информацию таким способом зачастую дешевле, чем разработать продукт
            с большим количеством функций. Это позволяет снизить затраты и
            риски, внести необходимые корректировки и доработать продукт в
            соответствии с ожиданиями целевой аудитории.
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title theme={accordionTitleTheme}>
            Что такое пользовательские истории?
          </Accordion.Title>
          <Accordion.Content theme={accordionContentTheme}>
            Это способ описания требований к разрабатываемой системе,
            сформулированных как одно или более предложений на повседневном или
            деловом языке пользователя. Например, в проекте обучения пению это
            выглядело так
            <ul className="my-2 ml-4">
              <li>«Администратор добавляет аудио-урок в курс»</li>
              <li>«Новый пользователь регистрируется в приложении» </li>
              «Пользователь переходит к подобранному по результатам теста курсу
              в тренажере»
            </ul>
            Выполнение этих задач легко проконтролировать, поскольку Заказчику
            понятна суть каждой истории. На основании этих задач разработчик
            пишет код, из которого инфраструктура Спринт АП собирает приложение.
            Это приложение по окончании спринта тестирует Заказчик. Для более
            тщательного контроля качества можно привлечь стороннего
            тестировщика.
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title theme={accordionTitleTheme}>
            Я могу пригласить своих специалистов?
          </Accordion.Title>
          <Accordion.Content theme={accordionContentTheme}>
            Да, конечно. Функционал сервиса позволяет воспользоваться и нашими
            кандидатурами, и отправить приглашение в проект стороннему
            исполнителю. Чтобы понимать обязательства и возможности специалистов
            сервиса, рекомендуем ознакомится с договором-офертой с исполнителем.
            Кстати, приглашенный вами специалист также должен будет принять и
            подписать эти условия, поскольку принципы и условия работы одинаковы
            для всех пользователей сервиса Спринт АП, согласованы с банком и
            изменению не подлежат.
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
};

export default Faq;
