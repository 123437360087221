import React from "react";

import CheckboxInput from "../../../../../components/CheckboxInput";

import iconify from "../../../../../assets/iconify";

const { Chevron } = iconify;

const TodoItem = ({ todo, onTodoClosed, onGoToTodo }) => {
  const { states, title, id } = todo;
  const { state } = states;

  const [isClosed, setIsClosed] = React.useState(false);
  const isInteractive = state === "READY_TO_CLOSE" && !isClosed;

  const onCheckboxClick = (e) => {
    e.stopPropagation();
    if (isInteractive) {
      setIsClosed(true);
      onTodoClosed({ todoId: id });
    }
  };

  const onTodoClick = () => {
    onGoToTodo({ todoId: id });
  };

  return (
    <button
      className="flex flex-row items-center gap-2 cursor-pointer w-full py-5 text-left font-primary text-white text-base"
      onClick={onTodoClick}
    >
      <CheckboxInput
        id="todo"
        value={state === "CLOSED"}
        disabled={state === "OPEN" || state === "CLOSED"}
        register={{ disabled: !isInteractive }}
        className={`${isInteractive ? "pulsating-checkbox" : ""} lineTo`}
        defaultChecked={state === "CLOSED"}
        onClick={onCheckboxClick}
      />
      <div className="flex-grow flex flex-row items-center gap-2">
        <label className="flex flex-grow cursor-pointer">
          {state === "CLOSED" ? (
            <span className="line-through">{title}</span>
          ) : (
            title
          )}
        </label>
        <div className="w-6 h-6">
          <Chevron />
        </div>
      </div>
    </button>
  );
};

export default TodoItem;
