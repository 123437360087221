import React from "react";

import RadioUnchecked from "../../../assets/v2/icons/RadioDisabled.png";
import RadioChecked from "../../../assets/v2/icons/RadioEnabled.png";

const RadioInput = ({
                        id,
                        value,
                        register,
                        currentValue,
                        color,
                        className,
                        backgroundSize,
                        onClick,
                        isActive
                    }) => {
    const bgColor = color || `bg-bigStone`;
    const bgSize = backgroundSize || `cover`;
    return (
        <input
            id={id}
            type="radio"
            value={value}
            onClick={onClick}
            className={`w-6 h-6 p-0 m-0 mb-1 ${bgColor} ${className} rounded border-0 ring-none hover:ring-0 hover:border-0 checked:${bgColor} checked:outline-0 checked:border-0 checked:ring-0 focus:ring-0 focus:border-0 focus:outline-0 focus:ring-offset-0 hover:outline-0`}
            style={{
                backgroundImage: currentValue === value || isActive
                    ? `url(${RadioChecked})`
                    : `url(${RadioUnchecked})`,
                backgroundSize: bgSize,
            }}
            {...register}
        />
    );
};

export default RadioInput;
