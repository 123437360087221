import React from "react";

import ActionUploadDocument from "./ActionUploadDocument";

const Actions = ({ actions }) => {
  const { uploadDocument } = actions;

  return (
    <div>
      {uploadDocument ? (<ActionUploadDocument uploadDocument={uploadDocument} />) : null}
    </div>
  );
};

export default Actions;
