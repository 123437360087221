import iconify from "../assets/iconify";

const { Info } = iconify;

const Infobox = ({ children }) => {
  return (
    <div className="w-full py-3 px-2 flex flex-row items-center gap-2 bg-primaryAccent rounded-lg">
      <div className="text-secondaryAccent">
        <Info />
      </div>
      <div className="font-primary text-white">{children}</div>
    </div>
  );
};

export default Infobox;
