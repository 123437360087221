import React from "react";

import CtoPhoto from "../../../assets/v2/images/CTO.png";
import MailIcon from "../../../assets/v2/icons/MailIcon.png";
import TelegramIcon from "../../../assets/v2/icons/TelegramIcon.png";
import ContactForm from "./ContactForm";

const name = "Александр Потапов";

const Team = ({className}) => {
    return (
        <div className={className} id="team">
            <h2
                className="text-2xl lg:text-54 lg:leading-56 text-mintTulip font-primaryBold mb-6"
                style={{
                    background:
                        "radial-gradient(2986.39% 124.16% at -1.32% 53.95%, #BCDBEC 39.63%, rgba(154, 190, 211, 0.00) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                }}
            >
                Мы на связи
            </h2>
                <div className="w-[200px] mx-auto">
                    <img
                        src={CtoPhoto}
                        alt={name}
                        className="rounded-lg w-[200px] h-[200px]"
                    />
                    <div className="mt-3">
                        <div className="font-primaryBold text-sinbad text-xl tracking-tight">
                            {name}
                        </div>
                        <div className="font-primary text-middleBlue text-sm tracking-tighter">
                            Основатель и технический директор сервиса Спринт&nbsp;АП
                        </div>
                    </div>
                </div>

                <div className="mt-8 flex flex-col items-center gap-8" id="contact">
                    <div className="flex flex-row justify-between items-center gap-10">
                        <a href="https://t.me/alpotapov" target="_blank" rel="noreferrer">
                            <img
                                src={TelegramIcon}
                                alt="@alpotapov"
                                className="w-[43px] h-[43px]"
                            />
                        </a>
                        <a href="mailto:alex@potapov.tech">
                            <img
                                src={MailIcon}
                                alt="alex@potapov.tech"
                                className="w-[43px] h-[43px]"
                            />
                        </a>
                    </div>
                </div>
        </div>
    );
};

export default Team;
