import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import backendActionService from "../../../../../../services/backendAction";

const TodoRequirementsMeeting = ({ payload, todoId }) => {
  const queryClient = useQueryClient();
  const { leadId } = useParams();

  const { action, estimatesId } = payload;
  const { url } = action;

  const createLeadMutation = useMutation({
    mutationFn: backendActionService.executePost(url),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["lead", leadId] });
    },
  });

  const onScheduled = async (calendlyPayload) => {
    createLeadMutation.mutate({
      estimatesId,
      calendlyPayload,
    });
  };
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      onScheduled(e.data.payload);
    },
  });
  return (
    <InlineWidget
      className="w-full"
      url="https://calendly.com/potapovtech/initial"
      pageSettings={{
        hideEventTypeDetails: true,
        hideGdprBanner: true,
      }}
    />
  );
};

export default TodoRequirementsMeeting;
