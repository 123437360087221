export function useCopyToClipboard() {
  const copy = async (textToCopy) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }

    try {
      await navigator.clipboard.writeText(textToCopy);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      return false;
    }
  };

  return { copy };
}
