import React from "react";
import svgIcons from "../../../assets/v2/svgIcons";

const { ArrowBack } = svgIcons;

const ArrowBackButton = () => (
  <div className="text-brightBlue">
    <ArrowBack />
  </div>
);

const Topbar = ({ className, pageTitle, onBack }) => {
  return (
    <div className={className}>
      <div className="bg-primaryDarkBlue p-3 fill-current stroke-current">
        <div className="max-w-sm mx-auto">
          <div className="flex flex-row justify-between items-center border border-middleBlue rounded-[20px] h-[40px] px-2 py-2">
            {onBack ? (
              <button
                onClick={onBack}
                className="hover:text-brandPurple focus:text-brandSecondaryPurple"
              >
                <ArrowBackButton />
              </button>
            ) : (
              <div className="w-8 h-8" />
            )}
            <div className="text-middleBlue text-13">{pageTitle}</div>
            <div className="w-8 h-8" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
