import React from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";

import {
  getToken,
  storeToken,
  removeToken,
  TokenTypes,
} from "./tokenRepository";
import { isTokenExpired } from "./jwtDomain";
import { signInUser } from "./authService";
import eventEmitter from "./EventEmitter";

export const AuthContext = React.createContext(null);

export const AuthProvider = () => {
  const navigate = useNavigate();
  const [, setCurrentProject] = useLocalStorage("currentProject", undefined);

  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isChecking, setIsChecking] = React.useState(true);

  const handleLogin = async ({ email, password }) => {
    const { accessToken, refreshToken } = await signInUser({
      email,
      password,
    });
    storeToken(TokenTypes.ACCESS, accessToken);
    storeToken(TokenTypes.REFRESH, refreshToken);

    setIsAuthenticated(true);
  };

  const manualLogin = ({ accessToken, refreshToken }) => {
    storeToken(TokenTypes.ACCESS, accessToken);
    storeToken(TokenTypes.REFRESH, refreshToken);

    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    removeToken(TokenTypes.ACCESS);
    removeToken(TokenTypes.REFRESH);

    setCurrentProject(undefined);

    setIsAuthenticated(false);
  };

  const handleUnauthorized = React.useCallback(() => {
    setIsAuthenticated(false);
    navigate("/app/signin");
  }, [navigate]);

  React.useEffect(() => {
    const accessToken = getToken(TokenTypes.ACCESS);

    if (!accessToken) {
      setIsChecking(false);
      setIsAuthenticated(false);
      return;
    }

    if (isTokenExpired(accessToken)) {
      setIsAuthenticated(false);
      setIsChecking(false);
      return;
    }
    
    setIsAuthenticated(true);
    setIsChecking(false);
  }, [handleUnauthorized]);

  React.useEffect(() => {
    eventEmitter.on("401", handleUnauthorized);

    return () => {
      eventEmitter.off("401", handleUnauthorized);
    };
  }, [handleUnauthorized]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isChecking,
        onLogin: handleLogin,
        onLogout: handleLogout,
        manualLogin,
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  );
};
