import React from "react";
import { Outlet } from "react-router-dom";

import Topbar from "../../components/Topbar";
import OnboardingBanner from "./components/OnboardingBanner";
import Footer from "./components/Footer";

import { OnboardingBannerProvider } from "./contexts/OnboardingBannerContext";

export default function OnboardingBase() {
  return (
    <OnboardingBannerProvider>
      <div className="min-h-screen flex flex-col fill-current stroke-current">
        <Topbar />
        <OnboardingBanner />
        <div className="flex-1 w-full h-full bg-page pb-20">
          <Outlet />
        </div>
        <div className="flex flex-col justify-end">
          <Footer hideLogo hideOffer hideTermsOfUsage />
        </div>
      </div>
    </OnboardingBannerProvider>
  );
}
