import React from "react";

import PlanTimeline from "./PlanTimeline";

const Plan = ({ className, plan, budget, deadline }) => {
  const budgetBody = budget.slice(0, -2);
  const budgetTotal = budget.slice(-2);
  const planWithCosts = plan.map((item, idx) => {
    const cost = idx < budgetBody.length ? budgetBody[idx].cost : null;
    return { ...item, cost };
  });
  return (
    <div className={`${className} font-primary text-sm`}>
      <h3 className="text-xl text-white font-primary">План</h3>
      <div className="font-primaryLight text-secondaryText my-2">
        Сроки работ - {deadline}
      </div>
      <PlanTimeline timeline={planWithCosts} />
      <div className="text-secondaryText mt-4 flex flex-col gap-2 pr-4">
        {budgetTotal.map((item, idx) => (
          <div className="flex flex-row justify-end" key={item.id}>
            <div className="flex-grow pl-9 pr-4 text-right">
              {item.title}
              {idx === 1 && <span>&nbsp;&nbsp;</span>}
            </div>
            <div>{item.cost}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plan;
