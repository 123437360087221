import React from "react";
import { Link } from "react-router-dom";

const Closed = ({ payload }) => {
  return (
    <div className="font-primary flex flex-col gap-4">
      Счет оплачен!
      <div>
        Ссылка на Figma будет доступна на{" "}
        <Link className="underline" to={"/app/project"}>
          странице Проект
        </Link>
      </div>
      Вы получите уведомление в Telegram о готовности.
    </div>
  );
};

export default Closed;
