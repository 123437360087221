import React from "react";

import iconify from "../../../assets/iconify";

const { Chevron } = iconify;

const Card = ({ item, onClick }) => {
  const { title, subtitle, color } = item;
  return (
    <button type="button" onClick={onClick} className="flex flex-row items-center gap-3 text-left w-full">
      <div
        className={`flex-grow rounded-2xl bg-primaryText text-white border border-primaryText`}
      >
        <div className="flex flex-row justify-between items-center py-2 pr-4">
          <div className={`flex-shrink h-10 w-1.5 bg-${color} rounded-xl`} />
          <div className="flex-grow ml-4 py-2 flex flex-col">
            <div>{title}</div>
            <div className="mt-1 text-xs text-secondaryText">{subtitle}</div>
          </div>
          <Chevron />
        </div>
      </div>
    </button>
  );
};

export default Card;
