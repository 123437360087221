import React from "react";

const ReadyToClose = () => {
  return (
    <p className="mb-2 text-gray-500 dark:text-gray-400">
      Вы зарегистрированы.
    </p>
  );
};

export default ReadyToClose;
