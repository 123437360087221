import React from 'react';

const PrimaryButton = ({ children, onClick, className }) => {
  return (
    <button
      className={`${className} text-primary rounded-[24px] bg-white text-primaryText border-2 border-brandPurple py-3 px-3 hover:text-white hover:bg-brandPurple focus:text-white focus:bg-brandSecondaryPurple transition-all`}
      onClick={onClick}
    >
      <span className="text-base font-primarySemiBold">
        {children}
      </span>
    </button>
  );
};

export default PrimaryButton;