import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import useAuth from "../../../../../../modules/auth/useAuth";
import leadService from "../../../../../../services/lead";

// import PrimaryButton from "../../../../../../components/PrimaryButton";
// import PasswordInput from "../../../../../../components/PasswordInput";
// import CheckboxInput from "../../../../../../components/CheckboxInput";
// import TextInput from "../../../../../../components/TextInput";

const Closed = ({ className, payload, todoId }) => {
  return (
    <div>Специалист присоединился к проекту!</div>
  );
};

export default Closed;
