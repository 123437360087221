import React from "react";

import SecondaryButton from "../../../../../../../components/SecondaryButton";

const Closed = ({ payload }) => {
  const { action, projectName, customerTelegramLink } = payload;

  const openTelegram = () => {
    window.open(customerTelegramLink, "_blank");
  };

  return (
    <div className="font-primary">
      {action === "REJECTED" ? (
        <div>Вы отказались от участия в проекте {projectName}</div>
      ) : (
        <div className="flex flex-col gap-4">
          <div>Вы изъявили желание участвовать в проекте {projectName}.</div>
          <div>
            Вы получите уведомление, если заказчик выберет вашу кандидатуру.
          </div>
          <SecondaryButton onClick={openTelegram}>
            Перейти в чат с заказчиком
          </SecondaryButton>
        </div>
      )}
    </div>
  );
};

export default Closed;
