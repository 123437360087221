import React from "react";
import Modal from "react-modal";
import { formatInTimeZone } from "date-fns-tz";
import { ru } from "date-fns/locale";
import styles from "./ScheduleMeetingModal.module.css";
import DownloadInAppStore from "../../../../assets/v2/images/DownloadInAppStore.png";
import DownloadInAppGooglePlay from "../../../../assets/v2/images/icon_google_play.png";

import config from "../../../../config";

const { appStoreLink } = config.app;

function ScheduleMeetingModal({ isOpen, onClose, time }) {
  const currentDate = new Date();
  return (
    <Modal
      isOpen={isOpen}
      className={styles.modal_content}
      style={{
        overlay: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "transparent",
        },
      }}
      ariaHideApp={false}
    >
      <div className={styles.header}>
        <div>
          {formatInTimeZone(currentDate, "Europe/Moscow", "dd MMMM yyyy", {
            locale: ru,
          })}
        </div>
        <div>
          {formatInTimeZone(currentDate, "Europe/Moscow", "HH:mm", {
            locale: ru,
          })}
        </div>
      </div>
      <div className={styles.description}>
        <img src={require("../../../../assets/icons/Clock.png")} alt="" />
        <p>
          Консультация по техническому заданию назначена на{" "}
          {formatInTimeZone(time.start, "Europe/Moscow", "dd MMMM yyyy", {
            locale: ru,
          })}{" "}
          с{" "}
          {`${formatInTimeZone(
            time.start,
            "Europe/Moscow",
            "HH:mm"
          )} - ${formatInTimeZone(time.end, "Europe/Moscow", "HH:mm")}`}
          , часовой пояс г. Москва
        </p>
      </div>
      <div>
        <div className={styles.mediaContent}>
          <p>Перейдите на мобильный телефон и загрузите приложение.</p>
          <p>Для регистрации используйте почту {"email"}</p>
          <div className={styles.links}>
            <a href={appStoreLink} target="_blank" rel="noreferrer">
              <img
                src={DownloadInAppStore}
                className="h-[50px]"
                alt="Скачать в AppStore"
              />
            </a>
            {/* <a href="https://apple.com" target="_blank" rel="noreferrer">
                                <img
                                    src={DownloadInAppGooglePlay}
                                    className="h-[50px]"
                                    alt="Скачать в Гугл Плей"
                                />
                            </a> */}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ScheduleMeetingModal;
