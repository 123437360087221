import Cross from "../../../assets/v2/icons/Cross.png";

const CrossButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="text-white"
  >
    <div
      className="px-[8px] rounded-2xl"
      style={{ backgroundColor: "#3E679252" }}
    >
      <img src={Cross} alt="Закрыть" className="w-[24px] h-[24px]" />
    </div>
  </button>
);

export default CrossButton;
