import { useQuery } from "@tanstack/react-query";

import backendActionService from "../services/backendAction";

const useBackendQuery = ({ action, queryKey }) => {
  const { url } = action;
  const getQuery = useQuery({
    queryKey,
    queryFn: backendActionService.executeGet(url),
  });

  return getQuery;
}

export default useBackendQuery;
