import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    backgroundColor: "transparent",
    margin: 0,
    inset: 0,
    padding: 0,
    borderRadius: 0,
    border: "none",
    height: "100%",
  },
  overlay: {
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(5, 19, 34, 0.90)",
    inset: 0,
  },
};

Modal.setAppElement("#root");

const ModalCard = ({ isOpen, onClose, content }) => {
  const { text, SvgIcon, backgroundConfig, titleColor, prevTitle, title, textSize, numberLeft, titleLeft,
    widthContent, svgStyles, svgSize, titleSize, nextTitle, pTop, textLeft, numberTop, textTop, idIcons, idColor, idIconSize } = content;
  const paddingTop = pTop || "140px";
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    if (!isOpen) {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
    >
      <div className="flex items-center w-full max-w-lg h-full mx-auto relative font-primary overflow-hidden" style={{
        background: backgroundConfig,
      }}>
        <div
          className="flex flex-row mx-auto w-[90%]"
        >
          <div
            className="flex flex-row"
            style={{ left: titleLeft }}
          >
            <span
              className={`text-4xl font-primaryBold`}
              style={{ color: titleColor, left: numberLeft }}
            >{prevTitle}</span>
            <h3
              className={`font-primaryBold flex`}
              style={{
                color: titleColor,
                width: idIconSize,
                height: idIconSize,
                position: "relative",
                top: numberTop,
               }}
            >
              {idIcons.map((IdIcon) => <IdIcon fill={idColor} width="100%" height="100%" />)}
            </h3>
          </div>
          <div
            className={`flex flex-col mx-auto pl-${titleLeft}`}
          >
             <span
                 className={`${titleSize} font-primaryBold`}
                 style={{ color: titleColor }}
             >{title}</span>
            <span
                className={`font-primaryBold relative`}
                style={{ color: titleColor, fontSize: "2rem" }}
            >{nextTitle}</span>
            <div
              className="text-lg relative font-primaryLight"
              style={{  fontSize: textSize, color: "#3E6792" }}
            >{text}</div>
          </div>
          <div
            className={`absolute ${svgStyles}`}
          >
            <SvgIcon width={svgSize} height={svgSize} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCard;
