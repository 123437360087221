import React from "react";
import Modal from "react-modal";

import ModalMobile from "./ModalMobile";
import ModalDesktop from "./ModalDesktop";

const customStyles = {
  content: {
    backgroundColor: "transparent",
    margin: 0,
    inset: 0,
    padding: 0,
    borderRadius: 0,
    border: "none",
    height: "100%",
  },
  overlay: {
    WebkitBackdropFilter: "blur(5px)",
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(5, 19, 34, 0.90)",
    inset: 0,
  },
};

Modal.setAppElement("#root");

const ModalDownloadApp = ({ isOpen, onClose }) => {
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    if (!isOpen) {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Скачать приложение"
    >
      <ModalMobile onClose={onClose} />
      <ModalDesktop onClose={onClose} />
    </Modal>
  );
};

export default ModalDownloadApp;
