import React from "react";

import Title from "../../../../assets/v2/images/text_logo_sprint_ap.png";

import config from "../../../../config";

import useLegalDocuments from "../../../../hooks/useLegalDocuments";

const { inn, ogrnip, name } = config.legalEntity;

const LandingFooterDesktop = ({ className }) => {
  const { privacyPolicy } = useLegalDocuments();

  return (
    <div className={className}>
      <div className="flex flex-row justify-between items-end text-middleBlue text-20 font-primary">
        <div className="">
          <div>{name}</div>
          <div>ИНН {inn}</div>
          <div>ОГРН {ogrnip}</div>
        </div>
        <div className="mt-6 flex flex-col gap-4">
          {privacyPolicy && (
            <a
              href={privacyPolicy}
              target="_blank"
              className="underline hover:text-brandPurple focus:text-brandSecondaryPurple"
              rel="noreferrer"
            >
              Политика обработки<br />персональных данных
            </a>
          )}
        </div>
        <div>© 2023-2024</div>
        <img src={Title} alt="title" className="h-[25px] mb-1" />
      </div>
    </div>
  );
};

export default LandingFooterDesktop;
