import React from "react";

import Title from "../../../assets/v2/images/text_logo_sprint_ap.png";

import config from "../../../config";

import useLegalDocuments from "../../../hooks/useLegalDocuments";

const { inn, ogrnip, name } = config.legalEntity;

const LandingFooter = ({ className }) => {
  const { privacyPolicy } = useLegalDocuments();
  return (
    <div className={className}>
      <img src={Title} alt="title" className="h-[20px] -ml-1" />
      <div className="w-1/2 mt-6 text-shipCove flex flex-col gap-4">
        {privacyPolicy && (
          <a
            href={privacyPolicy}
            className="underline hover:text-brandPurple focus:text-brandSecondaryPurple"
          >
            Политика обработки персональных данных
          </a>
        )}
      </div>
      <div className="mt-4 mb-8 text-shipCove font-primary flex flex-row justify-between items-end">
        <div>
          <div>{name}</div>
          <div>ИНН {inn}</div>
          <div>ОГРН {ogrnip}</div>
        </div>
        <div>© 2023-2024</div>
      </div>
    </div>
  );
};

export default LandingFooter;
