import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../modules/auth/useAuth";

import PrimaryButton from "../../../../components/PrimaryButton";
import Topbar from "../../../../components/Topbar";

import SigninForm from "./components/SigninForm";

import iconify from "../../../../assets/iconify";

const { Info } = iconify;

function Signin() {
  const navigate = useNavigate();
  const { onLogin } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [unauthorizedResponse, setUnauthorizedResponse] = React.useState(false);

  const mutation = useMutation({
    mutationFn: ({ email, password }) => onLogin({ email, password }),
    onSuccess: () => {
      navigate("/app");
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        setUnauthorizedResponse(true);
      }
      console.error(error.response);
    },
  });

  const onSubmit = (data) => {
    const { email, password } = data;
    setUnauthorizedResponse(false);
    mutation.mutate({ email, password });
  };

  return (
    <>
      <Topbar />
      <div className="max-w-sm mx-auto p-4">
        <h2 className="text-4xl font-primaryMedium text-white">
          Вход в приложение
        </h2>
        <SigninForm
          className="mt-10"
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        {unauthorizedResponse && (
          <div className="mt-10 w-full py-3 px-2 flex flex-row gap-2 bg-primaryAccent rounded-lg">
            <div className="mt-1 text-secondaryAccent">
              <Info />
            </div>
            <div className="font-primary text-white">
              Неверный email или пароль
            </div>
          </div>
        )}

        <PrimaryButton
          className="w-full mt-10"
          onClick={handleSubmit(onSubmit)}
        >
          Войти
        </PrimaryButton>
      </div>
    </>
  );
}

export default Signin;
