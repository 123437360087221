import React from "react";

import CheckboxUnchecked from "../assets/icons/CheckboxUnchecked.png";
import CheckboxChecked from "../assets/icons/CheckboxChecked.png";

const CheckboxInput = ({
  id,
  register,
  value,
  defaultChecked,
  className,
  onClick,
  disabled,
}) => {
  return (
    <input
      id={id}
      type="checkbox"
      defaultChecked={defaultChecked}
      className={`${className} w-6 h-6 p-0 m-0 rounded border-0 ring-none hover:ring-0 hover:border-0 checked:outline-0 checked:border-0 checked:ring-0 focus:ring-0 focus:border-0 focus:outline-0 focus:ring-offset-0 hover:outline-0 text-brandPurple`}
      style={{
        backgroundImage: value
          ? `url(${CheckboxChecked})`
          : `url(${CheckboxUnchecked})`,
        backgroundSize: "cover",
      }}
      {...register}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default CheckboxInput;
