import React from "react";
import { Link } from "react-router-dom";

const Closed = ({ payload }) => {
  const { isPaid } = payload;

  return (
    <p className="font-primary">
      Встреча завершена.
      {isPaid ? (
        <div>Вы получите техническое задание после оплаты счета</div>
      ) : (
        <div>
          Техническое задание будет доступно на странице{" "}
          <Link to={"/app/project"}>Проект</Link>
        </div>
      )}
    </p>
  );
};

export default Closed;
