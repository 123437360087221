import React from "react";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";

import { useCopyToClipboard } from "../hooks/useCopyToClipboard";

const TextInputCopy = ({ id, type, defaultValue }) => {
  const { copy } = useCopyToClipboard();
  return (
    <div className="flex flex-row">
      <div className="flex-1">
        <input
          id={id}
          type={type}
          className="h-full w-full p-2 border-none border-primaryAccent rounded-lg rounded-r-none bg-primaryAccent focus:border-none focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-brandPurple"
          defaultValue={defaultValue}
        />
      </div>
      <button
        onClick={() => copy(defaultValue)}
        className="h-full text-white bg-brandSecondaryPurple hover:bg-brandPurple focus:ring-2 focus:outline-none focus:ring-brandPurple focus:ring-inset font-medium rounded-lg rounded-l-none text-sm px-4 py-3.5"
      >
        <HiOutlineDocumentDuplicate />
      </button>
    </div>
  );
};

export default TextInputCopy;
