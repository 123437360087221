import React from "react";
import ItemDate from "./ItemDate";
import ItemTime from "./ItemTime";


function ScheduleDesktop({
                             slots,
                             chosenDateIndex,
                             setChosenDateIndex,
                             chosenSlotIndex,
                             setChosenSlotIndex,
                             slotSelectionIsDisabled,
                             onPress
                         }) {

    return (
        <div className="flex-1 w-full h-full bg-primaryDarkBlue hidden lg:block">
            <div className="font-primary pt-5 pb-5">
                <div
                    className="mx-auto w-full max-w-2xl"
                >
                    <div
                        className="mt-10 text-sinbad text-xl font-primaryExtraLight mb-4 flex items-center mx-auto w-fit">
                        Ближайшие дни для консультации
                    </div>
                    <div className="flex justify-center pt-3 mb-10 gap-10">
                        {slots.map((slot, index) => {
                            return (
                                <ItemDate key={index} date={slot.date} index={index}
                                          isActive={chosenDateIndex === index}
                                          onSelect={() => setChosenDateIndex(index)}/>
                            )
                        })}
                    </div>

                    <div
                        className="mt-14 text-sinbad text-xl font-primaryExtraLight mb-4 flex items-center mx-auto w-fit">
                        Выберите интервал времени для консультации
                    </div>
                    <div className="grid grid-cols-2 gap-4 pt-3">
                        {!slotSelectionIsDisabled && slots[chosenDateIndex].slots.map((itemSlot, slotIndex) => {
                            return (
                                <div key={slotIndex} className={"flex-1 flex justify-center px-14"}>
                                    <ItemTime slot={itemSlot} index={slotIndex}
                                              isActive={chosenSlotIndex === slotIndex}
                                              onSelect={() => setChosenSlotIndex(slotIndex)}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="pt-10 px-4 pb-8 flex flex-row justify-around">
                <button
                    className={`w-[300px] h-[55px] my-5 rounded-[50px] text-lg bg-mintTulip hover:bg-brightLightBlue focus:bg-brightLightBlue transition-all`}
                    onClick={onPress}>
            <span className={`font-primaryBold text-lg text-darkBlue`}>
              Подтвердить
            </span>
                </button>
            </div>
        </div>
    )
}

export default ScheduleDesktop
