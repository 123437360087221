import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import Appbar from "../../../../components/Appbar";
import Card from "../../components/Card";

import backendActionService from "../../../../services/backendAction";

import iconify from "../../../../assets/iconify";
import Actions from "./components/Actions";

const { Chevron } = iconify;

const colors = [
  "color1",
  "color2",
  "color3",
  "color4",
  "color5",
  "color6",
  "color7",
  "color8",
  "color9",
  "color10",
];

const BackButton = ({ backLink }) => {
  const navigate = useNavigate();
  return (
    <button
      className="w-6 h-6 text-white"
      style={{ transform: "rotate(180deg)" }}
      onClick={() => navigate(backLink)}
    >
      <Chevron />
    </button>
  );
};

const ProjectDetailView = () => {
  const [, setCurrentProject] = useLocalStorage("currentProject", undefined);
  const navigate = useNavigate();
  const { projectId } = useParams();

  React.useEffect(() => {
    setCurrentProject(projectId);
  }, [projectId, setCurrentProject]);

  const { data: projectsData, isLoading: projectsLoading } = useQuery({
    queryFn: backendActionService.executeGet(`/api/v1/project`),
    queryKey: ["project"],
  });

  const { data, isLoading } = useQuery({
    queryFn: backendActionService.executeGet(`/api/v1/project/${projectId}`),
    queryKey: ["project", projectId],
  });

  const { projects } = projectsData || { projects: [] };
  const hasMoreProjects = projects && projects.length > 1;

  const { name, assets, actions } = data || {};
  return (
    <div className="fill-current stroke-current w-full">
      <Appbar
        className={"mb-5"}
        title={name}
        backButton={
          hasMoreProjects ? <BackButton backLink={"/app/project"} /> : null
        }
      />
      <div className="max-w-sm mx-auto">
        <div className="relative px-4 flex flex-col gap-2">
          {assets &&
            assets.map((item, idx) => {
              const { type, content } = item;
              const { title, subtitle } = content || {};
              console.log({ item });
              if (item.type === "initialInfo") {
                return (
                  <Card
                    key={item.id}
                    item={{
                      color: colors[idx],
                      title,
                      subtitle,
                    }}
                    onClick={() =>
                      navigate(`/app/project/${projectId}/${type}`)
                    }
                  />
                );
              }

              if (item.type === "userStoriesAi") {
                return (
                  <Card
                    key={item.id}
                    item={{
                      color: colors[idx],
                      title,
                      subtitle,
                    }}
                    onClick={() =>
                      navigate(`/app/project/${projectId}/${type}`)
                    }
                  />
                );
              }

              if (
                item.type === "requirementsPdf" ||
                item.type === "userStoriesPdf"
              ) {
                const { documentLocation } = item.content;
                return (
                  <a
                    href={documentLocation}
                    target="_blank"
                    rel="noreferrer"
                    key={item.id}
                  >
                    <Card
                      item={{
                        color: colors[idx],
                        title,
                        subtitle,
                      }}
                      onClick={() => {}}
                    />
                  </a>
                );
              }

              if (
                type === "userStoriesPdfUnpaid" ||
                type === "requirementsPdfUnpaid"
              ) {
                const { paymentTodoId } = content;
                return (
                  <Card
                    item={{
                      color: colors[idx],
                      title,
                      subtitle,
                    }}
                    onClick={() => {
                      navigate(`/app/todo/${paymentTodoId}`);
                    }}
                  />
                );
              }

              if (type === "figmaLink") {
                return (
                  <Card
                    item={{
                      color: colors[idx],
                      title,
                      subtitle,
                    }}
                    onClick={() => window.open(content.link, "_blank")}
                  />
                );
              }

              return null;
            })}
          {actions && <Actions actions={actions} />}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailView;
