const config = {
  legalEntity: {
    inn: "771700358728",
    ogrnip: "323774600315314",
    name: "ИП Потапова Я.М.",
  },
  tinkoff: {
    terminalKey: "1703777356586DEMO"
  },
  app: {
    appStoreLink: "https://testflight.apple.com/join/qWEPjnG6",
  }
};

export default config;
