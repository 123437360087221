import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AuthProvider } from "./modules/auth/AuthContext";

import ProtectedRoute from "./modules/auth/ProtectedRoute";
import Logout from "./modules/auth/Logout";

import Landing from "./pages/UpdatedLanding/Landing";
import CalculatorLayout from "./pages/UpdatedCalculator/CalculatorLayout";
import Idea from "./pages/UpdatedCalculator/Idea/Idea";
import Details from "./pages/UpdatedCalculator/Details";
import Await from "./pages/UpdatedCalculator/Await";
import PreliminarySpecs from "./pages/UpdatedCalculator/PreliminarySpecs/PreliminarySpecs";
import ScheduleMeeting from "./pages/UpdatedCalculator/SheduleMeeting/ScheduleMeeting";

import OnboardingBase from "./pages/OnboardingBase/OnboardingBase";
import Signin from "./pages/OnboardingBase/pages/Signin/Signin";
import BriefBase from "./pages/OnboardingBase/pages/Brief/BriefBase";
import StepIdeaDescription from "./pages/OnboardingBase/pages/Brief/pages/StepIdeaDescription";
import StepProjectQuestions from "./pages/OnboardingBase/pages/Brief/pages/StepProjectQuestions";
import StepLegalQuestions from "./pages/OnboardingBase/pages/Brief/pages/StepLegalQuestions";
import StepLoadingState from "./pages/OnboardingBase/pages/Brief/pages/StepLoadingState";
import Estimates from "./pages/OnboardingBase/pages/Estimates/Estimates";

import AppBase from "./pages/AppBase/AppBase";
import UserTodoList from "./pages/AppBase/pages/NewTodoList/UserTodoList";
import LeadTodoList from "./pages/AppBase/pages/NewTodoList/LeadTodoList";
import Todo from "./pages/AppBase/pages/Todo/Todo";
import Invite from "./pages/AppBase/pages/Invite/Invite";
import ProjectTimeline from "./pages/AppBase/pages/ProjectTimeline/ProjectTimeline";
import ProjectList from "./pages/AppBase/pages/ProjectList/ProjectList";
import ProjectDetailView from "./pages/AppBase/pages/ProjectDetailView/ProjectDetailView";
import ProjectDetails from "./pages/AppBase/pages/ProjectDetails/ProjectDetails";
import SpecialistProfile from "./pages/AppBase/pages/SpecialistProfile/SpecialistProfile";

import LegalBase from "./pages/LegalBase/LegalBase";
import LegalDocument from "./pages/LegalBase/pages/LegalDocument";

import "react-modern-drawer/dist/index.css";
import "./assets/fonts/NunitoSans_10pt-ExtraLight.ttf";
import "./assets/fonts/NunitoSans_10pt-Light.ttf";
import "./assets/fonts/NunitoSans_10pt-Regular.ttf";
import "./assets/fonts/NunitoSans_10pt-Medium.ttf";
import "./assets/fonts/NunitoSans_10pt-SemiBold.ttf";
import "./assets/fonts/NunitoSans_10pt-Bold.ttf";
import ScheduleMeeting2 from "./pages/UpdatedCalculator/SheduleMeeting/ScheduleMeeting2";

const queryClient = new QueryClient();

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/calculator",
      element: <CalculatorLayout />,
      children: [
        {
          path: "",
          element: <Idea />,
        },
        {
          path: ":estimatesId/details",
          element: <Details />
        },
        {
          path: ":estimatesId/await",
          element: <Await />
        },
        {
          path: ":estimatesId/specs",
          element: <PreliminarySpecs />,
        },
        {
          path: ":estimatesId/schedule",
          element: <ScheduleMeeting />
        },
        {
          path: "schedule_v2",
          element: <ScheduleMeeting2 />
        }
      ],
    },
    {
      path: "/onboarding",
      element: <OnboardingBase />,
      children: [
        {
          path: "",
          element: <Navigate to="/onboarding/brief" replace={true} />,
        },
        {
          path: "brief",
          element: <BriefBase />,
          children: [
            {
              path: "",
              element: <StepIdeaDescription />,
            },
            {
              path: ":estimatesId/project",
              element: <StepProjectQuestions />,
            },
            {
              path: ":estimatesId/legal",
              element: <StepLegalQuestions />,
            },
            {
              path: ":estimatesId",
              element: <StepLoadingState />,
            },
          ],
        },
        {
          path: "estimates/:estimatesId",
          element: <Estimates />,
        },
      ],
    },
    {
      path: "/legal",
      element: <LegalBase />,
      children: [
        {
          path: ":documentName",
          element: <LegalDocument />,
        },
      ],
    },
    {
      path: "/app",
      element: <AuthProvider />,
      children: [
        {
          path: "",
          element: <AppBase />,
          children: [
            ...["", "todo"].map((path) => ({
              path,
              element: (
                <ProtectedRoute>
                  <UserTodoList />
                </ProtectedRoute>
              ),
            })),
            {
              path: "todo/:todoId",
              element: (
                <ProtectedRoute>
                  <Todo />
                </ProtectedRoute>
              ),
            },
            {
              path: "plan",
              element: (
                <ProtectedRoute>
                  <ProjectTimeline />
                </ProtectedRoute>
              ),
            },
            {
              path: "project",
              element: <ProjectList />,
            },
            {
              path: "project/:projectId",
              element: <ProjectDetailView />,
            },
            {
              path: "project/:projectId/:contentType",
              element: <ProjectDetails />,
            },
            {
              path: "signin",
              element: <Signin />,
            },
            {
              path: "logout",
              element: <Logout />,
            },
            {
              path: "todo/lead/:leadId",
              element: <LeadTodoList />,
            },
            {
              path: "todo/lead/:leadId/:todoId",
              element: <Todo />,
            },
            {
              path: "invite/:inviteId",
              element: <Invite />,
            },
            {
              path: "team",
              element: <SpecialistProfile />,
            },
            {
              path: "team/profile/:profileId/*",
              element: <SpecialistProfile />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
