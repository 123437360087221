import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import PrimaryButton from "../../../../../../../components/PrimaryButton";

import backendActionService from "../../../../../../../services/backendAction";

import iconify from "../../../../../../../assets/iconify";

const { Info } = iconify;

const RecruitmentOptionsCard = ({ payload, icon }) => {
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm();
  const { jobTitle, recruitmentOptions, role, recruitmentOptionsSaveAction } =
    payload;
  const { url } = recruitmentOptionsSaveAction;

  const mutation = useMutation({
    mutationFn: backendActionService.executePost(url),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["todoList"] });
      console.log(data);
    },
  });

  const confirmRecruitmentOptionChoice = (data) => {
    mutation.mutate(data);
  };
  return (
    <div className="w-full text-white">
      <div className="flex flex-col gap-4">
        <div>
          <fieldset className="flex max-w-md flex-col gap-4">
            {recruitmentOptions.map((recruitmentOption, idx) => (
              <div
                className="flex items-center gap-2"
                key={recruitmentOption.value}
              >
                <div className="inline-flex items-center">
                  <label
                    className="relative flex items-center p-3 rounded-full cursor-pointer"
                    htmlFor="html"
                  >
                    <input
                      name={`option`}
                      type="radio"
                      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-primaryText text-primaryText transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-secondaryText before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                      id={`${role}-${recruitmentOption.value}`}
                      value={recruitmentOption.value}
                      defaultChecked={recruitmentOption.defaultChecked}
                      {...register(`${role}`)}
                    />
                    <span className="absolute text-brandPurple transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <circle
                          data-name="ellipse"
                          cx="8"
                          cy="8"
                          r="8"
                        ></circle>
                      </svg>
                    </span>
                  </label>
                  <label
                    className="mt-px font-primary text-white cursor-pointer"
                    htmlFor={`${role}-${recruitmentOption.value}`}
                  >
                    {recruitmentOption.text}
                  </label>
                </div>
                {/* <input
                  type="radio"
                  id={`${role}-${recruitmentOption.value}`}
                  value={recruitmentOption.value}
                  defaultChecked={recruitmentOption.defaultChecked}
                  className="bg-brandPurple checked:border-0 checked:ring-0 checked:outline-none"
                  {...register(`${role}`)}
                /> */}
                {/* <label htmlFor={`${role}-${recruitmentOption.value}`}>
                  {recruitmentOption.text}
                </label> */}
              </div>
            ))}
          </fieldset>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          <div className="w-full py-3 px-2 flex flex-row gap-2 bg-primaryAccent rounded-lg">
            <div className="mt-1 text-secondaryAccent">
              <Info />
            </div>
            <div className="font-primary text-white">
              Вы сможете изменить свой выбор позднее
            </div>
          </div>
          <div className="mt-10" />
          <PrimaryButton onClick={handleSubmit(confirmRecruitmentOptionChoice)}>
            Подтвердить выбор
          </PrimaryButton>
          <div className="">
            <p className="text-base text-secondaryText mt-2"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentOptionsCard;
