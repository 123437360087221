import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "./useAuth";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isChecking } = useAuth();

  if (isChecking) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to="/app/signin" replace />;
  }

  return children;
};

export default ProtectedRoute;
