import React from "react";

import CopyComponent from "../../../../../../components/CopyComponent";

const Open = ({ payload }) => {
  const { inviteLink } = payload;

  return (
    <div className="flex flex-col gap-8 font-primary">
      <div>
        Перейдите по ссылке и нажмите кнопку "Старт" в чате с нашим ботом.
      </div>
      <CopyComponent defaultValue={inviteLink} id="inviteLink" />
      <div className="flex flex-col gap-4">
        <div className="w-full flex flex-row">
          <a
            href={inviteLink}
            target="_blank"
            rel="noopener noreferrer"
            className="w-full text-center text-primaryText rounded-[24px] bg-white text-primaryText border-2 border-brandPurple py-3 px-3 hover:text-white hover:bg-brandPurple focus:text-white focus:bg-brandSecondaryPurple transition-all"
          >
            Перейти к боту Спринт АП
          </a>
        </div>
      </div>
    </div>
  );
};

export default Open;
