const PlanTimelineSegment = ({ top, shouldHide, lineType, segmentHeight }) => {
  if (shouldHide) return null;
  return (
    <div
      className={`border-l border-secondaryText absolute ml-[7px]`}
      style={{
        height: segmentHeight,
        marginTop: top,
        borderStyle: lineType,
      }}
    />
  );
};

export default PlanTimelineSegment;
