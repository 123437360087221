import React from "react";
import { useParams } from "react-router-dom";

import PaymentDetailsForm from "./components/PaymentDetailsForm";
import Options from "./components/Options";
import TinkoffPaymentWidget from "./components/TinkoffPaymentButton";

const Open = ({ payload, todoId }) => {
  const { leadId } = useParams();
  const { options, text, questionKey } = payload;

  return (
    <div>
      <p className="font-primary text-white">{text}</p>
      {options ? (
        <Options payload={payload} leadId={leadId} className="mt-4" />
      ) : null}
      {questionKey === "paymentDetails" ? (
        <PaymentDetailsForm payload={payload} leadId={leadId} />
      ) : null}
      {questionKey === "payment" ? (
        <TinkoffPaymentWidget payload={payload} leadId={leadId} />
      ) : null}
    </div>
  );
};

export default Open;
