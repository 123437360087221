import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import Appbar from "../../../../components/Appbar";
import Card from "../../components/Card";

import backendActionService from "../../../../services/backendAction";

const ProjectList = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryFn: backendActionService.executeGet(`/api/v1/project`),
    queryKey: ["project"],
  });

  const { projects } = data || { projects: [] };

  return (
    <div className="fill-current stroke-current w-full">
      <Appbar className={"mb-5"} title={"Проекты"} />
      <div className="max-w-sm mx-auto">
        <div className="relative px-4 flex flex-col gap-2">
          {projects.map((project) => (
            <Card
              key={project.id}
              item={{
                color: "color1",
                title: project.name,
              }}
              onClick={() => navigate(`/app/project/${project.id}`)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
