import { apiClient } from "./http";


const getDocument = async (documentName) => {
  try {
    const response = await apiClient.get(`/api/v1/legal/${documentName}`);
    return response.data;
  } catch (err) {
    if (err.response.status === 404) {
      return {};
    }
    console.log(JSON.stringify(err));
    throw err;
  }
};

const legalService = {
  getDocument,
}

export default legalService;
