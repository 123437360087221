import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useWindowSize } from "@uidotdev/usehooks";

import estimatesService from "../../../services/estimates";

import useScrollToAnchor from "../../../hooks/useScrollToAnchor";
import useLegalDocuments from "../../../hooks/useLegalDocuments";

import ModalCancelCalculator from "../components/ModalCancelCalculator";
import ModalDownloadApp from "../../UpdatedLanding/components/ModalDownloadApp/ModalDownloadApp";

import IdeaMobile from "./IdeaMobile";
import IdeaDesktop from "./IdeaDesktop";

const Idea = () => {
  const size = useWindowSize();
  const { privacyPolicy } = useLegalDocuments();

  const contentConfig = {
    text: "Вы не внесли данные, необходимые для расчета. Хотите сразу назначить встречу с системным аналитиком для формирования ТЗ?",
    nextBtnText: "Внести данные",
    secondaryBtnText: "Назначить консультацию",
  };

  useScrollToAnchor();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      privacyPolicyAccepted: false,
      ideaDescription: "",
      uspDescription: "",
    },
  });

  const [isModalCancelOpen, setIsModalCancelOpen] = React.useState(false);
  const [isModalDownloadAppOpen, setIsModalDownloadAppOpen] =
    React.useState(false);

  const isEmailValid = watch("email") !== "";
  const hasAcceptedPrivacyPolicy = watch("privacyPolicyAccepted");
  const isIdeaFieldValid = watch("ideaDescription").length >= 280;
  const isUspFieldValid = watch("uspDescription").length >= 20;

  const isContinueButtonActive = isValid;

  const progress =
    10 +
    (isEmailValid ? 1 : 0) * 15 +
    (hasAcceptedPrivacyPolicy ? 1 : 0) * 5 +
    (isIdeaFieldValid ? 1 : 0) * 25 +
    (isUspFieldValid ? 1 : 0) * 20;

  const showSkipIdea = !isIdeaFieldValid;
  const showSkipUsp = isIdeaFieldValid && !isUspFieldValid;
  // const showSkipIdea = false;
  // const showSkipUsp = false;

  const mutation = useMutation({
    mutationFn: estimatesService.provideDescription,
    onSuccess: (estimatesId) => {
      console.log({ estimatesId });
      navigate(`/calculator/${estimatesId}/details`);
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        const responseData = error.response?.data;
        alert(
          JSON.stringify(
            responseData.map((item) => item.message).join("\r\n"),
            null,
            2
          )
        );
      }
    },
  });

  const onGetEstimates = async (ideaObject) => {
    mutation.mutate({
      ...ideaObject,
    });
  };

  return (
    <div className="flex-grow">
      {size.width < 1024 ? (
        <IdeaMobile
          register={register}
          watch={watch}
          errors={errors}
          handleSubmit={handleSubmit}
          progress={progress}
          isUspFieldValid={isUspFieldValid}
          isContinueButtonActive={isContinueButtonActive}
          setIsModalCancelOpen={setIsModalCancelOpen}
          onGetEstimates={onGetEstimates}
          isModalCancelOpen={isModalCancelOpen}
          contentConfig={contentConfig}
          showSkipIdea={showSkipIdea}
          showSkipUsp={showSkipUsp}
          isPending={mutation.isPending}
          privacyPolicy={privacyPolicy}
        />
      ) : (
        <IdeaDesktop
          register={register}
          watch={watch}
          errors={errors}
          handleSubmit={handleSubmit}
          progress={progress}
          isUspFieldValid={isUspFieldValid}
          isContinueButtonActive={isContinueButtonActive}
          setIsModalCancelOpen={setIsModalCancelOpen}
          onGetEstimates={onGetEstimates}
          isModalCancelOpen={isModalCancelOpen}
          contentConfig={contentConfig}
          showSkipIdea={showSkipIdea}
          showSkipUsp={showSkipUsp}
          isPending={mutation.isPending}
          privacyPolicy={privacyPolicy}
        />
      )}
      <ModalCancelCalculator
        isOpen={isModalCancelOpen}
        onClose={() => setIsModalCancelOpen(false)}
        content={contentConfig}
        onAbort={() => {
          setIsModalCancelOpen(false);
          setIsModalDownloadAppOpen(true)
        }}
      />
      <ModalDownloadApp
        isOpen={isModalDownloadAppOpen}
        onClose={() => setIsModalDownloadAppOpen(false)}
      />
    </div>
  );
};

export default Idea;
