import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import Appbar from "../../../../components/Appbar";

import todoService from "../../../../services/todo";
import leadService from "../../../../services/lead";
import useCloseTodo from "../../../../hooks/useCloseTodo";

import TodoHeader from "./components/TodoHeader";

import todoRequirementsMeeting from "./components/TodoRequirementsMeeting";
import todoPayForRequirementsMeeting from "./components/TodoPayForRequirementsMeeting";
import todoEnableTelegramNotifications from "./components/TodoEnableTelegramNotifications";
import todoRegistration from "./components/TodoRegistration";
import todoSpecialistRecruitment from "./components/TodoSpecialistRecruitment";
import todoSignAgreement from "./components/TodoSignAgreement";
import todoJoinRequirementsMeeting from "./components/TodoJoinRequirementsMeeting";
import todoCandidateSelection from "./components/TodoCandidateSelection";
import todoPayForDesigner from "./components/TodoPayForDesigner";

import specialistTodos from "./components/Specialist";

import iconify from "../../../../assets/iconify";

const { Chevron } = iconify;

const templates = ({ payload, todoId }) => ({
  todoRequirementsMeeting: todoRequirementsMeeting({ payload, todoId }),
  todoPayForRequirementsMeeting: todoPayForRequirementsMeeting({
    payload,
    todoId,
  }),
  todoEnableTelegramNotifications: todoEnableTelegramNotifications({
    payload,
    todoId,
  }),
  todoRegistration: todoRegistration({ payload, todoId }),
  todoSpecialistRecruitment: todoSpecialistRecruitment({ payload, todoId }),
  todoSignAgreement: todoSignAgreement({ payload, todoId }),
  todoJoinRequirementsMeeting: todoJoinRequirementsMeeting({ payload, todoId }),
  todoCandidateSelection: todoCandidateSelection({ payload, todoId }),
  todoPayForDesigner: todoPayForDesigner({ payload, todoId }),
  "specialist/todoRespondToProject": specialistTodos.todoRespondToProject({
    payload,
    todoId,
  }),
  "specialist/todoSubmitDesign": specialistTodos.todoSubmitDesign({
    payload,
    todoId,
  }),
});

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className="w-6 h-6 text-white"
      style={{ transform: "rotate(180deg)" }}
      onClick={() => navigate(-1)}
    >
      <Chevron />
    </button>
  );
};

const Todo = () => {
  const navigate = useNavigate();
  const { todoId, leadId } = useParams();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const queryKey = leadId ? ["lead", leadId] : ["todoList"];
  const queryFn = leadId
    ? () => leadService.getTodoList(leadId)
    : () => todoService.getTodoList();

  const onTodoCloseSuccess = () => {
    if (leadId) {
      navigate(`/app/todo/lead/${leadId}`);
    } else {
      navigate(`/app/todo`);
    }
  };
  const { closeTodo } = useCloseTodo({ leadId, onSuccess: onTodoCloseSuccess });

  const { data, isLoading, isError } = useQuery({
    queryKey,
    queryFn,
    initialData: [],
  });

  const { todoList, title: listTitle } = data || {};
  const todo =
    todoList && todoList.find((todo) => todo.id === parseInt(todoId, 10));
  const { title, states, template } = todo || {};
  const { state, payload } = states || {};

  React.useEffect(() => {
    console.log("todo", todo);
    console.log("payload", payload);
  }, [payload, todo]);

  const shouldShowHint =
    todoList &&
    todoList.length === 1 &&
    todoList[0].states.state === "READY_TO_CLOSE";

  if (!todo) return null;

  return (
    <>
      <div className="bg-primaryText w-full mb-2">
        <Appbar
          backButton={<BackButton />}
          title={listTitle}
          showDrawer={false}
        />
      </div>
      <div className="divide-y divide-gray-200 text-white max-w-sm mx-auto px-4 pb-4 fill-current stroke-current">
        <TodoHeader
          todo={todo}
          onTodoClosed={closeTodo}
          shouldShowHint={shouldShowHint}
        />
        <div className="py-5">
          {todo && templates({ payload, todoId })[template][state]()}
        </div>
      </div>
    </>
  );
};

export default Todo;
