import React from "react";

import iconify from "../assets/iconify";

const { Chevron, EmptyCircle, CheckedCircle } = iconify;

const TimelineCard = ({ item, onClick }) => {
  const { passed, title, duration, color, current } = item;
  const activeColor = current ? color : "primaryText";

  const cardRef = React.useRef(null);
  React.useLayoutEffect(() => {
    if (cardRef.current) {
      console.log(cardRef.current.getBoundingClientRect());
    }
  });
  return (
    <div className="relative">
      <div className="flex flex-row items-center gap-3" ref={cardRef}>
        <div className={`text-${color}`}>
          {passed ? <CheckedCircle /> : <EmptyCircle />}
        </div>
        <button
          className={`flex-grow rounded-2xl text-left bg-primaryText text-white border border-${activeColor} ${!onClick && "cursor-default"}`}
          type="button"
          onClick={onClick ? onClick : undefined}
        >
          <div className="flex flex-row justify-between items-center py-2 pr-4">
            <div
              className={`flex-shrink min-w-[6px] h-10 w-1.5 bg-${color} rounded-xl`}
            />
            <div className="flex-grow ml-4 py-2 flex flex-col">
              <div>{title}</div>
              {passed ? null : (
                <div className="mt-1 text-xs text-secondaryText">
                  {duration}
                </div>
              )}
            </div>
            {!onClick ? (
              <div className="min-w-[24px]" />
            ) : (
              <div className="min-h-5">
                <Chevron />
              </div>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default TimelineCard;
