import axios from "axios";

import { useMutation } from "@tanstack/react-query";

const API_HOST = process.env.REACT_APP_SERVER_URL;

export const useBackendMutationWithoutAuth = ({
  mutationKey,
  url,
  method,
  data,
  onSuccess,
  onError,
}) => {
  const mutation = useMutation({
    mutationKey,
    mutationFn: async (additionalData) => {
      const response = await axios.request({
        method,
        url: `${API_HOST}${url}`,
        data: { ...data, ...additionalData },
      });

      return response.data;
    },
    onSuccess,
    onError,
  });

  return mutation;
};
