import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";

import Tasks from "../../../assets/icons/Tasks.png";
import TasksPassive from "../../../assets/icons/TasksPassive.png";
import Plan from "../../../assets/icons/Plan.png";
import PlanPassive from "../../../assets/icons/PlanPassive.png";
import Team from "../../../assets/icons/Team.png";
import TeamPassive from "../../../assets/icons/TeamPassive.png";
import Requirements from "../../../assets/icons/Requirements.png";
import RequirementsPassive from "../../../assets/icons/RequirementsPassive.png";
import Profile from "../../../assets/icons/Profile.png";
import ProfilePassive from "../../../assets/icons/ProfilePassive.png";

const iconMapping = {
  Tasks: {
    active: Tasks,
    passive: TasksPassive,
  },
  Plan: {
    active: Plan,
    passive: PlanPassive,
  },
  Team: {
    active: Team,
    passive: TeamPassive,
  },
  Requirements: {
    active: Requirements,
    passive: RequirementsPassive,
  },
  Profile: {
    active: Profile,
    passive: ProfilePassive,
  },
};

const MenuItem = ({ icon, title, active, onClick }) => {
  return (
    <button
      type="button"
      className="flex flex-col items-center gap-1 w-1/5"
      onClick={onClick}
    >
      <img
        src={iconMapping[icon][active ? "active" : "passive"]}
        alt={title}
        className="w-5 h-5"
      />
      <div
        className={`${
          active
            ? "underline font-primary text-menuActive"
            : "font-primaryExtraLight text-menuPassive"
        }`}
      >
        {title}
      </div>
    </button>
  );
};

const Menu = () => {
  const [currentProject] = useLocalStorage("currentProject", undefined);

  const navigate = useNavigate();
  const location = useLocation();
  const onClick = (dest) => () => {
    navigate(dest);
  };
  const isActive = (dest) => {
    if (dest === "/app") {
      return location.pathname === dest;
    }

    return location.pathname.startsWith(dest);
  };
  return (
    <div className="w-full fixed bottom-0 bg-page">
      <div className="max-w-sm mx-auto px-4 py-2">
        <div className="flex flex-row justify-between text-xs">
          <MenuItem
            icon={"Tasks"}
            title="Задачи"
            onClick={onClick("/app")}
            active={isActive("/app") || isActive("/app/todo")}
          />
          <MenuItem
            icon={"Plan"}
            title="План"
            onClick={onClick("/app/plan")}
            active={isActive("/app/plan")}
          />
          <MenuItem
            icon={"Requirements"}
            title="Проект"
            onClick={onClick(
              `/app/project${currentProject ? `/${currentProject}` : ""}`
            )}
            active={isActive("/app/project")}
          />
          <MenuItem
            icon={"Team"}
            title="Команда"
            onClick={onClick("/app/team")}
            active={isActive("/app/team")}
          />
          <MenuItem icon={"Profile"} title="Профиль" />
        </div>
      </div>
    </div>
  );
};

export default Menu;
