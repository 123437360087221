import React from "react";
import { useNavigate } from "react-router-dom";

import iconify from "../../../../../../../assets/iconify";

const { Chevron } = iconify;

const TabCandidates = ({ candidates, todoId }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4">
      {Object.entries(candidates).map(([key, candidate]) => {
        const { profile, state, actions } = candidate;
        const { name, summary, hourlyRate } = profile;
        const onClick = () =>
          navigate(`/app/team/profile/${key}/todo/${todoId}`, {
            state: actions,
          });

        return (
          <button
            type="button"
            onClick={onClick}
            key={key}
            className="bg-primaryText flex flex-row gap-4 p-4 text-left rounded-2xl"
          >
            <div>
              <img
                src="https://via.placeholder.com/50x66"
                alt="Specialist"
                className="rounded-md"
              />
            </div>
            <div className="flex flex-col grow">
              <h2 className="font-primaryBold text-base">{name}</h2>
              <div className="text-secondaryText text-xs">{summary}</div>
              <div className="text-secondaryText text-xs">{hourlyRate}</div>
              <div className="text-brandPurple text-xs">
                {state === "CONTACTED" && "Вы обменялись контактами"}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-6 h-6">
                <Chevron />
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default TabCandidates;
