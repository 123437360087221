import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import PrimaryButton from "../../../../../../components/PrimaryButton";
import TextInput from "../../../../../../components/TextInput";
import CheckboxInput from "../../../../../../components/CheckboxInput";
import backendActionService from "../../../../../../services/backendAction";

const wrapWithLinkToLegal = ({ documentType, text }) => {
  return (
    <a
      href={`/legal/${documentType}`}
      target="_blank"
      rel="noreferrer"
      className="underline"
    >
      {text}
    </a>
  );
};

const Open = ({ payload }) => {
  const queryClient = useQueryClient();

  const { action, role, phone } = payload;
  const { url } = action;
  const isCodeSent = "codeSentAt" in payload;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      acceptTermsAndConditions: isCodeSent,
      acceptPublicOffer: isCodeSent,
      acceptRoleResponsibilities: isCodeSent,
      phone,
    },
  });

  const verificationRequestMutation = useMutation({
    mutationFn: backendActionService.executePost(url),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["todoList"] });
      console.log(data);
    },
  });

  const onSendSmsRequest = (data) => {
    verificationRequestMutation.mutate(data);
  }
  const onVerificationRequest = (data) => {
    const { code } = data;
    verificationRequestMutation.mutate({ code });
  };

  return (
    <div className="flex flex-col gap-4 text-white font-primary">
      <div className="flex flex-col justify-start gap-2">
        <div className="flex flex-row items-center gap-4">
          <CheckboxInput
            id="acceptTermsAndConditions"
            value={watch("acceptTermsAndConditions")}
            register={{
              ...register("acceptTermsAndConditions", {
                disabled: isCodeSent,
                required:
                  "Использование сервиса возможно только после согласия с правилами пользования Спринт АП",
              }),
            }}
          />

          <label htmlFor="acceptTermsAndConditions">
            Я ознакомился с{" "}
            {wrapWithLinkToLegal({
              documentType: "termsOfUsage",
              text: "правилами пользования Сервисом Спринт АП",
            })}{" "}
            и выражаю свое согласие с его условиями.
          </label>
        </div>
        {errors && errors.acceptTermsAndConditions && (
          <div className="text-errorRed dark:text-red-500">
            <span className="text-sm mt-2">
              {errors.acceptTermsAndConditions.message}
            </span>
          </div>
        )}
      </div>

      <div className="flex flex-col justify-start gap-2">
        <div className="flex flex-row items-center gap-4">
          <CheckboxInput
            id="acceptPublicOffer"
            value={watch("acceptPublicOffer")}
            register={{
              ...register("acceptPublicOffer", {
                disabled: isCodeSent,
                required:
                  "Использование сервиса возможно только после согласия с договором-офертой",
              }),
            }}
          />

          <label htmlFor="acceptPublicOffer">
            Я ознакомился с{" "}
            {wrapWithLinkToLegal({
              documentType: "offer",
              text: "договором-офертой",
            })}
            , регулирующим взаимоотношения сторон, сроки выполнения,
            формирование стоимости и порядок оплаты работ, ответственность
            пользователей Сервиса в рамках реализации Проекта.
          </label>
        </div>
        {errors && errors.acceptPublicOffer && (
          <div className="text-errorRed dark:text-red-500">
            <span className="text-sm mt-2">
              {errors.acceptPublicOffer.message}
            </span>
          </div>
        )}
      </div>

      <div className="flex flex-col justify-start gap-2">
        <div className="flex flex-row items-center gap-4">
          <CheckboxInput
            id="acceptRoleResponsibilities"
            value={watch("acceptRoleResponsibilities")}
            register={{
              ...register("acceptRoleResponsibilities", {
                disabled: isCodeSent,
                required:
                  "Использование сервиса возможно только после согласия с договором-офертой",
              }),
            }}
          />

          <label htmlFor="acceptRoleResponsibilities">
            Определяя свою роль в проекте как{" "}
            {role === "owner" ? "Заказчик" : "Исполнитель"}, обязуюсь следовать
            изложенным в{" "}
            {wrapWithLinkToLegal({
              documentType: "offer",
              text: "договоре-оферте",
            })}{" "}
            договоренностям.
          </label>
        </div>
        {errors && errors.acceptRoleResponsibilities && (
          <div className="text-errorRed dark:text-red-500">
            <span className="text-sm mt-2">
              {errors.acceptRoleResponsibilities.message}
            </span>
          </div>
        )}
      </div>

      <p>
        Свое согласие подтверждаю кодом, отправленным на указанный мной в
        реквизитах номер телефона.
      </p>

      <div>
        <div className="mb-2 block">
          <label htmlFor="phone">Телефон</label>
        </div>
        <TextInput
          id="phone"
          type="text"
          register={register("phone", {
            required: "Пожалуйста, укажите телефон",
          })}
          color={errors && errors.phone ? "failure" : "gray"}
          helperText={errors && errors.phone && <>{errors.phone.message}</>}
        />
      </div>

      {isCodeSent ? (
        <span>Запросить код повторно</span>
      ) : (
        <PrimaryButton onClick={handleSubmit(onSendSmsRequest)}>
          Запросить код
        </PrimaryButton>
      )}

      {isCodeSent && (
        <>
          <div>
            <div className="block">
              <label htmlFor="code">Код из смс</label>
            </div>
            <TextInput
              id="code"
              type="text"
              register={{...register("code", {
                required: false,
              })}}
              color={errors && errors.code ? "failure" : "gray"}
              helperText={errors && errors.code && <>{errors.code.message}</>}
            />
          </div>

          <PrimaryButton onClick={handleSubmit(onVerificationRequest)}>
            Подтверждаю согласие
          </PrimaryButton>
        </>
      )}
    </div>
  );
};

export default Open;
