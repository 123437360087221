import React from "react";

const TextInput = ({
  id,
  type,
  register,
  color,
  helperText,
  placeholder,
  disabled,
  defaultValue,
}) => {
  return (
    <div>
      <input
        id={id}
        type={type}
        className="w-full p-2 border border-primaryAccent rounded-md bg-primaryAccent focus:border-none focus:outline-none focus:ring-2 focus:ring-brandPurple"
        placeholder={placeholder}
        {...register}
        disabled={disabled}
        defaultValue={defaultValue}
      />
      <div className="text-errorRed mt-1">
        <span className="text-sm font-primary">{helperText}</span>
      </div>
    </div>
  );
};

export default TextInput;
