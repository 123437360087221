import React from "react";

import RecruitmentOptionsCard from './components/RecruitmentOptionsCard';
import InviteLinkCard from "./components/InviteLinkCard";
import RecruitmentDelegatedCard from "./components/RecruitmentDelegatedCard";
import SpecialistRecruitedCard from "./components/SpecialistRecruitedCard";

import roleIcons from "../../../../../../assets/roleIcons";

const cardTemplates = {
  RecruitmentOptionsCard,
  InviteLinkCard,
  RecruitmentDelegatedCard,
  SpecialistRecruitedCard,
};

const Open = ({ className, payload, todoId }) => {
  const { specialists } = payload;

  return (
    <div>
      {specialists &&
        specialists.map((specialist) => {
          const { cardTemplate, role } = specialist;
          const icon = roleIcons[role];

          const Card = cardTemplates[cardTemplate];

          return (
            <Card
              key={role}
              icon={icon}
              payload={specialist}
            />
          );
        })}
    </div>
  );
};

export default Open;
