import React from "react";
import Typed from "typed.js";

const HeaderTyping = ({ texts, className }) => {
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: texts,
      typeSpeed: 80,
      backSpeed: 50,
      backDelay: 2000,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, [texts]);

  return (
    <div className={className}>
      <div className="h-[200px]" style={{ color: "rgba(120, 237, 255, 0.70)"}}>
        <span ref={el} />
      </div>
    </div>
  );
};

export default HeaderTyping;
