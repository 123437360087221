import { useMutation, useQueryClient } from "@tanstack/react-query";

import todoService from "../services/todo";
import leadService from "../services/lead";

const useCloseTodo = ({ leadId, onSuccess, onError }) => {
  const queryClient = useQueryClient();

  const successCallback = onSuccess || (() => {});
  const errorCallback = onError || (() => {});

  const closeLeadTodo = useMutation({
    mutationFn: ({ leadId, todoId }) =>
      leadService.closeTodo({ leadId, todoId }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["todoList"]);
      successCallback(data);
    },
    onError: errorCallback,
  });

  const closeUserTodo = useMutation({
    mutationFn: ({ todoId }) => todoService.closeTodo({ todoId }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["lead", leadId]);
      successCallback(data);
    },
    onError: errorCallback,
  });

  const closeTodo = ({ todoId }) => {
    if (leadId) {
      closeLeadTodo.mutate({ leadId, todoId });
    } else {
      closeUserTodo.mutate({ todoId });
    }
  };

  return {
    closeTodo,
  };
};

export default useCloseTodo;
