import React from "react";

const wrapWithLinkToLegal = ({ documentType, text }) => {
  return (
    <a
      href={`/legal/${documentType}`}
      target="_blank"
      rel="noreferrer"
      className="underline"
    >
      {text}
    </a>
  );
};

const Closed = ({ className, payload, todoId }) => {
  return (
    <div>
      Вы обязались следовать изложенным в{" "}
      {wrapWithLinkToLegal({
        documentType: "offer",
        text: "договоре-оферте",
      })}{" "}
      договоренностям.{" "}
    </div>
  );
};

export default Closed;
