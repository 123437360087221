import React from "react";

import HeaderTyping from "./HeaderTyping";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

import LogoBackgroundElement from "../../../assets/v2/images/phone.png";

const FirstScreen = ({ className, onPrimaryClick, TEXTS, setIsModalOpen }) => {
  return (
    <div className={className}>
      <div className="relative w-full pt-6 overflow-visible">
        <div className="absolute top-4 right-0 z-5">
          <img src={LogoBackgroundElement} alt="logo" className="h-[270px]" />
        </div>
        <h1 className="relative px-3 text-4xl font-primarySemiBold text-mintTulip z-3">
          Разработка
          <HeaderTyping texts={TEXTS} />
          под контролем
        </h1>
        <div
          className="relative mt-8 ml-2"
          style={{ color: "rgba(160, 196, 216, 0.60)", maxWidth: 350 }}
        >
          <span className="">Приложение для предпринимателей - <br />от идеи до релиза за 4 недели силами фрилансеров</span>
        </div>

        <div className="mt-12 flex flex-col gap-7 justify-center items-center">
          <PrimaryButton className="w-[300px]" onClick={onPrimaryClick}>
            Рассчитать в ИИ-калькуляторе
          </PrimaryButton>

          <SecondaryButton
            className="w-[300px]"
            onClick={() => setIsModalOpen(true)}
          >
            Скачать приложение
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default FirstScreen;
