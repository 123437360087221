import React from "react";

import iconify from "../../../../../assets/iconify";

const { FilledCircle, EmptyCircle } = iconify;

const PlanTimelineCard = ({ item, idx }) => {
  const { title, duration, cost } = item;

  const cardRef = React.useRef(null);
  // React.useLayoutEffect(() => {
  //   if (cardRef.current) {
  //     console.log(cardRef.current.getBoundingClientRect());
  //   }
  // });
  return (
    <div className="relative">
      <div className="flex flex-row items-center gap-1" ref={cardRef}>
        <div className={`text-secondaryText`}>
          {idx === 0 ? <FilledCircle /> : <EmptyCircle />}
          {/* <EmptyCircle /> */}
        </div>
        <div className="flex-grow rounded-2xl text-left text-secondaryText cursor-default">
          <div className="flex flex-row justify-between py-1 pr-4">
            <div className="flex-grow ml-4 py-1 flex flex-col">
              <div>{title}</div>
              <div className="mt-1 text-xs">{duration}</div>
            </div>
            <div className="py-1">{cost}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanTimelineCard;
