import React from "react";

const nextSteps = [
  {
    title:
      "Выберите удобное для вас время и проработайте техническое задание с нашим специалистом.",
    color: "color6",
  },
  {
    title: "Оплатите услугу подготовки технического задания. Результат будет представлен в формате pdf.",
    color: "color7",
  },
  {
    title: "Выберите UI/UX-дизайнера из нашего пула талантов и проработайте дизайн проекта.",
    color: "color8",
  },
  {
    title: "Выберите разработчиков из нашего пула талантов и передайте им техническое задание и дизайн.",
    color: "color9",
  },
  {
    title: "Протестируйте первую версию вашего проекта уже после первого спринта!",
    color: "color10",
  },
];

const NextSteps = ({ className }) => {
  const nextStepsWithIds = nextSteps.map((step, idx) => ({
    ...step,
    id: idx,
  }));
  return (
    <div className={className}>
      <h3 className="text-xl text-white font-primary">Следующие шаги</h3>
      <div className="mt-4 flex flex-row gap-4 pb-3 overflow-x-scroll text-white">
        {nextStepsWithIds.map((step, index) => (
          <div
            className={`shrink-0 w-[300px] rounded-xl p-5 bg-${step.color} text-black`}
            key={step.id}
          >
            <div className="font-primaryBold text-base mb-2">/ {index + 1}</div>
            {step.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NextSteps;
