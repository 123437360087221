import React from "react";

import TodoItem from "./TodoItem";

import "../checkboxAnimation.css";

const TodoList = ({ todoList, onTodoClosed, onGoToTodo }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="divide-y divide-gray-200 border-gray-200">
      {todoList.map((todo) => (
        <TodoItem
          key={todo.id}
          todo={todo}
          onTodoClosed={onTodoClosed}
          onGoToTodo={onGoToTodo}
        />
      ))}
    </div>
  );
};

export default TodoList;
